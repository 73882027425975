import { useState, useContext, useEffect, useRef, useCallback } from 'react';

import { Button, Card, Form, Row, Col } from 'react-bootstrap';
import TextComponent from 'components/Input/TextComponent';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useRestApi } from 'context/RestApiContext';
import { useMaster } from 'context/MasterContext';

import { NYUKIN_STATUS_LIST, NyukinLabelComponent } from 'components/Input/NyukinLabelComponent';

/** 入金編集画面 */
function NyukinEditModal(props) {
  // マスタ情報
  const master = useMaster();

  const restApi = useRestApi();

  // 契約情報
  const [contract, setContract] = useState({});

  // 表示
  const [show, setShow] = useState(false);

  // モーダルパラメタの変更処理
  useEffect(() => {
    if (props?.params?.contract) {
      setContract({ ...props?.params?.contract });
      setShow(true);
    }
  }, [props.params]);

  // 入金状況の選択処理
  const handleOnClickNyukinStatus = (status) => {
    setContract({ ...contract, NYUKIN_STATUS: status });
  };

  // 編集処理
  const handleOnChange = (prop) => (target) => {
    setContract({ ...contract, [prop]: target.value });
  };

  // 閉じるボタン
  const handleClose = () => {
    setShow(false);
  };

  // 確定ボタン
  const handleFix = () => {
    const params = {
      SEIKYU_NO: contract.SEIKYU_NO,
      NYUKIN_STATUS: contract.NYUKIN_STATUS,
      NYUKIN_COMMENT: contract.NYUKIN_COMMENT,
      NYUKIN_DATE: contract.NYUKIN_DATE,
      MAE_NYUKIN: contract.MAE_NYUKIN,
      MAE_NYUKIN_DATE: contract.MAE_NYUKIN_DATE,
    };

    restApi.post('/api/accounting/nyukin', params, (_) => {
      props.params.callback();
      setShow(false);
    });
  };

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '720px',
            },
          },
        }}
        open={show}
      >
        <DialogTitle className="p-3">入金確認</DialogTitle>
        <DialogContent className="px-0 py-0 pr-0 pl-0">
          <Card className="m-0">
            <Card.Body>
              <Row>
                <Col className="pl-2 pt-2" md="5">
                  <Form.Group>
                    <label>入金状況</label>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="pt-1">
                {NYUKIN_STATUS_LIST.map((nyukin) => (
                  <NyukinLabelComponent
                    key={nyukin.id}
                    nyukin={nyukin.id}
                    className="m-1"
                    isDisabled={contract.NYUKIN_STATUS != nyukin.id}
                    onClick={() => handleOnClickNyukinStatus(nyukin.id)}
                  />
                ))}
              </Row>
              <Row>
                <Col className="pl-2" md="5">
                  <Form.Group>
                    <label>工事名</label>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="pl-2">
                  <h4 className="p-0 m-0">{contract.KOUJI_MEI}</h4>
                </Col>
              </Row>
              <Row>
                <Col className="pl-2 pt-2" md="5">
                  <Form.Group>
                    <label>宛先</label>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="pl-2">
                  <h4 className="p-0 m-0">{contract.ATESAKI}</h4>
                </Col>
              </Row>
              <Row>
                <Col className="pl-2 pt-2" md="5">
                  <Form.Group>
                    <label>工事場所</label>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="pl-2">
                  <h5 className="p-0 m-0">{contract.KOUJI_BASYO}</h5>
                </Col>
              </Row>
              <Row>
                <Col className="pl-2 pt-2" md="3">
                  <Form.Group>
                    <label>着工金支払い期限</label>
                  </Form.Group>
                </Col>
                <Col className="pl-2 pt-2">
                  <Form.Group>
                    <label>着工金入金日</label>
                  </Form.Group>
                </Col>
                <Col className="pl-2 pt-2 text-right">
                  <Form.Group>
                    <label>着工金請求額</label>
                  </Form.Group>
                </Col>
                <Col className="pl-2 pt-2 text-right" md="3">
                  <Form.Group>
                    <label>着工金支払額</label>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="pl-2" md="3">
                  <h4 className="p-0 m-0">{contract.MAE_KIGEN || '－'}</h4>
                </Col>
                <Col className="pl-2">
                  <TextComponent
                    type="date"
                    value={contract?.MAE_NYUKIN_DATE}
                    onChange={handleOnChange('MAE_NYUKIN_DATE')}
                  ></TextComponent>
                </Col>
                <Col className="pl-2 text-right">
                  <h4 className="p-0 m-0">{contract.MAE_KIN?.toLocaleString()}円</h4>
                </Col>
                <Col className="pl-2 text-right" md="3">
                  <TextComponent
                    type="number"
                    value={contract?.MAE_NYUKIN}
                    onChange={handleOnChange('MAE_NYUKIN')}
                  ></TextComponent>
                </Col>
              </Row>
              <Row>
                <Col className="pl-2 pt-2" md="3">
                  <Form.Group>
                    <label>請求日</label>
                  </Form.Group>
                </Col>
                <Col className="pl-2 pt-2">
                  <Form.Group>
                    <label>最終入金日</label>
                  </Form.Group>
                </Col>
                <Col className="pl-2 pt-2 text-right" md="3">
                  <Form.Group>
                    <label>請求金額</label>
                  </Form.Group>
                </Col>
                <Col className="pl-2 pt-2 text-right" md="3">
                  <Form.Group>
                    <label>完工金額</label>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="pl-2" md="3">
                  <h4 className="p-0 m-0">{contract.SEIKYU_DATE || '－'}</h4>
                </Col>
                <Col className="pl-2">
                  <TextComponent
                    type="date"
                    value={contract?.NYUKIN_DATE}
                    onChange={handleOnChange('NYUKIN_DATE')}
                  ></TextComponent>
                </Col>
                <Col className="pl-2 text-right" md="3">
                  <h4 className="p-0 m-0">{contract.SEIKYU_KIN?.toLocaleString()}円</h4>
                </Col>
                <Col className="pl-2 text-right" md="3">
                  <h4 className="p-0 m-0">
                    {((contract.SEIKYU_KIN ?? 0) - contract.MAE_NYUKIN).toLocaleString()}円
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col className="pl-2">
                  <Form.Group>
                    <label className="form-label">コメント</label>
                    <TextComponent
                      className="form-control"
                      type="textarea"
                      rows="4"
                      value={contract?.NYUKIN_COMMENT}
                      onChange={handleOnChange('NYUKIN_COMMENT')}
                      maxLength={255}
                    ></TextComponent>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </DialogContent>
        <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
          <Button variant="secondary" className="footer-button" onClick={handleClose}>
            閉じる
          </Button>
          <Button variant="primary btn-fill" className="footer-button" onClick={handleFix}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NyukinEditModal;
