import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';

import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useRestApi } from 'context/RestApiContext';
import ContractChangeModal from 'views/ContractChange/ContractChangeModal';

// 契約情報
function ContractInfo(props) {
  const restApi = useRestApi();

  // パラメタ
  const clientInfo = props.clientInfo; // 顧客情報
  const contractList = props.contractList; // 契約一覧
  const selectedSeikyuNo = props.selectedSeikyuNo; // 選択中の請求番号
  const quoteList = props.quoteList; // 見積もり一覧
  const setContractModalParam = props.setContractModalParam; // 契約編集モーダルパラメタ
  const callbakContractModal = props.callbakContractModal; // 契約入力のコールバック処理
  const orderList = props.orderList; // 注文一覧
  const addInvoiceList = props.addInvoiceList; // 追加請求一覧
  const constructionList = props.constructionList; // 工事一覧
  const seikyuList = props.seikyuList; // 請求一覧

  // 契約表示情報
  const [viewContract, setViewContract] = useState([]);

  // 契約変更モーダル
  const [contractChangeModalParam, setContractChangeModalParam] = useState({});

  // 契約情報の変更処理
  useEffect(() => {
    let _viewContract = [];
    if (contractList.length) {
      // 契約時の金額
      let contract_kin = contractList[0]['kingaku'];
      // 請求金額
      let seikyu_kin = seikyuList.reduce((a, x) => a + x['MITSU_KIN'], 0);
      // 注文金額
      let order_kin = orderList.reduce((a, x) => a + x['CYU_KIN'], 0);
      // 粗利
      let arari = seikyu_kin - order_kin;
      // 粗利率
      let arariritu = Math.floor((arari / seikyu_kin) * 100) / 100;
      _viewContract = [...contractList].map((x) => ({
        ...x,
        contract_kin: contract_kin,
        seikyu_kin: seikyu_kin,
        order_kin: order_kin,
        arari: arari,
        arariritu: arariritu,
        order_status: `${contractList[0]['zumi_su']} / ${
          contractList[0]['zumi_su'] + contractList[0]['not_su']
        } `,
      }));
    }

    setViewContract(_viewContract);
  }, [contractList]);

  // 契約編集
  const handleOnClickContractEdit = (item, mode) => {
    setContractModalParam({
      mode: mode,
      client: {
        CLIENT_CD: clientInfo['CLIENT_CD'],
        CLIENT_NAME: `${clientInfo['NAME_SEI_1']} ${clientInfo['NAME_MEI_1']}`,
        CLIENT_ADD: `${clientInfo['PREF']}${clientInfo['CITY']}${clientInfo['TOWN']}${clientInfo['ADDRESS']} `,
      },
      contract: item,
    });
  };

  // 変更契約の作成
  const handleOnClickContractChange = () => {
    setContractChangeModalParam({
      seikyuNo: selectedSeikyuNo,
      quoteList: [...quoteList],
      seikyu: { ...constructionList.find((x) => x.SEIKYU_NO == selectedSeikyuNo) },
    });
  };

  // 契約書の印刷
  const handleOnClickContractPrint = (item) => {
    restApi.getToPdf(`/api/contract/report/${item.KEI_NO}`);
  };

  // 契約のアイコンが使用不可かどうか
  const isContractIconDisabled = (icon, item) => {
    switch (icon) {
      case '編集':
        // 保証書作成済み、または請求書作成済は不可
        return item.HIKI_DATE || item.SEIKYU_DATE;
      case '削除':
        // 注文済は削除不可
        // 追加請求済は削除不可
        // 保証書作成済み、または請求書作成済は不可
        return (
          orderList.length > 0 || addInvoiceList.length > 0 || item.HIKI_DATE || item.SEIKYU_DATE
        );
      case '注文書作成':
      case '印刷':
      case '変更契約':
        // 常に使用可
        return false;
    }
  };

  return (
    <>
      <Row>
        <Col>
          <div className="list-label1">契約書</div>
        </Col>
        <Col></Col>
        <Col style={{ textAlign: 'right' }}>
          <br />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="list-3-line">
            <FlexGrid
              itemsSource={viewContract}
              selectionMode={'None'}
              headersVisibility={'Column'}
              isReadOnly={true}
              loadedRows={(grid) => {
                grid.rows.forEach((ele) => {
                  ele.height = 38;
                });
              }}
            >
              <FlexGridColumn binding="NO" header="No" width={60} align={'right'}></FlexGridColumn>
              <FlexGridColumn binding="KEI_DATE" header="契約日" width={95}></FlexGridColumn>
              <FlexGridColumn
                binding="KOUJI_MEI"
                header="工事名"
                width="2*"
                allowResizing={false}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="contract_kin"
                header="契約金額(税込)"
                width={105}
                align={'right'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="seikyu_kin"
                header="請求金額(税抜)"
                width={105}
                align={'right'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="order_kin"
                header="注文金額(税抜)"
                width={105}
                align={'right'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="arari"
                header="粗利益額"
                width={100}
                align={'right'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="arariritu"
                header="粗利率"
                width={60}
                format="n2"
                align={'right'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="order_status"
                header="発注状況"
                width={80}
                align={'right'}
              ></FlexGridColumn>
              <FlexGridColumn binding="KOUKI_ST" header="施工開始日" width={95}></FlexGridColumn>
              <FlexGridColumn binding="KOUKI_ED" header="施工終了日" width={95}></FlexGridColumn>
              <FlexGridColumn header="" binding="" isReadOnly={true} width={150}>
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(cell) => (
                    <span>
                      <Button
                        className="material-symbols-rounded cell-icon btn-fill"
                        title="編集"
                        variant="primary"
                        onClick={() => handleOnClickContractEdit(cell.item, 'upd')}
                        disabled={isContractIconDisabled('編集', cell.item)}
                      >
                        edit
                      </Button>
                      <Button
                        className="material-symbols-rounded cell-icon btn-fill"
                        title="削除"
                        variant="danger"
                        onClick={() => handleOnClickContractEdit(cell.item, 'del')}
                        disabled={isContractIconDisabled('削除', cell.item)}
                      >
                        delete
                      </Button>
                      <Button
                        className="material-symbols-rounded cell-icon btn-fill"
                        title="印刷"
                        variant="primary"
                        onClick={() => handleOnClickContractPrint(cell.item)}
                        disabled={isContractIconDisabled('印刷', cell.item)}
                      >
                        print
                      </Button>
                      <Button
                        className="cell-icon btn-fill"
                        title="変更契約書作成"
                        variant="primary"
                        onClick={() => handleOnClickContractChange(cell.item)}
                        disabled={isContractIconDisabled('変更契約', cell.item)}
                      >
                        更
                      </Button>
                    </span>
                  )}
                />
              </FlexGridColumn>
            </FlexGrid>
          </div>
        </Col>
      </Row>

      {/* 変更契約モーダル */}
      <ContractChangeModal
        params={contractChangeModalParam}
        callback={callbakContractModal}
      ></ContractChangeModal>
    </>
  );
}

export default ContractInfo;
