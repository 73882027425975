import { DataMap } from '@grapecity/wijmo.grid';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import TextComponent from 'components/Input/TextComponent';
import { useMaster } from 'context/MasterContext';
import { useRestApi } from 'context/RestApiContext';
import { useAuth } from 'context/AuthContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import SelectComponent from 'components/Input/SelectComponent';
import { NYUKIN_STATUS_LIST, NyukinLabelComponent } from 'components/Input/NyukinLabelComponent';
import { ProcessLableComponent } from 'components/Input/ProcessLableComponent';

import { Checkbox } from '@mui/material';
import NyukinEditModal from 'views/Accounting/NyukinEditModal';
import NyukinCommentModal from 'views/Accounting/NyukinCommentModal';
import SeikyusyoRePrintModal from 'views/Accounting/SeikyusyoRePrintModal';
import ClientCardEditModal from 'views/Dashboard/ClientCardEditModal';

/** 経理担当の画面 */
function Accounting() {
  const location = useLocation();

  const auth = useAuth();
  const master = useMaster();
  const restApi = useRestApi();

  // グリッド
  const flexGrid = useRef();

  // 契約データ
  const [contracts, setContracts] = useState([]);
  // 契約データ（表示用）
  const [viewContracts, setViewContracts] = useState([]);

  // 入金編集画面モーダルのパラメタ
  const [nyukinModalParam, setNyukinModalParam] = useState({});
  // 入金コメントモーダルのパラメタ
  const [nyukinCommentModalparam, setNyukinCommentModalparam] = useState({});
  // 編集モーダルのパラメタ
  const [clientCardEditModalParam, setClientCardEditModalParam] = useState({});
  // 請求書再印刷モーダルのパラメタ
  const [seikyusyoRePrintModalParam, setSeikyusyoRePrintModalParam] = useState({});

  // コード翻訳用のマッピング
  var planerMap = new DataMap(master.planer, 'id', 'name');

  // 検索条件
  const [searchCondition, setSearchCondition] = useState({
    TAN_CD: '',
    FILTER: '',
    STATUS: [0, 1, 2, 3, 4, 5],
  });

  // 契約情報の検索処理
  const searchContracts = () => {
    // 全契約を取得して、画面で絞り込む
    restApi.post('/api/accounting/list', {}, (data) => {
      setContracts(
        data.keiList.map((k) => {
          // 宛先は請求先を優先する
          let atesaki =
            k.SEIKYU_KBN === 'client'
              ? k.SEIKYU_CLIENT_NAME
              : k.SEIKYU_TRI_NAME ||
                (k.QUOTE_KBN === 'client' ? k.QUOTE_CLIENT_NAME : k.QUOTE_TRI_NAME);
          return {
            ...k,
            NO: '#' + k.SEIKYU_NO,
            ATESAKI: atesaki,
          };
        })
      );
    });
  };

  // 初期表示処理
  useEffect(() => {
    // 検索条件に指定がある場合
    if (location.state?.searchCondition) {
      setSearchCondition(location.state.searchCondition);
    }

    searchContracts();
  }, []);

  // 検索条件、検索結果の変更処理
  useEffect(() => {
    const filter = searchCondition.FILTER.trim();
    const tanCd = searchCondition.TAN_CD;
    const status = searchCondition.STATUS;

    setViewContracts(
      contracts
        .filter((x) => !filter || x.ATESAKI?.includes(filter) || x.KOUJI_MEI?.includes(filter))
        .filter((x) => !tanCd || x.TAN_CD == tanCd)
        .filter((x) => status.includes(x.NYUKIN_STATUS))
    );
  }, [contracts, searchCondition]);

  // 編集ボタン
  const handleOnClickEdit = (item) => {
    setNyukinModalParam({
      contract: item,
      callback: () => {
        searchContracts();
      },
    });
  };

  // 顧客編集モーダルの呼び出し処理
  const callClientCardEditModal = (client) => {
    setClientCardEditModalParam({
      client: client,
      callback: (_) => {
        searchContracts();
      },
    });
  };

  // 編集処理
  const handleChange = (prop) => (target) => {
    setSearchCondition({ ...searchCondition, [prop]: target?.value ?? target?.target.value });
  };

  // 入金状態の変更処理
  const handleOnChangeNyukin = (value) => {
    if (searchCondition.STATUS.some((s) => s == value)) {
      setSearchCondition({
        ...searchCondition,
        STATUS: searchCondition.STATUS.filter((s) => s != value),
      });
    } else {
      setSearchCondition({ ...searchCondition, STATUS: [...searchCondition.STATUS, value] });
    }
  };

  // 入金コメントモーダルの呼び出し処理
  const callNyukinCommentModal = (item) => {
    setNyukinCommentModalparam({ client: item });
  };

  // 未入金の請求書再印刷
  const callSeikyusyoRePrintModal = () => {
    setSeikyusyoRePrintModalParam({ show: true });
  };

  return (
    <>
      <Card className="m-0 table-container">
        <Card.Body>
          <Row>
            <Col className="pr-1" md="3">
              <Form.Group>
                <label>絞り込み</label>
                <TextComponent
                  placeholder="部分一致で検索します"
                  type="text"
                  value={searchCondition.FILTER}
                  onChange={handleChange('FILTER')}
                ></TextComponent>
              </Form.Group>
            </Col>
            <Col className="pr-1" md="1">
              <Form.Group>
                <label>担当プランナー</label>
                <SelectComponent value={searchCondition.TAN_CD} onChange={handleChange('TAN_CD')}>
                  <option value="">　</option>
                  {master.planer.map((x, i) => (
                    <option key={i} value={x['id']}>
                      {x['name']}
                    </option>
                  ))}
                </SelectComponent>
              </Form.Group>
            </Col>
            <Col className="pr-1" md="6">
              <Form.Group>
                <label>入金状況</label>
                <div className="d-flex flex-row align-items-center">
                  {NYUKIN_STATUS_LIST.map((nyukin) => (
                    <span key={nyukin.id}>
                      <Checkbox
                        checked={searchCondition?.STATUS?.indexOf(nyukin.id) > -1}
                        className="m-0 p-1"
                        onChange={() => handleOnChangeNyukin(nyukin.id)}
                      />
                      <NyukinLabelComponent
                        nyukin={nyukin.id}
                        className="p-0 m-0 mr-2"
                        isDisabled={searchCondition?.STATUS?.indexOf(nyukin.id) == -1}
                        onClick={() => handleOnChangeNyukin(nyukin.id)}
                      />
                    </span>
                  ))}
                </div>
              </Form.Group>
            </Col>
            <Col md="2" className="d-flex justify-content-end align-items-end">
              <Button
                variant="primary"
                className="p-2 m-0 d-flex align-items-center btn-fill"
                style={{ height: '42px' }}
                onClick={callSeikyusyoRePrintModal}
              >
                <i className="material-symbols-rounded">print</i>
                <span style={{ fontWeight: 'bold' }}>請求書再印刷</span>
              </Button>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <div className="table-scroll" style={{ height: 'calc(100vh - 186px)' }}>
                <FlexGrid
                  ref={flexGrid}
                  isReadOnly={true}
                  itemsSource={viewContracts}
                  selectionMode={'Row'}
                  headersVisibility={'Column'}
                  allowSorting={true}
                  loadedRows={(grid) => {
                    grid.rows.forEach((ele) => {
                      ele.height = 38;
                    });
                  }}
                  style={{ height: '100%' }}
                >
                  <FlexGridColumn
                    binding="NO"
                    align={'left'}
                    header=" "
                    width={40}
                    cssClass={'seikyu_no'}
                  ></FlexGridColumn>
                  <FlexGridColumn binding="KOUJI_MEI" header="工事名" width={'1*'}></FlexGridColumn>
                  <FlexGridColumn binding="ATESAKI" header="宛先" width={'1*'}></FlexGridColumn>
                  {/* <FlexGridColumn binding="KOUJI_BASYO" header="工事場所" width={"1*"}></FlexGridColumn> */}
                  <FlexGridColumn
                    binding="MAE_KIGEN"
                    header="着工金支払期限"
                    width={88}
                  ></FlexGridColumn>
                  <FlexGridColumn binding="SEIKYU_DATE" header="請求日" width={85}></FlexGridColumn>
                  <FlexGridColumn
                    binding="MAE_NYUKIN_DATE"
                    header="着工金入金日"
                    width={85}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="NYUKIN_DATE"
                    header="最終入金日"
                    width={85}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="MAE_KIN"
                    header="着工金請求額"
                    width={100}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="MAE_NYUKIN"
                    header="着工金支払額"
                    width={100}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="SEIKYU_KIN"
                    header="請求金額"
                    width={100}
                  ></FlexGridColumn>
                  <FlexGridColumn binding="KAN_KIN" header="完工金額" width={100}></FlexGridColumn>
                  <FlexGridColumn
                    binding="TAN_CD"
                    dataMap={planerMap}
                    header="担当"
                    width={80}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="NYUKIN_STATUS"
                    header="入金状況"
                    align={'center'}
                    width={112}
                  >
                    <FlexGridCellTemplate
                      cellType="Cell"
                      template={(cell) => (
                        <NyukinLabelComponent
                          key={cell.item.SEIKYU_NO}
                          nyukin={cell.item.NYUKIN_STATUS}
                          size="small"
                          hasComment={cell.item.NYUKIN_COMMENT}
                          onClick={
                            cell.item.NYUKIN_COMMENT
                              ? () => callNyukinCommentModal(cell.item)
                              : null
                          }
                        />
                      )}
                    />
                  </FlexGridColumn>
                  {/** プランナーの場合工程を表示 */}
                  {auth.auth.kbn?.some((k) => k == 'planner') ? (
                    <FlexGridColumn binding="PROCESS_CD" header="工程" align={'center'} width={100}>
                      <FlexGridCellTemplate
                        cellType="Cell"
                        template={(cell) => (
                          <ProcessLableComponent
                            process={master.process.find(
                              (p) => p.PROCESS_CD == cell.item.PROCESS_CD
                            )}
                            size="small"
                          />
                        )}
                      />
                    </FlexGridColumn>
                  ) : null}
                  {/** プランナーの場合、顧客情報の編集を追加 */}
                  {auth.auth.kbn?.some((k) => k == 'planner') ? (
                    <FlexGridColumn header="" binding="" isReadOnly={true} width={42}>
                      <FlexGridCellTemplate
                        cellType="Cell"
                        template={(cell) => (
                          <span>
                            <Button
                              className="material-symbols-rounded cell-icon btn-fill"
                              variant="secondary"
                              title="編集"
                              onClick={() => callClientCardEditModal(cell.item)}
                            >
                              more_horiz
                            </Button>
                          </span>
                        )}
                      />
                    </FlexGridColumn>
                  ) : null}
                  {/* 編集ボタンは経理のみ可能 */}
                  {auth.auth.kbn?.some((k) => k == 'accounting') ? (
                    <FlexGridColumn header="" binding="" isReadOnly={true} width={42}>
                      <FlexGridCellTemplate
                        cellType="Cell"
                        template={(cell) => (
                          <span>
                            <Button
                              className="material-symbols-rounded cell-icon btn-fill"
                              variant="primary"
                              title="編集"
                              onClick={() => handleOnClickEdit(cell.item)}
                            >
                              edit
                            </Button>
                          </span>
                        )}
                      />
                    </FlexGridColumn>
                  ) : null}
                </FlexGrid>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {/* 入金確認ダイアログ */}
      <NyukinEditModal params={nyukinModalParam} />
      {/** 入金コメントモーダル画面 */}
      <NyukinCommentModal params={nyukinCommentModalparam} />
      {/** 顧客情報修正 */}
      <ClientCardEditModal params={clientCardEditModalParam} />
      {/** 請求書再印刷モーダル */}
      <SeikyusyoRePrintModal params={seikyusyoRePrintModalParam} />
    </>
  );
}

export default Accounting;
