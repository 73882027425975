import { DataMap, HeadersVisibility } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import TextComponent from 'components/Input/TextComponent';
import { useMaster } from 'context/MasterContext';
import { useRestApi } from 'context/RestApiContext';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import SelectComponent from 'components/Input/SelectComponent';

import PaymentConstructionSelectModal from 'views/Payment/PaymentConstructionSelectModal';

/** 工事選択画面 */
function PaymentConstructionSelect(props) {
  const paymentMonth = props.paymentMonth;

  const location = useLocation();
  const navigate = useNavigate();

  const master = useMaster();
  const restApi = useRestApi();

  // 工事追加用のモーダルパラメタ
  const [addModalParams, setAddModalParams] = useState({});

  // 請求データ
  const [payments, setPayments] = useState([]);

  // 工事一覧
  const [constructions, setConstructions] = useState([]);

  // コード翻訳用のマッピング
  var planerMap = new DataMap(master.planer, 'id', 'name');

  // 支払い請求情報を取得
  const searchContracts = () => {
    restApi.post('/api/payment/list', { paymentMonth: paymentMonth }, (data) => {
      setPayments(data.payments);

      setConstructions(
        data.paySeikyuList.map((p) => ({
          ...p,
          ATESAKI: props.getAtesaki(p),
        }))
      );
    });
  };

  // 初期表示処理
  useEffect(() => {
    location.state?.searchCondition && setSearchCondition(location.state.searchCondition);
    searchContracts();
  }, []);

  // 追加ボタンの処理
  const handleOnClickAdd = () => {
    setAddModalParams({
      paymentMonth: props.paymentMonth,
      constructions: constructions,
      payments: payments,
      callback: (_constructions) => {
        setConstructions(_constructions);
      },
    });
  };

  // 次へボタン押下
  const handleNext = () => {
    restApi.post(
      '/api/payment/seikyu/regist',
      {
        paymentMonth: paymentMonth,
        constructions: constructions,
      },
      (data) => {
        props.setConstructions(constructions);
        props.handleNext();
      }
    );
  };

  return (
    <>
      <Row className="pt-4">
        <Col className="pr-1">・原価入力対象の工事を選択してください。</Col>
      </Row>
      <Row className="pt-2">
        <Col className="pr-1" md="2">
          <Form.Group>
            <label>請求年月</label>
            <TextComponent value={paymentMonth} disabled={true} type="date" selectionMode="Month" />
          </Form.Group>
        </Col>
      </Row>
      <Row className="text-right mt-0 mb-0">
        <Col>
          <Button
            className="m-0 material-symbols-rounded cell-icon-sm btn-fill"
            variant="primary"
            title="追加"
            onClick={handleOnClickAdd}
          >
            add
          </Button>
        </Col>
      </Row>
      <Row className="mt-0">
        <Col>
          <div className="table-scroll" style={{ height: 'calc(100vh - 342px)' }}>
            <FlexGrid
              isReadOnly={true}
              itemsSource={constructions}
              selectionMode={'Row'}
              headersVisibility={HeadersVisibility.Column}
              allowSorting={true}
              style={{ height: '100%' }}
              loadedRows={(grid) => {
                grid.rows.forEach((ele) => {
                  ele.height = 24;
                });
              }}
            >
              <FlexGridColumn binding="SEIKYU_NO" header="#" width={40} format="d"></FlexGridColumn>
              <FlexGridColumn binding="KOUJI_MEI" header="工事名" width={'1*'}></FlexGridColumn>
              <FlexGridColumn binding="ATESAKI" header="依頼主" width={'1*'}></FlexGridColumn>
              <FlexGridColumn binding="KOUJI_BASYO" header="工事場所" width={'2*'}></FlexGridColumn>
              <FlexGridColumn
                binding="TAN_CD"
                dataMap={planerMap}
                header="担当"
                width={80}
              ></FlexGridColumn>
            </FlexGrid>
          </div>
        </Col>
      </Row>
      <Row className="pt-2">
        <Col className="d-flex justify-content-between">
          <Button variant="secondary" className="footer-button" onClick={props.handleBack}>
            戻る
          </Button>
          <Button variant="primary" className="btn-fill footer-button" onClick={handleNext}>
            次へ
          </Button>
        </Col>
      </Row>
      <PaymentConstructionSelectModal params={addModalParams} />
    </>
  );
}

export default PaymentConstructionSelect;
