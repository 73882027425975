import { DataMap } from '@grapecity/wijmo.grid';
import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import { Card, Col, Row } from "react-bootstrap"
import { format } from "date-fns";

import { useRestApi } from 'context/RestApiContext';
import PaymentMonthSelect from "views/Payment/PaymentMonthSelect"
import PaymentRegister from "views/Payment/PaymentRegister"
import PaymentConstructionSelect from "views/Payment/PaymentConstructionSelect"
import PaymentFixed from "views/Payment/PaymentFixed"
import { MessageModal } from "components/modal/MessageModal"

const steps = ['請求年月を選択', '請求情報を登録', '対象の工事を選択', '原価を入力']

export default function Payment() {

    const restApi = useRestApi()

    // メッセージモーダル
    const [dialog, setDialog] = useState({})

    // 現在のステップ
    const [activeStep, setActiveStep] = useState(0)

    // 請求月
    const [paymentMonth, setPaymentMonth] = useState(format(new Date(), "yyyy/MM"))

    // 工事データ
    const [constructions, setConstructions] = useState([])

    // 取引先データMap
    const [toriDataMap, setToriDataMap] = useState(null)

    // 請求情報から宛先を取得
    const getAtesaki = (seikyu) => {
        // 請求を優先
        let atesaki = seikyu.SEIKYU_KBN === "client" ? seikyu.SEIKYU_CLIENT_NAME : seikyu.SEIKYU_TRI_NAME
            || (seikyu.QUOTE_KBN === "client" ? seikyu.QUOTE_CLIENT_NAME : seikyu.QUOTE_TRI_NAME)
        return atesaki

    }

    // 初期表示処理
    useEffect(() => {
        restApi.get("/api/master/tori", (data) => {
            setToriDataMap(new DataMap(
                [{ TRI_CD: -999, TRI_NAME: "" }, ...data.map(x => ({ TRI_CD: x.TRI_CD, TRI_NAME: `${x.TRI_CD}` + ": " + x.TRI_NAME }))]
                , "TRI_CD", "TRI_NAME"))
        })
    }, [])

    // 次へボタン
    const handleNext = () => {
        if (activeStep == steps.length - 1) {
            return
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
    // 戻るボタン
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    return (
        <>
            <Card className="m-0">
                <Card.Body>
                    <Row>
                        <Col className='p-0 m-0' md="10">
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    return (
                                        <Step key={index} completed={false}>
                                            <StepLabel>
                                                {label}
                                            </StepLabel>
                                        </Step>
                                    )
                                })}
                            </Stepper>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='p-0 m-1'>
                            {
                                // 請求年月選択
                                activeStep == 0 &&
                                <PaymentMonthSelect
                                    paymentMonth={paymentMonth}
                                    setPaymentMonth={setPaymentMonth}
                                    handleNext={handleNext} />
                            }
                            {
                                // 請求情報登録
                                activeStep == 1 &&
                                <PaymentRegister
                                    paymentMonth={paymentMonth}
                                    toriDataMap={toriDataMap}
                                    getAtesaki={getAtesaki}
                                    setDialog={setDialog}
                                    handleBack={handleBack}
                                    handleNext={handleNext} />
                            }
                            {
                                // 工事選択
                                activeStep == 2 &&
                                <PaymentConstructionSelect
                                    paymentMonth={paymentMonth}
                                    setConstructions={setConstructions}
                                    getAtesaki={getAtesaki}
                                    setDialog={setDialog}
                                    handleBack={handleBack}
                                    handleNext={handleNext} />
                            }
                            {
                                // 原価入力
                                activeStep == 3 &&
                                <PaymentFixed
                                    paymentMonth={paymentMonth}
                                    constructions={constructions}
                                    toriDataMap={toriDataMap}
                                    setDialog={setDialog}
                                    handleBack={handleBack} />
                            }
                        </Col>
                    </Row>
                </Card.Body >
            </Card >
            { /** メッセージモーダル */}
            <MessageModal dialog={dialog} />
        </>
    )
}