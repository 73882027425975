import { Chip } from '@mui/material';
import CommentIcon from '@mui/icons-material/Message';

// 入金状態
export const NYUKIN_STATUS = {
  NONE: 0,
  MAE_PART: 1,
  MAE_ALL: 2,
  PART: 3,
  ALL: 4,
  ERROR: 5,
};

// 入金状態一覧
export const NYUKIN_STATUS_LIST = [
  { id: NYUKIN_STATUS.NONE, label: '入金なし', color: '#172b4d', background: '#C4CAE9' },
  { id: NYUKIN_STATUS.MAE_PART, label: '着工金一部', color: '#172b4d', background: '#FEFFCE' },
  { id: NYUKIN_STATUS.MAE_ALL, label: '着工金確認', color: 'blue', background: '#FFE78E' },
  { id: NYUKIN_STATUS.PART, label: '入金一部', color: '#172b4d', background: '#FFC55F' },
  { id: NYUKIN_STATUS.ALL, label: '入金確認', color: 'blue', background: '#FF9530' },
  { id: NYUKIN_STATUS.ERROR, label: '入金トラブル', color: '#FFFFFF', background: 'red' },
];

// 入金状態ラベルコンポーネント
export function NyukinLabelComponent(props) {
  const status = NYUKIN_STATUS_LIST.find((s) => s.id == props.nyukin);
  const className = props.className;
  const size = props.size;
  const isDisabled = props.isDisabled;
  const onDelete = props.onDelete;
  const onMouseDown = props.onMouseDown;
  const onClick = props.onClick;
  const hidden = props.hidden;
  const hasComment = props.hasComment; // 入金コメントの有無

  return (
    <Chip
      className={className}
      label={status?.label}
      style={{
        minWidth: '62px',
        color: isDisabled ? '#172b4d' : status?.color,
        background: isDisabled ? '#e4e4e4' : status?.background,
      }}
      deleteIcon={hasComment ? <CommentIcon /> : null}
      size={size}
      onClick={onClick}
      onDelete={onDelete ?? hasComment ? onClick ?? (() => {}) : null}
      onMouseDown={onMouseDown}
      hidden={hidden}
    />
  );
}
