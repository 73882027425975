import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { CellType } from '@grapecity/wijmo.grid';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRestApi } from 'context/RestApiContext';
import StatusLableComponent from 'components/Input/StatusLableComponent';

/**
 * 追加請求情報
 * @param {*} props
 * @returns
 */
function AddInvoiceInfo(props) {
  const restApi = useRestApi();
  const location = useLocation();
  const navigate = useNavigate();

  // グリッド
  const grid = useRef();

  // パラメタ
  const addInvoiceList = props.addInvoiceList; // 追加請求一覧
  const selectedSeikyuNo = props.selectedSeikyuNo; // 請求NO
  const contractList = props.contractList; // 契約一覧
  const invoiceList = props.invoiceList; // 請求情報

  // 追加請求の新規作成
  const handleOnClickNewQuote = () => {
    navigate('/admin/addinvoice', {
      state: {
        mode: 'add',
        clientId: location?.state?.clientId,
        seikyuNo: selectedSeikyuNo,
        quoteNo: 0,
        backPage: location?.pathname,
        addInvoice: true,
      },
    });
  };

  // 追加請求編集・削除
  const handleOnClickEditQuote = (mode, item) => {
    navigate('/admin/addinvoice', {
      state: {
        mode: mode,
        clientId: location?.state?.clientId,
        seikyuNo: selectedSeikyuNo,
        quoteNo: item.QUOTE_NO,
        backPage: location?.pathname,
        addInvoice: true,
      },
    });
  };

  // 追加請求書の印刷
  const handleOnClickQuotePrint = (item) => {
    restApi.getToPdf(`/api/quote/report/${item.QUOTE_NO}`);
  };

  // 追加請求もり明細のアイコンが使用不可かどうか
  const isAddInvoiceIconDisabled = (icon, item) => {
    switch (icon) {
      case '編集':
      case '削除':
        // 請求の承認申請中、承認済みの場合は編集不可
        return isInvoiceApprove();
      case '承認申請':
        return item.STATUS == '承認待ち' || item.STATUS == '承認済み' || isInvoiceApprove();
      case '承認申請取消':
        return item.STATUS != '承認待ち';
      case '印刷':
        // 常に使用可能
        return false;
    }
  };

  // 請求情報が承認申請中、承認済みかどうか？
  const isInvoiceApprove = () => {
    return (
      invoiceList.length > 0 &&
      (invoiceList[0].STATUS == '承認待ち' || invoiceList[0].STATUS == '承認済み')
    );
  };

  // ステータスの既読処理
  const handleOnClickStatusLabel = (item) => {
    if (item.APP_UNREAD_FLG == 1) {
      restApi.post(
        '/api/client/appread',
        {
          TABLE: 'F_QUOTE_H',
          NO: item.QUOTE_NO,
        },
        (_) => {}
      );
    }
    item.APP_UNREAD_FLG = 0;
  };

  // 見積一覧のグリッドのフォーマット処理
  const quoteListFormatItem = (grid, e) => {
    if (e.panel.cellType == CellType.Cell) {
      var dataItem = grid.rows[e.row].dataItem;

      // 申請承認画面から呼び出された場合に選択した見積の場合に青くする
      let isUpd = location.state?.quoteNo == dataItem.QUOTE_NO;

      e.cell.style.color = isUpd ? 'blue' : '';
      e.cell.style.fontWeight = isUpd ? 'bold' : '';
      e.cell.style.background = isUpd ? '#DDEEFF' : '';
    }
  };

  return (
    <>
      <Row>
        <Col>
          <div className="list-label1">追加請求一覧</div>
        </Col>
        <Col></Col>
        <Row>
          <Col className="mr-3 pt-0 d-flex flex-nowrap text-right align-items-end">
            <Button
              className="material-symbols-rounded cell-icon btn-fill"
              variant="primary"
              onClick={handleOnClickNewQuote}
              disabled={contractList?.length == 0 || isInvoiceApprove()}
              title="追加請求作成"
            >
              add
            </Button>
          </Col>
        </Row>
      </Row>
      <Row>
        <Col>
          <div className="list-3-line">
            <FlexGrid
              ref={grid}
              itemsSource={addInvoiceList}
              selectionMode={'None'}
              headersVisibility={'Column'}
              isReadOnly={true}
              loadedRows={(grid) => {
                grid.rows.forEach((ele) => {
                  ele.height = 38;
                });
              }}
              formatItem={quoteListFormatItem}
            >
              <FlexGridColumn binding="NO" header="No" width={60} align={'right'}></FlexGridColumn>
              <FlexGridColumn
                binding="QUOTE_NO"
                header="見積番号"
                format={'d'}
                width={95}
              ></FlexGridColumn>
              <FlexGridColumn binding="MITSU_DATE" header="作成日" width={95}></FlexGridColumn>
              <FlexGridColumn
                binding="SYANAI_MEI"
                header="工事名（社内呼称）"
                width="2*"
                allowResizing={false}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="MITSU_KIN_ZEI"
                header="金額(税込)"
                width={100}
                align={'right'}
              ></FlexGridColumn>
              <FlexGridColumn binding="STATUS" header="ステータス" width={88} align="center">
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(cell) => (
                    <StatusLableComponent
                      status={cell.item?.STATUS}
                      isUnread={cell.item?.APP_UNREAD_FLG == 1}
                      onClick={() => handleOnClickStatusLabel(cell.item)}
                    />
                  )}
                />
              </FlexGridColumn>
              <FlexGridColumn header="" binding="" isReadOnly={true} width={185}>
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(cell) => (
                    <span>
                      <Button
                        className="material-symbols-rounded cell-icon btn-fill"
                        variant="primary"
                        title="編集"
                        onClick={() => handleOnClickEditQuote('upd', cell.item)}
                        disabled={isAddInvoiceIconDisabled('編集', cell.item)}
                      >
                        edit
                      </Button>
                      <Button
                        className="material-symbols-rounded cell-icon btn-fill"
                        variant="danger"
                        title="削除"
                        onClick={() => handleOnClickEditQuote('del', cell.item)}
                        disabled={isAddInvoiceIconDisabled('削除', cell.item)}
                      >
                        delete
                      </Button>
                      <Button
                        className="material-symbols-rounded cell-icon btn-fill"
                        variant="primary"
                        title="印刷"
                        onClick={() => handleOnClickQuotePrint(cell.item)}
                        disabled={isAddInvoiceIconDisabled('印刷', cell.item)}
                      >
                        print
                      </Button>
                      <Button
                        className="cell-icon btn-fill"
                        variant="primary"
                        title="承認申請"
                        onClick={() => handleOnClickEditQuote('request', cell.item)}
                        disabled={isAddInvoiceIconDisabled('承認申請', cell.item)}
                      >
                        申
                      </Button>
                      <Button
                        className="cell-icon btn-fill"
                        variant="danger"
                        title="承認申請取消"
                        onClick={() => handleOnClickEditQuote('cancel', cell.item)}
                        disabled={isAddInvoiceIconDisabled('承認申請取消', cell.item)}
                      >
                        消
                      </Button>
                    </span>
                  )}
                />
              </FlexGridColumn>
            </FlexGrid>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default AddInvoiceInfo;
