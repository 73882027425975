import { Badge, Chip } from '@mui/material';
import { useState } from 'react';

// ステータスラベルコンポーネント
function StatusLableComponent(props) {
  const STATUS_LIST = [
    { id: '新規', label: '新規', color: '#172b4d', background: '#C4CAE9' },
    { id: '作成中', label: '作成中', color: '#172b4d', background: '#C4CAE9' },
    {
      id: '承認待ち',
      label: '申請中',
      color: '#ffffff',
      background: '#636363',
      badgeColor: 'success',
    },
    { id: '承認済み', label: '承認', color: '#ffffff', background: '#1976D2', badgeColor: 'error' },
    { id: '承認却下', label: '却下', color: '#ffffff', background: '#dc3545', badgeColor: 'info' },
    { id: '契約済', label: '契約', color: '#ffffff', background: '#3FBF31' },
    { id: '発注なし', label: '発注なし', color: '#7d310e', background: '#f7c2aa' },
    { id: 'マイナス', label: 'マイナス', color: '#feeeed', background: '#f85a51' },
  ];
  const status = STATUS_LIST.find((s) => s.id == props.status);
  const overlap = props.isUnread ? 'circular' : 'rectangular';
  const badgeContent = props.badgeContent;
  const hidden = props.hidden;
  const className = props.className;
  const sx = props.isUnread ? { '& .MuiBadge-badge': { height: 10, minWidth: 10 } } : {};

  // バッジの種類を設定
  const [variant, setVariant] = useState(
    badgeContent?.length ? 'standard' : props.isUnread ? 'dot' : ''
  );

  // クリック処理
  const handleOnClick = () => {
    if (variant == 'dot') {
      setVariant('');
      props.onClick && props.onClick();
    }
  };

  return (
    <Badge
      className={className}
      color={status?.badgeColor}
      overlap={overlap}
      badgeContent={badgeContent}
      hidden={hidden}
      variant={variant}
      sx={sx}
    >
      <Chip
        label={status?.label}
        style={{ minWidth: '62px', color: status?.color, background: status?.background }}
        size="small"
        onClick={variant == 'dot' ? () => handleOnClick('') : null}
      />
    </Badge>
  );
}

export default StatusLableComponent;
