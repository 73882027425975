
import { useState } from "react"
import { useAuth } from "context/AuthContext"
import { useNavigate } from "react-router-dom"
import pic from "assets/img/logo.png"
import { Container, Card, Button, Row, Col, Form } from "react-bootstrap";

import 'assets/css/login.css';
import TextComponent from "components/Input/TextComponent";

export const Login = (props) => {

    const auth = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState("")
    const [user, setUser] = useState({ id: "", password: "" })

    // 編集処理
    const handleChange = (prop) => (target) => {
        setUser({ ...user, [prop]: target.value })
    };

    // サブミット処理
    const handleOnSubmit = (e) => {
        e.preventDefault();
        handleOnClickLogin(); // ログイン処理を呼び出し
        return false;
    };

    // ログインボタン
    const handleOnClickLogin = () => {
        setError("")
        const baseUrl = process.env.REACT_APP_REST_URL ?? ""
        fetch(`${baseUrl}${"/auth"}`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json;' },
                body: JSON.stringify({
                    username: user.id,
                    password: user.password,
                }),
                cache: "no-cache",
                mode: 'cors',
            })
            .then(res => {
                // 認証エラーの場合はログアウト処理
                if (!res.ok) {
                    setError("ユーザ名またはパスワードに誤りがあります。")
                    return undefined
                }
                return res.json()
            })
            .then(data => {
                if (data == undefined) {
                    return
                }
                auth.setAuth({
                    isAuthed: true,
                    access_token: data.access_token,
                    user_id: data.user_id,
                    user_name: data.user_name,
                    section: data.section,
                    kbn: data.kbn,
                });

                // 初期ページへ遷移
                navigate(auth.getDefaultPage())
            })
    };

    return (
        <Container fluid>
            <Row className="login-base d-flex justify-content-center pt-5">
                <Col md="3">
                    <Card>
                        <Card.Body className="m-3">
                            <form onSubmit={handleOnSubmit}>
                                <Row>
                                    <Col md="2" />
                                    <Col md="8">
                                        <img className="loginlogo" src={pic} />
                                    </Col>
                                </Row>
                                <Row>
                                    <label style={{ color: "red" }}>{error}</label>
                                </Row>
                                <Row>
                                    <Col md="2" />
                                    <Col md="8">
                                        <Form.Group>
                                            <label>ユーザ</label>
                                            <TextComponent
                                                value={user.id}
                                                type="text"
                                                onChange={handleChange("id")}
                                            ></TextComponent>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="pt-3">
                                    <Col md="2" />
                                    <Col md="8">
                                        <Form.Group>
                                            <label>パスワード</label>
                                            <TextComponent
                                                value={user.password}
                                                type="password"
                                                onChange={handleChange("password")}
                                            ></TextComponent>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="pt-4">
                                    <Col md="2" />
                                    <Col md="8" className="text-center">
                                        <Button type="submit" className="btn-fill pull-right">ログイン</Button>
                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}
