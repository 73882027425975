import { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router';

import { Button, Card, Form, Row, Col } from 'react-bootstrap';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useRestApi } from 'context/RestApiContext';

import SelectComponent from 'components/Input/SelectComponent';

function OrderDestSelectModal(props) {
  const restApi = useRestApi();
  const navigate = useNavigate();
  const location = useLocation();

  // 注文先一覧
  const [orderdests, setOrderdests] = useState([]);
  // 選択中の注文先
  const [selectedOrderDest, setSelectedOrderDest] = useState('');
  // モーダルの表示
  const [show, setShow] = useState(false);

  // モーダルパラメタの変更処理
  useEffect(() => {
    if (props.params.keiNo) {
      // 取引先を取得
      restApi.get('/api/master/tori', (data) => {
        // 発注区分が 1:発注あり の取引先のみ表示
        setOrderdests(data.filter((x) => x.HACYU_KBN == '1'));
      });
      setShow(true);
    }
  }, [props.params]);

  // 選択ボタン押下
  const handleSelect = () => {
    navigate('/admin/orderentry', {
      state: {
        mode: 'add',
        backPage: location?.pathname,
        keiNo: props.params.keiNo,
        orderDest: orderdests.find((x) => x.TRI_CD == selectedOrderDest),
        checkedPrinted: location.state.checkedPrinted,
      },
    });
  };

  // 編集処理
  const handleChange = (target) => {
    setSelectedOrderDest(target.value);
  };

  // 閉じるボタン
  const handleClose = useCallback(() => {
    setSelectedOrderDest('');
    setShow(false);
  }, [props.params]);

  return (
    <>
      <Dialog fullWidth={true} maxWidth={'sm'} open={show}>
        <DialogTitle className="p-3">注文先選択</DialogTitle>
        <DialogContent className="px-0 py-0 pr-0 pl-0">
          <Card className="m-0">
            <Card.Body>
              <Row>
                <Col className="pl-3" md="10">
                  <Form.Group>
                    <label>注文先</label>
                    <SelectComponent value={selectedOrderDest} onChange={handleChange}>
                      <option value=""></option>
                      {orderdests.map((x, i) => (
                        <option key={i} value={x.TRI_CD}>
                          {x.TRI_NAME}
                        </option>
                      ))}
                    </SelectComponent>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </DialogContent>
        <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
          <Button variant="secondary" className="footer-button" onClick={handleClose}>
            閉じる
          </Button>
          <Button
            className="btn-fill footer-button"
            variant="primary"
            disabled={selectedOrderDest == ''}
            onClick={handleSelect}
          >
            選択
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default OrderDestSelectModal;
