import { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { Button, Card, Form, Row, Col } from 'react-bootstrap';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useRestApi } from 'context/RestApiContext';

/** 未入金の請求書の再印刷画面 */
function SeikyusyoRePrintModal(props) {
  // グリッド
  const flexGrid = useRef();
  const scrollPosition = useRef(0);
  // RESTAPI
  const restApi = useRestApi();

  // 表示
  const [show, setShow] = useState(false);
  // 未入金の請求書データ
  const [seikyusyoList, setSeikyusyoList] = useState([]);

  // 全選択
  const [allSelected, setAllSelected] = useState(true);

  // モーダルパラメタの変更処理
  useEffect(() => {
    if (props?.params?.show) {
      restApi.get('/api/accounting/seikyusyo', (data) => {
        setSeikyusyoList(
          data.seikyusyoList.map((d) => ({
            ...d,
            Check: true,
            ATESAKI: d.SEIKYU_KBN === 'client' ? d.SEIKYU_CLIENT_NAME : d.SEIKYU_TRI_NAME,
          }))
        );
        // ダイアログを表示
        setShow(true);
      });
    }
  }, [props.params]);

  // スクロールの位置を保持
  useEffect(() => {
    if (flexGrid.current?.control) {
      scrollPosition.current = flexGrid.current.control.scrollPosition = scrollPosition.current;
    }
  }, [seikyusyoList]);

  // チェックボックスの変更処理
  const handleOnChangeCheck = (item) => (e) => {
    // スクロール位置を保持
    scrollPosition.current = flexGrid.current.control.scrollPosition;

    setSeikyusyoList(
      seikyusyoList.map((s) =>
        s.SEIKYU_NO === item.SEIKYU_NO ? { ...s, Check: e.target.checked } : s
      )
    );

    if (!e.target.checked) {
      setAllSelected(e.target.checked);
    }
  };
  // 全選択
  const handleChangeAllSelected = (e) => {
    setAllSelected(e.target.checked);

    // スクロール位置を保持
    scrollPosition.current = flexGrid.current.control.scrollPosition;
    setSeikyusyoList(seikyusyoList.map((s) => ({ ...s, Check: e.target.checked })));
  };
  // 閉じるボタン
  const handleClose = () => {
    setShow(false);
  };

  // 印刷ボタン
  const handlePrint = () => {
    restApi.postToPdf(`/api/accounting/seikyusyo_print`, {
      seikyu_no_list: seikyusyoList.filter((s) => s.Check).map((s) => s.SEIKYU_NO),
    });
  };

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '720px',
            },
          },
        }}
        open={show}
      >
        <DialogTitle className="p-3">請求書再印刷</DialogTitle>
        <DialogContent className="px-0 py-0 pr-0 pl-0">
          <Card className="m-0">
            <Card.Body>
              <Row className="m-0 p-0">
                <Col className="m-0 p-0">
                  ・請求書発行済で30日が経過した入金済みでない請求書を再印刷します。
                  <br />
                  ・請求書はAM1:00に作成されたものになります。
                </Col>
              </Row>{' '}
              <Row className="m-0 p-0">
                <Col className="m-0 p-0">
                  <label style={{ fontWeight: '400', fontSize: '16px', color: 'black' }}>
                    <input
                      className="ml-2"
                      style={{ transform: 'scale(1.5)' }}
                      type="checkbox"
                      checked={allSelected}
                      onChange={handleChangeAllSelected}
                    ></input>
                    &nbsp;&nbsp;全選択
                  </label>
                </Col>
              </Row>
              <Row className="mt-0">
                <Col className="pt-0 pl-3 table-scroll" md="12" style={{ height: '420px' }}>
                  <FlexGrid
                    ref={flexGrid}
                    allowSorting={false}
                    itemsSource={seikyusyoList}
                    selectionMode={'Cell'}
                    headersVisibility={'Column'}
                    autoGenerateColumns={true}
                    isReadOnly={true}
                    loadedRows={(grid) => {
                      grid.rows.forEach((ele) => {
                        ele.height = 24;
                      });
                    }}
                    updatedView={(grid) => {
                      grid.rows.forEach((ele) => {
                        ele.height = 24;
                      });
                    }}
                  >
                    <FlexGridColumn header="選" binding="Check" isReadOnly={true} width={28}>
                      <FlexGridCellTemplate
                        cellType="Cell"
                        template={(cell) => (
                          <input
                            type="checkbox"
                            checked={cell.item.Check}
                            onChange={handleOnChangeCheck(cell.item)}
                          ></input>
                        )}
                      />
                    </FlexGridColumn>
                    <FlexGridColumn
                      binding="KOUJI_MEI"
                      header="工事名"
                      width={'*'}
                    ></FlexGridColumn>
                    <FlexGridColumn binding="ATESAKI" header="宛先" width={200}></FlexGridColumn>
                    <FlexGridColumn
                      binding="SEIKYU_DATE"
                      header="請求日"
                      width={100}
                    ></FlexGridColumn>
                  </FlexGrid>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </DialogContent>
        <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
          <Button variant="secondary" className="footer-button" onClick={handleClose}>
            閉じる
          </Button>
          <Button variant="primary btn-fill" className="footer-button" onClick={handlePrint}>
            印刷
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SeikyusyoRePrintModal;
