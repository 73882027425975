import { DataMap } from '@grapecity/wijmo.grid';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import TextComponent from 'components/Input/TextComponent';
import { useMaster } from 'context/MasterContext';
import { useRestApi } from 'context/RestApiContext';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import SelectComponent from 'components/Input/SelectComponent';

/** 請求月の選択画面 */
function PaymentMonthSelect(props) {
  const paymentMonth = props.paymentMonth;
  const setPaymentMonth = props.setPaymentMonth;

  // 編集処理
  const handleChange = (prop) => (target) => {
    setClient({ ...client, [prop]: target?.value ?? target?.target.value });
  };

  return (
    <>
      <Row className="pt-4">
        <Col className="pr-1">・請求年月を選択してください。</Col>
      </Row>
      <Row className="pt-2">
        <Col className="pr-1" md="2">
          <Form.Group style={{ height: 'calc(100vh - 243px)' }}>
            <label>請求年月</label>
            <TextComponent
              value={paymentMonth}
              onChange={(target) => {
                setPaymentMonth(target.value);
              }}
              type="date"
              selectionMode="Month"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="pt-0">
        <Col className="text-right">
          <Button variant="primary" className="btn-fill footer-button" onClick={props.handleNext}>
            次へ
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default PaymentMonthSelect;
