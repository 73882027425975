import { useState, useContext, useEffect, useRef, useCallback } from 'react';

import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { CellType, KeyAction } from '@grapecity/wijmo.grid';

import { Button, Card, Form, Row, Col } from 'react-bootstrap';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useRestApi } from 'context/RestApiContext';
import { toFloat, toInt } from 'utils/numberUtils';

function OrderDetailsEditModal(props) {
  const restApi = useRestApi();

  const flexGrid = useRef();

  // 見積明細
  const [quoteDetails, setQuoteDetails] = useState([]);

  // 注文明細
  const [orderDetails, setOrderDetails] = useState([]);

  // 注文明細（表示用）
  const [viewOrderDetails, setViewOrderDetails] = useState([]);

  // 選択中の明細
  const [selectedRow, setSelectedRow] = useState(-1);

  // 請求全体の合計
  const [seikyuTotal, setSeikyuTotal] = useState([
    {
      SeikyuKei: 0,
      OrderKei: 0,
      ArariKei: 0,
      RiekiRitsu: 0,
    },
  ]);

  // 合計
  const [total, setTotal] = useState([
    {
      ShoKei: 0,
      ZeiKei: 0,
      OrderKei: 0,
      MitsuKei: 0,
      ArariKei: 0,
      RiekiRitsu: 0,
    },
  ]);

  // モーダルの表示
  const [show, setShow] = useState(false);

  // 初期表示
  useEffect(() => {
    if (props.seikyuNo != -1) {
      // 見積もり明細を取得
      restApi.get(`/api/order/quotedetails/${props.seikyuNo}`, (data) => {
        setQuoteDetails(data.details.map((x) => ({ ...x, Check: false })));
      });
    }
  }, [props.seikyuNo]);

  // パラメタの変更時の処理
  useEffect(() => {
    // 明細を未選択状態に
    setSelectedRow(-1);
    setQuoteDetails(
      [...quoteDetails].map((x) => ({
        ...x,
        Check: props.params.orderDetails.some((d) => d.QUOTE_MNO_KEY == x.QUOTE_MNO_KEY),
      }))
    );
    setShow(props.params.show);
    setOrderDetails([...props.params.orderDetails].map((x) => ({ ...x })));
  }, [props.params]);

  // 注文明細の変更時の処理
  useEffect(() => {
    let _orderDetails = [...orderDetails];
    setViewOrderDetails(_orderDetails);
    // 合計を計算
    calcTotal(orderDetails);
  }, [orderDetails]);

  // 合計の計算処理
  const calcTotal = (orderDetails) => {
    // 小計
    let shokei = orderDetails.reduce((a, x) => a + toFloat(x['KINGAKU']), 0);
    // 消費税
    let tax = Math.floor(shokei * 0.1);
    // 請求額
    let seikyu = shokei + tax;
    // 見積合計
    let mitsu_kingaku = orderDetails.reduce((a, x) => a + toFloat(x['MITSU_KINGAKU']), 0);
    // 粗利
    let arari = mitsu_kingaku - shokei;
    // 粗利率
    let arariritu = mitsu_kingaku > 0 ? Math.floor((arari / mitsu_kingaku) * 100) / 100 : 0;

    // 全体の注文金額
    let cyukinTotal = props.otherCyuKin + shokei;
    // 全体の請求金額に対する粗利
    let arariTotal = props.seikyuKin - cyukinTotal;
    // 全体の粗利率
    let ararirituTotal =
      props.seikyuKin > 0 ? Math.floor((arariTotal / props.seikyuKin) * 100) / 100 : 0;

    // 全体の請求金額に反映
    setSeikyuTotal([
      {
        SeikyuKei: props.seikyuKin,
        OrderKei: cyukinTotal,
        ArariKei: arariTotal,
        RiekiRitsu: ararirituTotal,
      },
    ]);

    // 集計結果を反映
    setTotal([
      {
        ShoKei: shokei,
        ZeiKei: tax,
        OrderKei: seikyu,
        MitsuKei: mitsu_kingaku,
        ArariKei: arari,
        RiekiRitsu: !isNaN(arariritu) ? arariritu : 0,
      },
    ]);
  };

  // 見積明細のチェックボックス
  const handleOnCheckedQuote = (item) => (e) => {
    item['Check'] = e.target.checked;
    if (e.target.checked) {
      setOrderDetails([
        ...orderDetails,
        {
          KOUJI_SYU: item.KOUJI_SYU,
          CONTENT: item.CONTENT,
          SIYOU: item.SIYOU,
          SU: item.SU,
          TANI: item.TANI,
          TANKA: item.GENKA_TANKA,
          KINGAKU: item.GENKA_KINGAKU,
          BIKO: item.BIKO,
          GENKA_RATE: item.GENKA_RATE,
          MITSU_TANKA: item.TANKA,
          MITSU_KINGAKU: item.KINGAKU,
          BIKO_SYANAI: item.BIKO_SYANAI,
          QUOTE_MNO_KEY: item.QUOTE_MNO_KEY,
        },
      ]);
    } else if (item['QUOTE_MNO_KEY'] < 0) {
      setOrderDetails(orderDetails.filter((x) => x['QUOTE_MNO_KEY'] != item['QUOTE_MNO_KEY']));
    } else {
      // 諸経費の場合
      setOrderDetails(
        orderDetails.filter(
          (x) => !(x['KOUJI_SYU'] == item['KOUJI_SYU'] && x['CONTENT'] == item['CONTENT'])
        )
      );
    }
  };

  // 注文明細の削除ボタン
  const handleOnClickDelete = (item) => (e) => {
    // 登録用の明細から削除
    setOrderDetails(orderDetails.filter((x) => x != item));
    // 見積明細のチェックを外す
    setQuoteDetails(
      quoteDetails.map((x) => ({
        ...x,
        Check: x['QUOTE_MNO_KEY'] == item['QUOTE_MNO_KEY'] ? false : x.Check,
      }))
    );
  };

  // 手入力の注文明細の追加ボタン
  const handlenOnClickAdd = () => {
    setOrderDetails([
      ...orderDetails,
      { QUOTE_MNO_KEY: '', KINGAKU: 0, GENKA_RATE: '', MITSU_TANKA: '', MITSU_KINGAKU: '' },
    ]);
  };

  // 確定ボタン
  const handleOnClickFix = () => {
    props.callBack(orderDetails);
    setShow(false);
  };

  // 閉じるボタン
  const handleClose = useCallback(() => {
    setShow(false);
  }, [props.params]);

  // 明細の移動処理
  const handleOnClickMove = (item, upDown) => (e) => {
    item = selectedRow;
    // 移動位置を取得
    let idx = orderDetails.indexOf(item) + (upDown == 'down' ? 1 : -1);
    if (idx < 0) {
      return;
    }
    let _orderDetails = orderDetails.filter((x) => x != item);
    _orderDetails.splice(idx, 0, item);
    setOrderDetails(_orderDetails);
  };

  // グリッドの選択時の処理（ime制御)
  const gridSelectionChanged = (grid, args) => {
    var col = grid.columns[args.col];
    // 数量、単価の場合はimeをoffにする
    var imeEnabled = !['SU', 'TANKA'].some((x) => x == col?.binding);
    setTimeout(function () {
      grid.imeEnabled = imeEnabled;
    }, 50);
  };

  // 貼り付け処理（編集開始処理と同じ処理）
  const pastingCell = (s, args) => {
    beginningEdit(s, args);
  };
  // 編集開始処理
  const beginningEdit = (grid, args) => {
    var dataItem = grid.rows[args.row].dataItem;
    var col = grid.columns[args.col];
    // 見積明細から選択した項目の場合、単価、適用、社内備考以外は入力不可
    if (dataItem && dataItem['QUOTE_MNO_KEY']) {
      var readOnlys = [
        'KOUJI_SYU',
        'CONTENT',
        'SIYOU',
        'SU',
        'TANI',
        'KINGAKU',
        'GENKA_RATE',
        'MITSU_TANKA',
        'MITSU_KINGAKU',
      ];
      if (readOnlys.includes(col['binding'])) {
        args.cancel = true;
      }
    }
    // 手動の場合は以下を入力不可
    else {
      var readOnlys = ['KINGAKU', 'GENKA_RATE', 'MITSU_TANKA', 'MITSU_KINGAKU'];
      if (readOnlys.includes(col['binding'])) {
        args.cancel = true;
      }
    }
  };

  // 貼り付け後の処理
  const pasted = (grid, args) => {
    cellEditEnded(grid, args);
  };
  // 編集終了処理
  const cellEditEnded = (grid, args) => {
    var dataItem = grid.rows[args.row].dataItem;
    var col = grid.columns[args.col];
    let upd = false;

    // 単価の場合
    if (col['binding'] == 'TANKA') {
      let tanka = toFloat(dataItem['TANKA']);
      let mitsu_tanka = toFloat(dataItem['MITSU_TANKA']);
      let su = toFloat(dataItem['SU'], 1);
      let kingaku = toInt(tanka * su);

      let rate = '';
      if (mitsu_tanka && tanka) {
        rate = toFloat(tanka / mitsu_tanka, 2);
      }
      dataItem['KINGAKU'] = kingaku;
      dataItem['GENKA_RATE'] = rate;

      upd = true;
    }
    // 数量の場合
    else if (col['binding'] == 'SU') {
      let su = toFloat(dataItem['SU'], 1);
      let tanka = toFloat(dataItem['TANKA']);

      dataItem['SU'] = su;
      dataItem['KINGAKU'] = toInt(tanka * su);
      upd = true;
    }

    // 一覧の再描画をおこなう
    if (upd) {
      grid.beginUpdate();
      grid.setCellData(args.row, 7, dataItem['KINGAKU'], true);
      grid.setCellData(args.row, 9, dataItem['GENKA_RATE'], true);
      grid.endUpdate();

      // 合計を計算
      calcTotal(grid.rows.map((x) => x.dataItem));
    }
  };

  // グリッドのフォーマット処理
  const formatItem = (grid, e) => {
    if (e.panel.cellType == CellType.Cell) {
      var col = grid.columns[e.col];
      var dataItem = grid.rows[e.row].dataItem;
      // 見積明細から選択した場合は以下を非活性
      if (dataItem && dataItem['QUOTE_MNO_KEY']) {
        var readOnlys = [
          'KOUJI_SYU',
          'CONTENT',
          'SIYOU',
          'SU',
          'TANI',
          'KINGAKU',
          'GENKA_RATE',
          'MITSU_TANKA',
          'MITSU_KINGAKU',
        ];
        if (readOnlys.includes(col['binding'])) {
          e.cell.style.background = 'rgb(236, 219, 219)';
        }
      }
      // 手動の場合は以下を非活性
      else {
        var readOnlys = ['KINGAKU', 'GENKA_RATE', 'MITSU_TANKA', 'MITSU_KINGAKU'];
        if (readOnlys.includes(col['binding'])) {
          e.cell.style.background = 'rgb(236, 219, 219)';
        } else {
          e.cell.style.background = '';
        }
      }

      // 単価と数量から金額を計算
      if (col['binding'] == 'KINGAKU') {
        let tanka = toFloat(dataItem['TANKA']);
        let su = toFloat(dataItem['SU'], 1);

        dataItem['SU'] = su;
        dataItem['KINGAKU'] = toInt(tanka * su);
      }
      // 単価と見積単価から掛け率を取得
      if (col['binding'] == 'GENKA_RATE') {
        let genka = toFloat(dataItem['TANKA']);
        let tanka = toFloat(dataItem['MITSU_TANKA']);

        if (tanka && genka) {
          let rate = genka / tanka;
          dataItem['GENKA_RATE'] = toFloat(rate, 2);
        } else {
          dataItem['GENKA_RATE'] = '';
        }
      }
    }
  };

  return (
    <>
      <Dialog fullWidth={true} maxWidth={'xl'} open={show}>
        <DialogTitle className="p-3">注文明細登録</DialogTitle>
        <DialogContent className="px-0 py-0 pr-0 pl-0">
          <Card className="m-0">
            <Card.Body>
              <Row>
                <Col className="pt-2 pl-3 table-scroll" md="12" style={{ height: '318px' }}>
                  <FlexGrid
                    allowSorting={false}
                    itemsSource={quoteDetails}
                    selectionMode={'Cell'}
                    headersVisibility={'Column'}
                    isReadOnly={true}
                    frozenColumns={3}
                    updatedView={(grid) => {
                      grid.rows.forEach((ele) => {
                        ele.height = 24;
                      });
                    }}
                  >
                    <FlexGridColumn header="選" binding="Check" isReadOnly={true} width={28}>
                      <FlexGridCellTemplate
                        cellType="Cell"
                        template={(cell) =>
                          !cell.item.CYU_NO || cell.item.CYU_NO == props.cyuNo ? (
                            <input
                              type="checkbox"
                              checked={cell.item.Check}
                              onChange={handleOnCheckedQuote(cell.item)}
                            ></input>
                          ) : (
                            <></>
                          )
                        }
                      />
                    </FlexGridColumn>
                    <FlexGridColumn
                      binding="KOUJI_SYU"
                      header="工事種別"
                      width={200}
                      cssClass="cell-readonly"
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="CONTENT"
                      header="名称"
                      width={200}
                      cssClass="cell-readonly"
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="SIYOU"
                      header="仕様"
                      width={200}
                      cssClass="cell-readonly"
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="SU"
                      header="数量"
                      dataType="Number"
                      format="n1"
                      width={100}
                      cssClass="cell-readonly"
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="TANI"
                      header="単位"
                      width={50}
                      cssClass="cell-readonly"
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="GENKA_TANKA"
                      header="単価"
                      dataType="Number"
                      width={100}
                      cssClass="cell-readonly"
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="GENKA_KINGAKU"
                      header="金額"
                      dataType="Number"
                      width={100}
                      cssClass="cell-readonly"
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="BIKO"
                      header="摘要"
                      width={100}
                      cssClass="cell-readonly"
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="GENKA_RATE"
                      header="掛け率"
                      width={60}
                      cssClass="cell-readonly"
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="TANKA"
                      header="見積単価"
                      dataType="Number"
                      width={100}
                      cssClass="cell-readonly"
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="KINGAKU"
                      header="見積金額"
                      dataType="Number"
                      width={100}
                      cssClass="cell-readonly"
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="BIKO_SYANAI"
                      header="社内備考"
                      width={100}
                      cssClass="cell-readonly"
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="TRI_NAME"
                      header="取引先"
                      width={100}
                      cssClass="cell-readonly"
                    ></FlexGridColumn>
                  </FlexGrid>
                </Col>
              </Row>
              <Row className="pt-2">
                <Col>
                  <Button
                    className="material-symbols-rounded cell-icon-sm btn-fill"
                    variant="success"
                    disabled={orderDetails.indexOf(selectedRow) < 0}
                    title="下に移動"
                    onClick={handleOnClickMove({}, 'down')}
                  >
                    Arrow_Downward
                  </Button>
                  <Button
                    className="material-symbols-rounded cell-icon-sm btn-fill ml-1"
                    variant="success"
                    disabled={orderDetails.indexOf(selectedRow) < 0}
                    title="上に移動"
                    onClick={handleOnClickMove({}, 'up')}
                  >
                    Arrow_Upward
                  </Button>
                </Col>
                <Col className="text-right">
                  <Button
                    className="material-symbols-rounded cell-icon-sm btn-fill"
                    title="追加"
                    onClick={handlenOnClickAdd}
                  >
                    add
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className="pt-2 pl-3 table-scroll" md="12" style={{ height: '318px' }}>
                  <FlexGrid
                    imeEnabled={true}
                    ref={flexGrid}
                    allowSorting={false}
                    itemsSource={viewOrderDetails}
                    selectionMode={'Cell'}
                    headersVisibility={'Column'}
                    beginningEdit={beginningEdit}
                    formatItem={formatItem}
                    pastingCell={pastingCell}
                    cellEditEnded={cellEditEnded}
                    pasted={pasted}
                    keyActionTab={KeyAction.Cycle}
                    autoGenerateColumns={false}
                    selectionChanged={gridSelectionChanged}
                    loadedRows={(grid) => {
                      grid.rows.forEach((ele) => {
                        ele.height = 24;
                      });
                    }}
                    updatedView={(grid) => {
                      grid.rows.forEach((ele) => {
                        ele.height = 24;
                      });
                    }}
                  >
                    <FlexGridColumn header="　" isReadOnly={true} width={28}>
                      <FlexGridCellTemplate
                        cellType="Cell"
                        template={(cell) => (
                          <input
                            type="radio"
                            checked={cell.item === selectedRow}
                            onClick={() => setSelectedRow(cell.item)}
                          ></input>
                        )}
                      />
                    </FlexGridColumn>
                    <FlexGridColumn
                      binding="KOUJI_SYU"
                      header="工事種別"
                      width={200}
                      isRequired={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="CONTENT"
                      header="名称"
                      width={200}
                      isRequired={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="SIYOU"
                      header="仕様"
                      width={200}
                      isRequired={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="SU"
                      header="数量"
                      dataType="Number"
                      format="n1"
                      width={100}
                      isRequired={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="TANI"
                      header="単位"
                      width={50}
                      isRequired={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="TANKA"
                      header="単価"
                      dataType="Number"
                      width={100}
                      isRequired={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="KINGAKU"
                      header="金額"
                      dataType="Number"
                      width={100}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="BIKO"
                      header="摘要"
                      width={100}
                      isRequired={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="GENKA_RATE"
                      header="掛け率"
                      width={60}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="MITSU_TANKA"
                      header="見積単価"
                      dataType="Number"
                      width={100}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="MITSU_KINGAKU"
                      header="見積金額"
                      dataType="Number"
                      width={100}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="BIKO_SYANAI"
                      header="社内備考"
                      width="2*"
                      allowResizing={false}
                      isRequired={false}
                    ></FlexGridColumn>
                    <FlexGridColumn header="　" binding="Check" isReadOnly={true} width={28}>
                      <FlexGridCellTemplate
                        cellType="Cell"
                        template={(cell) => (
                          <Button
                            className="material-symbols-rounded cell-icon-sm btn-fill"
                            variant="danger"
                            title="削除"
                            onClick={handleOnClickDelete(cell.item)}
                          >
                            Clear
                          </Button>
                        )}
                      />
                    </FlexGridColumn>
                  </FlexGrid>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </DialogContent>
        <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
          <Button className="footer-button" variant="secondary" onClick={handleClose}>
            閉じる
          </Button>
          <div style={{ width: '400' }}>
            <FlexGrid
              itemsSource={seikyuTotal}
              selectionMode={'None'}
              headersVisibility={'Column'}
              isReadOnly={true}
              allowSorting={false}
            >
              <FlexGridColumn
                binding="SeikyuKei"
                header="請求金額(税抜)"
                format="c0"
                width={'1*'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="OrderKei"
                header="注文金額(税抜)"
                format="c0"
                width={'1*'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="ArariKei"
                header="粗利益額"
                format="c0"
                width={'1*'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="RiekiRitsu"
                header="粗利率"
                format="n2"
                width={'1*'}
              ></FlexGridColumn>
            </FlexGrid>
          </div>
          <div style={{ width: '600' }}>
            <FlexGrid
              itemsSource={total}
              selectionMode={'None'}
              headersVisibility={'Column'}
              isReadOnly={true}
              allowSorting={false}
            >
              <FlexGridColumn
                binding="ShoKei"
                header="小計"
                format="c0"
                width={'1*'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="ZeiKei"
                header="消費税"
                format="c0"
                width={'1*'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="OrderKei"
                header="注文金額"
                format="c0"
                width={'1*'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="MitsuKei"
                header="見積合計"
                format="c0"
                width={'1*'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="ArariKei"
                header="粗利益額"
                format="c0"
                width={'1*'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="RiekiRitsu"
                header="粗利率"
                format="n2"
                width={'1*'}
              ></FlexGridColumn>
            </FlexGrid>
          </div>
          <Button className="btn-fill footer-button" variant="primary" onClick={handleOnClickFix}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default OrderDetailsEditModal;
