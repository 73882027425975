
/**
 * 必須チェック処理
 * @param {*} val 値
 * @param {*} name 名称
 * @param {*} id ID
 * @param {*} errors エラーリスト
 */
export function checkRequired(val, name, id, errors) {
    if (!val.trim()) {
        errors.push({ msg: `・${name}を入力してください。`, fields: [id] })
    }
}