import ApprovalList from 'views/Approval/ApprovalList';
import Dashboard from 'views/Dashboard/Dashboard';
import ClientManage from 'views/ClientManage';
import ClientEdit from 'views/ClientEdit';
import ClientInfo from 'views/ClientInfo/ClientInfo';
import InvoiceApproval from 'views/Invoice/InvoiceApproval';
import CustomerQuoteEntry from 'views/CustomerQuoteEntry/CustomerQuoteEntry';
import OrderEntry from 'views/Order/OrderEntry';
import MasterManagement from 'views/MasterManagement/MasterManagement';
import Accounting from 'views/Accounting/Accounting';
import Payment from 'views/Payment/Payment';
import QuoteBaseEntry from 'views/MasterManagement/QuoteBaseEntry';
import CorporatePlanning from 'views/CorporatePlanning/CorporatePlanning';
import AnalysisReport from 'views/AnalysisReport/AnalysisReport';
import TriManage from 'views/MasterManagement/TriManage';
import TriEdit from 'views/MasterManagement/TriEdit';

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'ダッシュボード',
    icon: 'nc-icon nc-chart-pie-35',
    component: Dashboard,
    layout: '/admin',
    auth: ['planner', 'sales', 'admin'], // プランナー、営業事務、管理者
  },
  {
    path: '/corporatePlanning',
    name: '経営企画',
    icon: 'nc-icon nc-chart',
    component: CorporatePlanning,
    layout: '/admin',
    auth: ['planner', 'sales', 'strategic', 'accounting'], // プランナー、営業事務、経営企画、総務
  },
  {
    path: '/clientmanage',
    name: '顧客管理',
    icon: 'nc-icon nc-single-02',
    component: ClientManage,
    layout: '/admin',
    auth: ['planner', 'sales', 'strategic'], // プランナー、営業事務、経営企画
  },
  {
    path: '/approval',
    name: '申請／承認',
    icon: 'nc-icon nc-align-left-2',
    component: ApprovalList,
    layout: '/admin',
    auth: ['planner', 'sales', 'strategic'], // プランナー、営業事務、経営企画
  },
  {
    path: '/clientedit',
    name: '顧客編集',
    icon: '',
    component: ClientEdit,
    layout: '/admin',
  },
  {
    path: '/accounting',
    name: '入金確認',
    icon: 'nc-icon nc-notes',
    component: Accounting,
    layout: '/admin',
  },
  {
    path: '/payment',
    name: '原価入力',
    icon: 'nc-icon nc-money-coins',
    component: Payment,
    layout: '/admin',
    auth: ['planner', 'sales', 'strategic'], // プランナー、営業事務、経営企画
  },
  {
    path: '/report',
    name: '分析帳票',
    icon: 'nc-icon nc-chart-pie-36',
    component: AnalysisReport,
    layout: '/admin',
    auth: ['strategic'], // 経営企画
  },
  {
    path: '/master_manage',
    name: 'マスタ修正',
    icon: 'nc-icon nc-ruler-pencil',
    component: MasterManagement,
    layout: '/admin',
    auth: ['admin'], // 管理者
  },
  {
    path: '/ClientInfo',
    name: '顧客情報',
    component: ClientInfo,
    layout: '/admin',
  },
  {
    path: '/CustomerQuoteEntry',
    name: '顧客見積入力',
    component: CustomerQuoteEntry,
    layout: '/admin',
  },
  {
    path: '/orderentry',
    name: '注文入力',
    component: OrderEntry,
    layout: '/admin',
  },
  {
    path: '/addinvoice',
    name: '追加請求',
    component: CustomerQuoteEntry,
    layout: '/admin',
  },
  {
    path: '/InvoiceApproval',
    name: '請求承認',
    component: InvoiceApproval,
    layout: '/admin',
  },
  {
    path: '/QuoteBaseEntry',
    name: '見積基盤マスタ',
    component: QuoteBaseEntry,
    layout: '/admin',
  },
  {
    path: '/trimanage',
    name: '取引先マスタ',
    component: TriManage,
    layout: '/admin',
  },
  {
    path: '/triedit',
    name: '取引先編集',
    component: TriEdit,
    layout: '/admin',
  },
];

export default dashboardRoutes;
