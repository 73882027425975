import { useState, useEffect } from 'react'
import { Card, Col, Row, Form, Button } from "react-bootstrap"

import TextComponent from 'components/Input/TextComponent';
import { useRestApi } from 'context/RestApiContext';
import { MessageModal } from "components/modal/MessageModal"
import { getNendoStartMonth, getNendoEndMonth } from "utils/dateUtils"
import { checkRequired } from "utils/validationUtils"

export default function AnalysisReport() {

    const restApi = useRestApi()

    // メッセージモーダル
    const [dialog, setDialog] = useState({})

    // エラーフィールド
    const [errorFileds, setErrorFileds] = useState([])

    // 開始年月
    const [startMonth, setStartMonth] = useState(getNendoStartMonth())
    // 終了年月
    const [endMonth, setEndMonth] = useState(getNendoEndMonth())

    // 選択帳票
    const [selectedReport, setSelectedReport] = useState(["sales", "genka", "saleForecasteds", "construction" /** "contractDetail" 契約明細は多いので初期値は未選択 */])


    // 入力チェック
    const validate = () => {
        let errors = []
        checkRequired(startMonth, "開始年月", "startMonth", errors)
        checkRequired(endMonth, "終了年月", "endMonth", errors)

        setErrorFileds(errors.flatMap(x => (x.fields)))

        return errors.map(x => (x.msg))
    }

    // 帳票チェックボックスの選択処理
    const onHandleReportCheck = (report) => {
        if (selectedReport.some(x => report == x)) {
            setSelectedReport(selectedReport.filter(x => report != x))
        }
        else {
            setSelectedReport([...selectedReport, report])
        }
    }

    // ダウンロード
    const handleOnClickDownload = () => {
        var error = validate()
        if (error.length > 0) {
            setDialog({
                type: "error",
                message: error,
            })
            return;
        }

        restApi.postDownload(`/api/analysis_report`, {
            startMonth: startMonth,
            endMonth: endMonth,
            sales: selectedReport.some(x => x == "sales"), // 売上一覧
            genka: selectedReport.some(x => x == "genka"), // 原価一覧
            saleForecasteds: selectedReport.some(x => x == "saleForecasteds"), // 売上見込一覧
            construction: selectedReport.some(x => x == "construction"), // 登録工事一覧
            contractDetail: selectedReport.some(x => x == "contractDetail"), // 契約明細一覧
        }, "分析帳票.xlsx", () => {

        })
    }



    return (
        <>
            <Card className="m-0">
                <Card.Body>
                    <Row className='pt-4'>
                        <Col className="pr-1">
                            ・出力範囲と帳票を選択してダウンロードボタンを押下してください。
                        </Col>
                    </Row>
                    <Row className='pt-2'>
                        <Col className="pr-1" md="2">
                            <Form.Group>
                                <label>開始年月</label>
                                <TextComponent
                                    value={startMonth}
                                    onChange={(target) => {
                                        setStartMonth(target.value)
                                        setErrorFileds([...errorFileds].filter(x => x != "startMonth"))
                                    }}
                                    error={errorFileds.some(x => x == 'startMonth')}
                                    type="date"
                                    selectionMode="Month"
                                />
                            </Form.Group>
                        </Col>
                        <Col className="pr-1" md="2">
                            <Form.Group>
                                <label>終了年月</label>
                                <TextComponent
                                    value={endMonth}
                                    onChange={(target) => {
                                        setEndMonth(target.value)
                                        setErrorFileds([...errorFileds].filter(x => x != "endMonth"))
                                    }}
                                    error={errorFileds.some(x => x == 'endMonth')}
                                    type="date"
                                    selectionMode="Month"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <label className='mr-2' style={{ fontSize: "14pt", color: "black" }}>
                                <input className='mr-2' style={{ transform: "scale(1.5)" }} type="checkbox"
                                    checked={selectedReport.some(x => x == "sales")} onChange={() => onHandleReportCheck("sales")}></input>
                                &nbsp;売上一覧表
                            </label>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            <label className='mr-2' style={{ fontSize: "14pt", color: "black" }}>
                                <input className='mr-2' style={{ transform: "scale(1.5)" }} type="checkbox"
                                    checked={selectedReport.some(x => x == "genka")} onChange={() => onHandleReportCheck("genka")}></input>
                                &nbsp;原価一覧
                            </label>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            <label className='mr-2' style={{ fontSize: "14pt", color: "black" }}>
                                <input className='mr-2' style={{ transform: "scale(1.5)" }} type="checkbox"
                                    checked={selectedReport.some(x => x == "saleForecasteds")} onChange={() => onHandleReportCheck("saleForecasteds")}></input>
                                &nbsp;売上見込み表
                            </label>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            <label className='mr-2' style={{ fontSize: "14pt", color: "black" }}>
                                <input className='mr-2' style={{ transform: "scale(1.5)" }} type="checkbox"
                                    checked={selectedReport.some(x => x == "construction")} onChange={() => onHandleReportCheck("construction")}></input>
                                &nbsp;工事登録一覧
                            </label>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            <label className='mr-2' style={{ fontSize: "14pt", color: "black" }}>
                                <input className='mr-2' style={{ transform: "scale(1.5)" }} type="checkbox"
                                    checked={selectedReport.some(x => x == "contractDetail")} onChange={() => onHandleReportCheck("contractDetail")}></input>
                                &nbsp;契約明細一覧
                            </label>
                        </Col>
                    </Row>
                    <Row className="pt-0 d-flex align-items-end">
                        <Col className='text-right'>
                            <Button variant='primary' className="btn-fill" onClick={handleOnClickDownload} disabled={selectedReport.length == 0}>ダウンロード</Button>
                        </Col>
                    </Row>
                </Card.Body >
            </Card >
            { /** メッセージモーダル */}
            <MessageModal dialog={dialog} />
        </>
    )
}