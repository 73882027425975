import { useState, useEffect } from 'react';

import { Button, Card, Form, Row, Col } from "react-bootstrap";
import TextComponent from 'components/Input/TextComponent';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

/** 入金コメントモーダル */
function NyukinCommentModal(props) {

    // モーダルの表示
    const [show, setShow] = useState(false)

    // client
    const [client, setClient] = useState({})

    // モーダルパラメタの変更処理
    useEffect(() => {
        if (props?.params?.client) {
            setClient({ ...props?.params?.client })
            setShow(true)
        }
    }, [props.params]);

    // 閉じるボタン
    const handleClose = () => {
        setShow(false)
    }

    return (
        <>
            <Dialog
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "680px",
                        },
                    },
                }}
                open={show}
            >
                <DialogTitle className="p-3">入金コメント</DialogTitle>
                <DialogContent className="px-0 py-0 pr-0 pl-0">
                    <Card className="m-0">
                        <Card.Body>
                            <Row>
                                <Col className="pl-2">
                                    <TextComponent className="form-control"
                                        type="textarea"
                                        rows="6"
                                        readOnly={true}
                                        value={client?.NYUKIN_COMMENT}
                                    ></TextComponent>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </DialogContent>
                <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
                    <Button variant="secondary" className="footer-button" onClick={handleClose}>閉じる</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default NyukinCommentModal;
