import { useState, useContext, useEffect, useRef, useCallback } from 'react';

import { Button, Card, Form, Row, Col } from 'react-bootstrap';
import { format } from 'date-fns';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useRestApi } from 'context/RestApiContext';
import { useMaster } from 'context/MasterContext';

import { Chip, Slider } from '@mui/material';
import { ProcessLableComponent, getProcess, PROCESS } from 'components/Input/ProcessLableComponent';
import TextComponent from 'components/Input/TextComponent';

import { isPastYearMonth } from 'utils/dateUtils';

function ClientCardEditModal(props) {
  // マスタ情報
  const master = useMaster();

  const restApi = useRestApi();

  // 顧客情報（修正前の情報）
  const [orgClient, setOrgClient] = useState({});
  // 顧客情報
  const [client, setClient] = useState({});

  // モーダルの表示
  const [show, setShow] = useState(false);

  // ラベル
  const [labels, setLabels] = useState([]);

  // モーダルパラメタの変更処理
  useEffect(() => {
    if (props?.params?.client) {
      restApi.get(`/api/construction/${props?.params?.client.SEIKYU_NO}`, (data) => {
        // 顧客情報
        let _client = {
          ...props?.params?.client,
          KOUJI_MEI: data.construction.KOUJI_MEI,
          PROCESS_CD: data.construction.PROCESS_CD,
          SALES_TAN_CD: data.construction.SALES_TAN_CD,
          DASHBOARD_DISP_DATE: data.construction.DASHBOARD_DISP_DATE,
          LOST_PENDING_DATE: data.construction.LOST_PENDING_DATE,
          EXPECTED_SALES_MONTH: data.construction.EXPECTED_SALES_MONTH,
          ORDER_LEVEL: data.construction.ORDER_LEVEL,
          JIGYO_CD: data.construction.JIGYO_CD,
        };
        setClient(_client);
        setOrgClient({ ..._client });
        setLabels(data.construction.LABELS ? data.construction.LABELS.split(',') : []);
        setShow(true);
      });
    }
  }, [props.params]);

  // 工程の選択処理
  const handleOnClickProcess = (process) => {
    setClient({ ...client, PROCESS_CD: process });
  };

  // ラベルの選択処理
  const handleOnClickLabel = (lbl) => {
    if (labels.some((l) => l == lbl)) {
      setLabels(labels.filter((x) => x != lbl));
    } else {
      setLabels([...labels, lbl]);
    }
  };

  // 編集処理
  const handleChange = (prop) => (target) => {
    setClient({ ...client, [prop]: target?.value ?? target?.target.value });
  };

  // 閉じるボタン
  const handleClose = () => {
    setShow(false);
  };

  // 確定ボタンが無効かどうか？
  const isDisabledFix = () => {
    // 受注確度が80%以上の場合に売上見込月が未入力
    if (!client.EXPECTED_SALES_MONTH && client.ORDER_LEVEL >= 80) {
      return true;
    }
    // 失注または、保留で失注/保留日付が未入力
    if (
      [PROCESS.ON_HOLD, PROCESS.LOST_ORDER].includes(client.PROCESS_CD) &&
      !client.LOST_PENDING_DATE
    ) {
      return true;
    }

    return false;
  };

  // 確定ボタン
  const handleFix = () => {
    let today = new Date();
    let dispDate = master.process.find((p) => p.PROCESS_CD == client.PROCESS_CD).DISP_DATE;
    let isChangeProcessCd = orgClient.PROCESS_CD != client.PROCESS_CD; // 工程に変更があったか？
    let dashboardDate = !isChangeProcessCd
      ? orgClient.DASHBOARD_DISP_DATE // 工程が変更なしの場合はそのまま
      : dispDate == 0
      ? '9999-12-31' // 表示期限が無期限の場合
      : format(today.setDate(today.getDate() + dispDate), 'yyyy-MM-dd');

    const params = {
      SEIKYU_NO: client.SEIKYU_NO,
      LABELS: labels,
      PROCESS_CD: client.PROCESS_CD,
      DASHBOARD_DISP_DATE: dashboardDate,
      SALES_TAN_CD: client.SALES_TAN_CD,
      EXPECTED_SALES_MONTH: client.EXPECTED_SALES_MONTH,
      LOST_PENDING_DATE: [PROCESS.ON_HOLD, PROCESS.LOST_ORDER].includes(client.PROCESS_CD)
        ? client.LOST_PENDING_DATE
        : null,
      ORDER_LEVEL: client.ORDER_LEVEL,
      JIGYO_CD: client.JIGYO_CD,
      IS_CHANGE_PROCESS: isChangeProcessCd,
    };

    restApi.post('/api/dashboard/label', params, (_) => {
      props.params.callback({
        ...client,
        LABELS: labels,
        DASHBOARD_DISP_DATE: dashboardDate,
        SORT: isChangeProcessCd ? 0 : orgClient.SORT,
      });
      setShow(false);
    });
  };

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '680px', // Set your width here
            },
          },
        }}
        open={show}
      >
        <DialogTitle className="p-3">
          {client.NAME_SEI_1 && `${client.NAME_SEI_1}　${client.NAME_MEI_1}`}
          {client.ATESAKI && `${client.ATESAKI}`}
        </DialogTitle>
        <DialogContent className="px-0 py-0 pr-0 pl-0">
          <Card className="m-0">
            <Card.Body>
              <Row>
                <Col className="pl-2" md="12">
                  <Form.Group>
                    <label>工事名</label>
                    <h4 className="m-1">{client.KOUJI_MEI}</h4>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="pl-2 pt-2">
                <label>売上予定月</label>
              </Row>
              <Row>
                <Col md="3">
                  <TextComponent
                    value={client.EXPECTED_SALES_MONTH}
                    onChange={handleChange('EXPECTED_SALES_MONTH')}
                    type="date"
                    selectionMode="Month"
                  />
                </Col>
                <Col className="m-0 p-0 d-flex align-items-end">
                  <div style={{ color: 'red' }}>
                    {!client.EXPECTED_SALES_MONTH && client.ORDER_LEVEL >= 80
                      ? '※受注確度が80%以上は売上予定月を入力してください。'
                      : ''}
                  </div>
                  <div style={{ color: 'orange' }}>
                    {client.EXPECTED_SALES_MONTH && isPastYearMonth(client.EXPECTED_SALES_MONTH)
                      ? '売上年月に過去の年月が設定されています!'
                      : ''}
                  </div>
                </Col>
              </Row>
              <Row className="pl-2 pt-2">
                <label>受注確度</label>
              </Row>
              <Row>
                <Col md="10" className="pl-4">
                  <Slider
                    defaultValue={client.ORDER_LEVEL}
                    valueLabelFormat={(v) => `${v}%`}
                    aria-labelledby="discrete-slider-always"
                    valueLabelDisplay="auto"
                    step={10}
                    min={10}
                    onChange={handleChange('ORDER_LEVEL')}
                    marks={[
                      { value: 10, label: '10%' },
                      { value: 50, label: '50%' },
                      { value: 80, label: '80%' },
                      { value: 100, label: '100%' },
                    ]}
                  />
                </Col>
                <Col style={{ color: '#1976d2' }}>
                  <h4 className="m-0 p-0">{client.ORDER_LEVEL}%</h4>
                </Col>
              </Row>
              <Row>
                <Col className="pl-2" md="5">
                  <Form.Group>
                    <label>工程</label>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="pt-1">
                {getProcess(
                  master,
                  orgClient.PROCESS_CD,
                  orgClient.NYUKIN_STATUS,
                  orgClient.KEI_NO
                ).map((p) => (
                  <ProcessLableComponent
                    key={p.PROCESS_CD}
                    process={p}
                    width="112px"
                    isDisabled={p.PROCESS_CD != client.PROCESS_CD}
                    onClick={() => handleOnClickProcess(p.PROCESS_CD)}
                  />
                ))}
              </Row>
              {/** 保留または失注の場合に保留/失注日付を表示 */}
              {[PROCESS.LOST_ORDER, PROCESS.ON_HOLD].includes(client.PROCESS_CD) && (
                <>
                  <Row>
                    <Col className="pl-2" md="5">
                      <Form.Group>
                        <label>
                          {client.PROCESS_CD == PROCESS.LOST_ORDER ? '失注日付' : '保留日付'}
                        </label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="pt-1">
                    <Col className="pl-2" md="4">
                      <TextComponent
                        type="date"
                        value={client.LOST_PENDING_DATE}
                        onChange={handleChange('LOST_PENDING_DATE')}
                      />
                    </Col>
                    <Col className="m-0 p-0 d-flex align-items-end">
                      <div style={{ color: 'red' }}>
                        {!client.LOST_PENDING_DATE
                          ? `※${
                              client.PROCESS_CD == PROCESS.LOST_ORDER ? '失注日付' : '保留日付'
                            }入力してください。`
                          : ''}
                      </div>
                    </Col>
                  </Row>
                </>
              )}

              <Row className="pl-2 pt-2">
                <label>ラベル</label>
              </Row>
              <Row className="pt-1">
                {master.label.map((ml) => (
                  <Chip
                    key={ml.LABEL_CD}
                    className="m-1"
                    style={{
                      width: '112px',
                      color: labels.some((l) => l == ml.LABEL_CD) ? ml.FONT_COLOR : '#172b4d',
                      backgroundColor: labels.some((l) => l == ml.LABEL_CD) ? ml.COLOR : '#e4e4e4',
                    }}
                    label={ml.DISPLAY}
                    onClick={() => handleOnClickLabel(ml.LABEL_CD)}
                  />
                ))}
              </Row>

              <Row className="pl-2 pt-2">
                <Col md="6">
                  <label>補助担当</label>
                </Col>
                <Col md="6">
                  <label>事業部</label>
                </Col>
              </Row>
              <Row className="pt-1">
                <Col md="6">
                  <Form.Select
                    className="form-control"
                    value={client?.SALES_TAN_CD}
                    onChange={handleChange('SALES_TAN_CD')}
                  >
                    <option value={''}>　</option>
                    {master.staff
                      .filter((x) => x.SALES_KBN == '1')
                      .map((x, i) => (
                        <option key={i} value={x['id']}>
                          {x['name']}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
                <Col md="6">
                  <Form.Select
                    className="form-control"
                    value={client?.JIGYO_CD}
                    onChange={handleChange('JIGYO_CD')}
                  >
                    <option value={'0'}>　</option>
                    {master.jigyo.map((x, i) => (
                      <option key={i} value={x['JIGYO_CD']}>
                        {x['JIGYO_NAME']}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </DialogContent>
        <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
          <Button variant="secondary" className="footer-button" onClick={handleClose}>
            閉じる
          </Button>
          <Button
            variant="primary btn-fill"
            className="footer-button"
            onClick={handleFix}
            disabled={isDisabledFix()}
          >
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ClientCardEditModal;
