import { useState, useEffect, useCallback } from 'react';
import { Button, Row } from 'react-bootstrap'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import "assets/css/modal.css"

/** メッセージモーダル */
export const MessageModal = (props) => {
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState([]);

    const dialog = props.dialog;
    useEffect(() => {
        if (dialog.message) {
            setShow(true)
            setMessage(Array.isArray(dialog.message) ? dialog.message : [dialog.message])
        }
    }, [dialog]);

    // OKボタン
    const handleModalOk = useCallback(() => {
        if (dialog.okCallBack) {
            dialog.okCallBack();
        }
        dialog.message = undefined
        setShow(false);
    }, [dialog]);

    // キャンセルボタン
    const handleModalCancel = useCallback(() => {
        dialog.message = undefined
        setShow(false);
    }, [dialog]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={show}
        >
            {
                dialog.type == "error" ?
                    <>
                        <DialogTitle className="error-modal-header p-2">エラー</DialogTitle>
                        <DialogContent className="p-2">
                            {message.map((m, i) => (<p key={i} className='p-0 m-1'>{m}</p>))}
                        </DialogContent>
                        <DialogActions className="p-3" >
                            <Button variant="secondary" className="footer-button" onClick={handleModalOk}>
                                閉じる
                            </Button>
                        </DialogActions>
                    </>
                    :
                    dialog.type == "info" ?
                        <>
                            <DialogTitle className="info-modal-header p-2">情報</DialogTitle>
                            <DialogContent className="p-2">
                                {message.map((m, i) => (<p key={i} className='p-0 m-1'>{m}</p>))}
                            </DialogContent>
                            <DialogActions className="p-3" >
                                <Button variant="secondary" className="footer-button" onClick={handleModalOk}>
                                    閉じる
                                </Button>
                            </DialogActions>
                        </>
                        :
                        <>
                            <DialogTitle className="confirm-modal-header p-2">確認</DialogTitle>
                            <DialogContent className="p-2">
                                {message.map((m, i) => (<p key={i} className='p-0 m-1'>{m}</p>))}
                            </DialogContent>
                            <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
                                <Button variant="secondary" className="footer-button" onClick={handleModalCancel}>
                                    キャンセル
                                </Button>
                                <Button variant="primary" className="btn-fill footer-button" onClick={handleModalOk}>
                                    OK
                                </Button>
                            </DialogActions>
                        </>
            }

        </Dialog >
    )
}