import React, { useContext, createContext, useRef } from 'react';
import { useCookies } from 'react-cookie';

const AuthContext = createContext();

/**
 * 認証情報プロバイダ
 * @param {*} 子要素
 * @returns 認証情報プロバイダ
 */
export function AuthContextProvider({ children }) {
  const [cookies, setCookie, removeCookie] = useCookies(['ate-sekisan-auth']);
  const userInfo = JSON.parse(localStorage.getItem('ate-sekisan-user'));

  // クッキーから認証情報を取得
  const _auth = cookies['ate-sekisan-auth']
    ? {
        isAuthed: true,
        access_token: cookies['ate-sekisan-auth'],
        user_id: userInfo ? userInfo['user_id'] : '',
        user_name: userInfo ? userInfo['user_name'] : '',
        section: userInfo ? userInfo['section'] : '',
        kbn: userInfo ? userInfo['kbn'] : [],
      }
    : {
        isAuthed: false,
        access_token: '',
        user_id: '',
        user_name: '',
        section: '',
        kbn: [],
      };

  // 認証情報を設定
  const auth = useRef({}).current;
  const setAuth = (a) => {
    auth.isAuthed = a.isAuthed;
    auth.access_token = a.access_token;
    auth.user_id = a.user_id;
    auth.user_name = a.user_name;
    auth.section = a.section;
    auth.kbn = a.kbn;

    // 認証の場合はクッキーに保持、ログアウトの場合はクッキーを削除
    if (a.isAuthed) {
      setCookie('ate-sekisan-auth', a.access_token, { path: '/' });
      localStorage.setItem(
        'ate-sekisan-user',
        JSON.stringify({
          user_id: a.user_id,
          user_name: a.user_name,
          section: a.section,
          kbn: a.kbn,
        })
      );
    } else {
      removeCookie('ate-sekisan-auth', { path: '/' });
      localStorage.removeItem('ate-sekisan-user');
    }
  };

  // 初期ページを取得
  const getDefaultPage = () => {
    // プランナー／営業事務の場合はダッシュボード
    if (auth.kbn?.some((k) => ['planner', 'sales'].includes(k))) {
      return '/admin/dashboard';
    }

    // 経営企画の場合
    if (auth.kbn?.some((k) => ['strategic'].includes(k))) {
      return '/admin/corporatePlanning';
    }

    // 経理の場合
    if (auth.kbn?.some((k) => ['accounting'].includes(k))) {
      return '/admin/accounting';
    }

    return '/admin/clientmanage';
  };

  return (
    <AuthContext.Provider value={{ auth, setAuth, getDefaultPage }}>
      {children}
    </AuthContext.Provider>
  );
}
/**
 * 認証情報を取得
 * @returns 認証情報
 */
export function useAuth() {
  const context = useContext(AuthContext);
  if (context == undefined) {
    throw new Error('認証情報の取得に失敗しました。');
  }
  return context;
}
