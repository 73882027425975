import { useState, useContext, useEffect, useRef, useCallback } from 'react';

import { Button, Card, Form, Row, Col } from 'react-bootstrap';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useRestApi } from 'context/RestApiContext';

import { MessageModal } from 'components/modal/MessageModal';
import TextComponent from 'components/Input/TextComponent';

function InvoiceEntryModal(props) {
  const restApi = useRestApi();

  // モード情報
  const MODE_LIST = [
    { id: 'add', caption: '登録', variant: 'primary', readOnly: false },
    { id: 'upd', caption: '更新', variant: 'primary', readOnly: false },
    { id: 'del', caption: '削除', variant: 'danger', readOnly: true },
  ];

  // 確認ダイアログ
  const [dialog, setDialog] = useState({});

  // 画面モード
  const [mode, setMode] = useState({});

  // 修正前の請求情報
  const [originInvoice, setOriginInvoice] = useState({});

  // 請求情報
  const [invoice, setInvoice] = useState({});

  // 請求氏名ラジオの選択
  const [seikyuKbn, setSeikyuKbn] = useState('client');

  // 取引先情報
  const [tori, setTori] = useState({});

  // モーダルの表示
  const [show, setShow] = useState(false);

  // モーダルパラメタの変更処理
  useEffect(() => {
    if (props?.params?.seikyuNo) {
      // モードを設定
      setMode(MODE_LIST.find((x) => x.id == props.params.mode));

      // 請求情報を取得
      restApi.get(`/api/invoice/${props?.params?.seikyuNo}`, (data) => {
        let _invoice = { ...data.invoice };

        // 追加モードの場合
        if (props.params.mode == 'add') {
          _invoice.SEIKYU_POST = _invoice.C_POST;
          _invoice.SEIKYU_ADD_1 = _invoice.C_PREF + _invoice.C_CITY + _invoice.C_TOWN;
          _invoice.SEIKYU_ADD_2 = _invoice.C_ADDRESS;
          _invoice.SEIKYU_CLIENT_NAME = _invoice.CLIENT_NAME;
          _invoice.SEIKYU_TRI_NAME = data.tori?.RYAKU;
          _invoice.SEIKYU_TRI_CD = data.tori?.TRI_CD;
          setSeikyuKbn('client');
        }
        // 追加モードでない場合
        else {
          // 施主の場合は取引先コードは顧客情報の取引先を設定
          if (_invoice.SEIKYU_KBN == 'client') {
            _invoice.SEIKYU_TRI_NAME = data.tori?.TRI_NAME;
            _invoice.SEIKYU_TRI_CD = data.tori?.TRI_CD;
          }
          // 変更前の請求情報を保持
          setOriginInvoice(data.invoice);
          // 請求先名の区分
          setSeikyuKbn(_invoice.SEIKYU_KBN);
        }
        setTori(data.tori);
        setInvoice(_invoice);
        setShow(true);
      });
    }
  }, [props.params]);

  // 編集処理
  const handleOnChange = (prop) => (target) => {
    setInvoice({ ...invoice, [prop]: target.value });
  };

  // 請求名ラジオの変更処理
  const handleOnChangeSeikyuName = (event) => {
    let _seikyuKbn = event.target.value;

    // 請求先が施主、かつ、変更前の請求情報が施主でない場合
    // 顧客情報の住所を設定
    if (_seikyuKbn == 'client' && originInvoice.SEIKYU_KBN != _seikyuKbn) {
      setInvoice({
        ...invoice,
        SEIKYU_POST: invoice.C_POST,
        SEIKYU_ADD_1: invoice.C_PREF + invoice.C_CITY + invoice.C_TOWN,
        SEIKYU_ADD_2: invoice.C_ADDRESS,
      });
    }
    // 請求先が受注元、かつ、変更前の請求情報が受注元でない場合
    // 顧客情報の住所を設定
    else if (_seikyuKbn == 'tori' && originInvoice.SEIKYU_KBN != _seikyuKbn) {
      setInvoice({
        ...invoice,
        SEIKYU_POST: tori?.POST_CODE,
        SEIKYU_ADD_1: tori?.ADD1,
        SEIKYU_ADD_2: tori?.ADD2,
      });
    }
    // 上記以外は変更前の請求情報の住所を設定
    else {
      setInvoice({
        ...invoice,
        SEIKYU_POST: originInvoice?.SEIKYU_POST,
        SEIKYU_ADD_1: originInvoice?.SEIKYU_ADD_1,
        SEIKYU_ADD_2: originInvoice?.SEIKYU_ADD_2,
      });
    }

    // 請求名を変更
    setSeikyuKbn(_seikyuKbn);
  };

  // 登録ボタン押下処理
  const handleOnClickCreate = () => {
    setDialog({
      type: 'confirm',
      message: `${mode.caption}します。よろしいですか？`,
      okCallBack: () => {
        let param = {
          CLIENT_CD: invoice.CLIENT_CD,
          SEIKYU_NO: props.params.seikyuNo,
          SEIKYU_CLIENT_NAME: invoice.SEIKYU_CLIENT_NAME,
          SEIKYU_TRI_CD: invoice.SEIKYU_TRI_CD ?? '',
          SEIKYU_TRI_NAME: invoice.SEIKYU_TRI_NAME ?? '',
          SEIKYU_DATE: invoice.SEIKYU_DATE,
          SEIKYU_POST: invoice.SEIKYU_POST,
          SEIKYU_ADD_1: invoice.SEIKYU_ADD_1,
          SEIKYU_ADD_2: invoice.SEIKYU_ADD_2,
          SEIKYU_KBN: seikyuKbn,
        };

        // 登録(請求書の印刷もおこなう)
        if (mode?.id == 'add') {
          restApi.postToPdf('/api/invoice/create', param, () => {
            props.callbak();
            setShow(false);
          });
        }
        // 更新
        else {
          restApi.post(`/api/invoice/${mode.id}`, param, () => {
            props.callbak();
            setShow(false);
          });
        }
      },
    });
  };

  // 閉じるボタン
  const handleClose = useCallback(() => {
    setShow(false);
  }, [props.params]);

  return (
    <>
      <Dialog fullWidth={true} maxWidth={'lg'} open={show}>
        <DialogTitle className="p-3">請求書</DialogTitle>
        <DialogContent className="px-0 py-0 pr-0 pl-0">
          <Card className="m-0">
            <Card.Body>
              {invoice?.MAE_NYUKIN != invoice?.MAE_KIN && (
                <Row>
                  <Col style={{ color: 'red' }}>
                    契約時の着工金額と着工金支払額が一致しません。確認のうえ請求処理をおこなってください。
                  </Col>
                </Row>
              )}
              <Row>
                <Col className="pl-3" md="5">
                  <Form.Group>
                    <label>請求日</label>
                    <br />
                    <TextComponent
                      type="date"
                      value={invoice?.SEIKYU_DATE}
                      disabled={mode?.readOnly}
                      onChange={handleOnChange('SEIKYU_DATE')}
                    ></TextComponent>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="pl-3 pt-3 d-flex flex-nowrap" md="5">
                  <label>請求先名</label>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-start align-items-center">
                  <div style={{ width: '80px' }}>
                    <label>
                      <input
                        type="radio"
                        className="mr-2"
                        name="radioSeikyu"
                        value="client"
                        disabled={mode?.readOnly}
                        checked={seikyuKbn == 'client'}
                        onChange={handleOnChangeSeikyuName}
                      />
                      施主
                    </label>
                  </div>
                  <div style={{ width: '300px' }}>
                    <TextComponent
                      className="pl-2"
                      type="text"
                      value={invoice?.SEIKYU_CLIENT_NAME}
                      disabled={mode?.readOnly || seikyuKbn == 'tori'}
                      onChange={handleOnChange('SEIKYU_CLIENT_NAME')}
                    ></TextComponent>
                  </div>
                  <div className="pl-2">様</div>
                </Col>
              </Row>
              <Row className="pt-3">
                <Col className="d-flex justify-content-start align-items-center">
                  <div style={{ width: '80px' }}>
                    <label>
                      <input
                        type="radio"
                        className="mr-2"
                        name="radioSeikyu"
                        value="tori"
                        disabled={mode?.readOnly || (!tori?.TRI_CD && !originInvoice.SEIKYU_TRI_CD)}
                        checked={seikyuKbn == 'tori'}
                        onChange={handleOnChangeSeikyuName}
                      />
                      受注元
                    </label>
                  </div>
                  <div style={{ width: '500px' }}>
                    <TextComponent
                      className="pl-2"
                      type="text"
                      value={invoice?.SEIKYU_TRI_NAME}
                      disabled={
                        mode?.readOnly ||
                        seikyuKbn == 'client' ||
                        (!tori?.TRI_CD && !originInvoice.SEIKYU_TRI_CD)
                      }
                      onChange={handleOnChange('SEIKYU_TRI_NAME')}
                    ></TextComponent>
                  </div>
                  <div className="pl-2">御中</div>
                </Col>
              </Row>
              <Row>
                <Col className="pl-3 pt-3" md="3">
                  <Form.Group>
                    <label>請求書住所</label>
                    <TextComponent
                      type="tel"
                      value={invoice?.SEIKYU_POST}
                      disabled={mode?.readOnly}
                      onChange={handleOnChange('SEIKYU_POST')}
                    ></TextComponent>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="pl-3" md="10">
                  <TextComponent
                    type="text"
                    value={invoice?.SEIKYU_ADD_1}
                    disabled={mode?.readOnly}
                    onChange={handleOnChange('SEIKYU_ADD_1')}
                  ></TextComponent>
                </Col>
              </Row>
              <Row>
                <Col className="pl-3" md="10">
                  <TextComponent
                    type="text"
                    value={invoice?.SEIKYU_ADD_2}
                    disabled={mode?.readOnly}
                    onChange={handleOnChange('SEIKYU_ADD_2')}
                  ></TextComponent>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </DialogContent>
        <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
          <Button variant="secondary" className="footer-button" onClick={handleClose}>
            閉じる
          </Button>
          <Button
            className="btn-fill footer-button"
            variant={mode?.variant}
            disabled={invoice?.SEIKYU_DATE == ''}
            onClick={handleOnClickCreate}
          >
            {mode?.caption}
          </Button>
        </DialogActions>
      </Dialog>
      {/** メッセージモーダル */}
      <MessageModal dialog={dialog}></MessageModal>
    </>
  );
}

export default InvoiceEntryModal;
