import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import TextComponent from 'components/Input/TextComponent';
import { useAuth } from 'context/AuthContext';
import { useRestApi } from 'context/RestApiContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { FlexGridXlsxConverter } from '@grapecity/wijmo.grid.xlsx';

const TriManage = (props) => {
  const auth = useAuth();

  const restApi = useRestApi();

  const location = useLocation();
  // 履歴
  const navigate = useNavigate();

  // グリッド
  const flexGrid = useRef();

  // 取引先一覧情報
  const [tris, setTris] = useState([]);

  // ソートの最大値
  const [maxsort, setMaxsort] = useState([]);

  // 取引先検索条件
  const [searchCondition, setSearchCondition] = useState({
    NAME: '',
  });

  //検索処理
  const SearchTris = (condition) => {
    restApi.post('/api/master/tri', condition, (data) => {
      setTris(
        data.map((x) => ({
          ...x,
          HACYU_DISP: x.HACYU_KBN == 1 ? '✓' : '',
          JUCYU_DISP: x.JUCYU_KBN == 1 ? '✓' : '',
        }))
      );
    });
    //ソートの最大値を数値に変換
    restApi.get('/api/master/maxsort', (maxsort) => {
      setMaxsort(maxsort[0].MAXSORT - 0);
    });
  };

  // 初期表示処理
  useEffect(() => {
    // 別画面から戻ってきた場合は検索条件を復活
    let condition = location?.state?.searchCondition ?? {
      NAME: '',
    };
    setSearchCondition(condition);
    // 検索
    SearchTris(condition);
  }, []);

  // 新規取引先作成ボタン
  const handleOnClickNewTri = () => {
    navigate('/admin/triedit', {
      state: {
        mode: 'add',
        maxsort: maxsort,
        searchCondition: searchCondition,
      },
    });
  };

  //編集ボタン
  const handleOnClickEditTri = () => {
    navigate('/admin/triedit', {
      state: {
        mode: 'upd',
        targetTri: flexGrid.current.control.selectedItems[0],
        searchCondition: searchCondition,
      },
    });
  };

  // 削除ボタン
  const handleOnClickDeleteTri = () => {
    navigate('/admin/triedit', {
      state: {
        mode: 'del',
        targetTri: flexGrid.current.control.selectedItems[0],
        searchCondition: searchCondition,
      },
    });
  };

  // 検索ボタン
  const handleOnClickSearchtri = () => {
    SearchTris(searchCondition);
  };
  //編集処理
  const handleChange = (prop) => (target) => {
    setSearchCondition({ ...searchCondition, [prop]: target?.value ?? target?.target.value });
  };
  // Excelダウンロード
  const handleExcelDownload = () => {
    const grid = flexGrid.current.control; // FlexGridコンポーネントへの参照を取得
    const fileName = '取引先一覧.xlsx'; // ダウンロードするExcelファイルの名前

    // Excelファイルを生成し、ダウンロードする
    FlexGridXlsxConverter.save(
      grid,
      {
        includeColumns: function (column) {
          return column.header; // ヘッダー名称なしは含めない（ボタンの項目）
        },
      },
      fileName
    );
  };

  return (
    <>
      <Card className="m-0 table-container">
        <Card.Body>
          <Row>
            <Col className="pr-1" md="2">
              <Form.Group>
                <label>取引先名</label>
                <TextComponent
                  placeholder="部分一致で検索します"
                  type="text"
                  value={searchCondition.NAME}
                  onChange={handleChange('NAME')}
                ></TextComponent>
              </Form.Group>
            </Col>
            <Col style={{ paddingTop: '30px' }}>
              <Button
                variant="success"
                className="btn-fill"
                size="sm"
                onClick={handleOnClickSearchtri}
              >
                検索
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="success"
                className="btn-fill m-2"
                size="sm"
                onClick={handleOnClickNewTri}
              >
                新規登録
              </Button>
              <Button
                variant="success"
                className="btn-fill m-2"
                size="sm"
                onClick={handleExcelDownload}
              >
                Excelダウンロード
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="table-scroll">
                <FlexGrid
                  ref={flexGrid}
                  isReadOnly={true}
                  itemsSource={tris}
                  selectionMode={'Row'}
                  headersVisibility={'Column'}
                  style={{ height: '100%' }}
                  frozenColumns={2}
                >
                  <FlexGridFilter />
                  <FlexGridColumn
                    binding="HACYU_DISP"
                    align="center"
                    header="発注"
                    width={46}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="JUCYU_DISP"
                    align="center"
                    header="受注"
                    width={46}
                  ></FlexGridColumn>
                  <FlexGridColumn binding="SORT" header="ソート順" width={65}></FlexGridColumn>
                  <FlexGridColumn binding="TRI_NAME" header="取引先名" width={180}></FlexGridColumn>
                  <FlexGridColumn binding="RYAKU" header="略名" width={180}></FlexGridColumn>
                  <FlexGridColumn binding="POST_CODE" header="郵便番号"></FlexGridColumn>
                  <FlexGridColumn binding="ADD1" header="所在地1" width={250}></FlexGridColumn>
                  <FlexGridColumn binding="TEL" header="電話番号" width={110}></FlexGridColumn>
                  <FlexGridColumn binding="FAX" header="FAX" width={110}></FlexGridColumn>
                  <FlexGridColumn binding="TAN_NAME" header="担当者名" width={80}></FlexGridColumn>
                  <FlexGridColumn
                    binding="TAN_MAIL"
                    header="担当者メール"
                    width={210}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="TAN_MOBILE"
                    header="担当者携帯"
                    width={110}
                  ></FlexGridColumn>
                  <FlexGridColumn header="" binding="" isReadOnly={true} width={80}>
                    <FlexGridCellTemplate
                      cellType="Cell"
                      template={(cell) => (
                        <span>
                          <Button
                            className="material-symbols-rounded cell-icon btn-fill"
                            variant="primary"
                            title="編集"
                            onClick={handleOnClickEditTri}
                          >
                            edit
                          </Button>
                          <Button
                            className="material-symbols-rounded cell-icon btn-fill"
                            variant="danger"
                            title="削除"
                            onClick={handleOnClickDeleteTri}
                          >
                            delete
                          </Button>
                        </span>
                      )}
                    />
                  </FlexGridColumn>
                </FlexGrid>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default TriManage;
