import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import TextComponent from 'components/Input/TextComponent';
import { MessageModal } from 'components/modal/MessageModal';
import { useRestApi } from 'context/RestApiContext';
import { format } from 'date-fns';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { checkRequired } from 'utils/validationUtils';
import { toInt } from 'utils/numberUtils';
import { useMaster } from 'context/MasterContext';

const ContractEntry = (props) => {
  // マスタ情報
  const master = useMaster();
  // REST
  const restApi = useRestApi();
  // 確認ダイアログ
  const [dialog, setDialog] = useState({});
  // REF
  const ref = useRef();
  // モード
  const [show, setShow] = useState(false);
  // エラーフィールド
  const [errorFileds, setErrorFileds] = useState([]);
  // 契約情報
  const [contract, setContract] = useState({
    KEI_NO: '',
    QUOTE_NO: '',
    CLIENT_CD: '',
    CLIENT_NAME: '',
    CLIENT_ADD: '',
    KOUJI_MEI: '',
    KOUJI_BASYO: '',
    KEI_DATE: '',
    KOUKI_ST: '',
    KOUKI_ED: '',
    ZEINUKI_KIN: '',
    ZEI: '',
    KEI_KIN: '',
    MAE_KIGEN: '',
    MAE_KIN: 0,
    KAN_KIN: 0,
    SUM_KIN: 0,
    DATE_NOTPRINT: 0,
    MAE_BIKO: '',
    KAN_BIKO: '',
    SUM_BIKO: '',
    INS_TAN_CD: '',
    INS_DATE: '',
    INS_TIME: '',
    UPD_TAN_CD: '',
    UPD_DATE: '',
    UPD_TIME: '',
    DEL_FLG: '',
  });

  // モード
  const [mode, setMode] = useState(props.contractModalParam.mode ?? '');

  // 前受金を計算
  const calcMaeukeKin = (kingaku) => {
    // 設定値以下の割合を使用
    let rate = master.maeukeRates.find((m) => m.kingaku > kingaku).rate;
    return toInt(kingaku * rate);
  };

  // モーダルを表示時の処理
  useEffect(() => {
    if (props.contractModalParam.mode == undefined) {
      setShow(false);
      return;
    }

    setMode(props.contractModalParam.mode);

    // 追加モードの場合
    if (props.contractModalParam.mode == 'add') {
      let now = new Date();
      // 前受け金を計算
      let maeKin = calcMaeukeKin(props.contractModalParam.quote['MITSU_KIN_ZEI']);
      delete contract['KEI_NO'];
      setContract({
        ...contract,
        SEIKYU_NO: props.contractModalParam.quote['SEIKYU_NO'],
        QUOTE_NO: props.contractModalParam.quote['QUOTE_NO'],
        CLIENT_CD: props.contractModalParam.client['CLIENT_CD'],
        CLIENT_NAME: props.contractModalParam.client['CLIENT_NAME'],
        CLIENT_ADD: props.contractModalParam.client['CLIENT_ADD'],
        KOUJI_MEI: props.contractModalParam.quote['KOUJI_MEI'],
        KOUJI_BASYO: props.contractModalParam.quote['KOUJI_BASYO'],
        KEI_DATE: format(now, 'yyyy/MM/dd'),
        ZEINUKI_KIN: props.contractModalParam.quote['MITSU_KIN'],
        ZEI: props.contractModalParam.quote['ZEI'],
        DATE_NOTPRINT: 0,
        MAE_KIGEN: '',
        MAE_KIN: maeKin,
        KAN_KIN: props.contractModalParam.quote['MITSU_KIN_ZEI'] - maeKin,
        SUM_KIN: props.contractModalParam.quote['MITSU_KIN_ZEI'],
      });
    } else {
      setContract({ ...props.contractModalParam.contract });
    }
    setErrorFileds([]);
    setShow(true);
  }, [props.contractModalParam]);

  // 編集処理
  const handleChange = (prop) => (target) => {
    if (prop == 'MAE_KIN') {
      setContract({
        ...contract,
        MAE_KIN: parseInt(target.value),
        KAN_KIN: contract['SUM_KIN'] - target.value,
      });
    } else {
      setContract({ ...contract, [prop]: target.value });
    }
    // エラーをクリア
    setErrorFileds([...errorFileds].filter((x) => x != prop));
  };

  // 契約日を印字しないチェックボックスの変更処理
  const setDatePrint = (datePrint) => {
    setContract({ ...contract, DATE_NOTPRINT: datePrint == 0 ? 1 : 0 });
  };

  // ボタンのアクション
  const action = {
    '': { caption: '', cmd: '', variant: '' },
    add: { caption: '登録', cmd: 'add', variant: 'primary' }, // 新規登録
    upd: { caption: '更新', cmd: 'upd', variant: 'primary' }, // 編集
    del: { caption: '削除', cmd: 'del', variant: 'danger' }, // 削除
  };

  // 入力チェック
  const validate = () => {
    let errors = [];
    checkRequired(contract.KEI_DATE, '契約日', 'KEI_DATE', errors);
    setErrorFileds(errors.flatMap((x) => x.fields));

    return errors.map((x) => x.msg);
  };

  // 確定ボタン押下処理
  const handleOnClickSave = () => {
    // 入力チェック
    var error = validate();
    if (error.length > 0) {
      setDialog({
        type: 'error',
        message: error,
      });
      return;
    }

    setDialog({
      type: 'confirm',
      message: `${action[mode]['caption']}します。よろしいですか？`,
      okCallBack: () => {
        updateContract();
      },
    });
  };

  const updateContract = () => {
    let _contract = contract;
    delete _contract['NO'];
    restApi.post(
      `/api/contract/${action[mode]['cmd']}`,
      {
        contract: contract,
      },
      (data) => {
        props.callbakContractModal();
        handleClose();
      }
    );
  };

  // 閉じるボタン処理
  const handleClose = () => {
    props.contractModalParam.mode = undefined;
    setShow(false);
  };

  return (
    <>
      <Dialog ref={ref} maxWidth={'xl'} open={show}>
        <DialogTitle className="p-3">契約情報登録</DialogTitle>
        <DialogContent className="px-0 py-0 pr-0 pl-0">
          <Card className="m-3 p-3">
            <Row>
              <Col className="pr-1" md="3">
                <Form.Group>
                  <label>契約日</label>
                  <TextComponent
                    type="date"
                    value={contract.KEI_DATE}
                    onChange={handleChange('KEI_DATE')}
                    error={errorFileds.some((x) => x == 'KEI_DATE')}
                  ></TextComponent>
                </Form.Group>
              </Col>
              <Col className="d-flex align-items-end">
                <label className="mr-2" style={{ fontSize: '12pt', color: 'black' }}>
                  <input
                    className="mr-2"
                    style={{ transform: 'scale(1.5)' }}
                    type="checkbox"
                    checked={contract.DATE_NOTPRINT == 1}
                    onChange={() => setDatePrint(contract.DATE_NOTPRINT)}
                  ></input>
                  契約書の契約日を印字しない
                </label>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="3">
                <Form.Group>
                  <label>工期：着工予定日</label>
                  <TextComponent
                    type="date"
                    value={contract.KOUKI_ST ?? ''}
                    onChange={handleChange('KOUKI_ST')}
                  ></TextComponent>
                </Form.Group>
              </Col>
              <Col className="pr-1" md="3">
                <Form.Group>
                  <label>工期：完了予定日</label>
                  <TextComponent
                    type="date"
                    value={contract.KOUKI_ED ?? ''}
                    onChange={handleChange('KOUKI_ED')}
                  ></TextComponent>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="3">
                <Form.Group>
                  <label>着工金額支払期日</label>
                  <TextComponent
                    type="date"
                    value={contract.MAE_KIGEN ?? ''}
                    onChange={handleChange('MAE_KIGEN')}
                  ></TextComponent>
                </Form.Group>
              </Col>
              <Col className="pr-1" md="3">
                <Form.Group>
                  <label>着工金額</label>
                  <TextComponent
                    type="number"
                    style={{ textAlign: 'right' }}
                    value={contract.MAE_KIN}
                    onChange={handleChange('MAE_KIN')}
                  ></TextComponent>
                </Form.Group>
              </Col>
              <Col className="pr-3" md="6">
                <Form.Group>
                  <label>着工金額備考</label>
                  <TextComponent
                    type="text"
                    value={contract.MAE_BIKO}
                    onChange={handleChange('MAE_BIKO')}
                  ></TextComponent>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="3"></Col>
              <Col className="pr-1" md="3">
                <Form.Group>
                  <label>完工金額</label>
                  <TextComponent
                    disabled={true}
                    type="number"
                    value={contract.KAN_KIN}
                  ></TextComponent>
                </Form.Group>
              </Col>
              <Col className="pr-3" md="6">
                <Form.Group>
                  <label>完工金額備考</label>
                  <TextComponent
                    type="text"
                    value={contract.KAN_BIKO}
                    onChange={handleChange('KAN_BIKO')}
                  ></TextComponent>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="3"></Col>
              <Col className="pr-1" md="3">
                <Form.Group>
                  <label>合計金額</label>
                  <TextComponent
                    disabled={true}
                    type="number"
                    value={contract.SUM_KIN}
                  ></TextComponent>
                </Form.Group>
              </Col>
              <Col className="pr-3" md="6">
                <Form.Group>
                  <label>合計金額備考</label>
                  <TextComponent
                    type="text"
                    value={contract.SUM_BIKO}
                    onChange={handleChange('SUM_BIKO')}
                  ></TextComponent>
                </Form.Group>
              </Col>
            </Row>
          </Card>
          <MessageModal dialog={dialog}></MessageModal>
        </DialogContent>
        <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
          <Button variant="secondary" className="footer-button" onClick={handleClose}>
            閉じる
          </Button>
          <Button
            variant={action[mode]?.variant}
            className="btn-fill flex-right footer-button"
            onClick={handleOnClickSave}
          >
            {action[mode]?.caption}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ContractEntry;
