import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { CellType, KeyAction } from '@grapecity/wijmo.grid';
import { MessageModal } from 'components/modal/MessageModal';
import { useRestApi } from 'context/RestApiContext';
import { useMaster } from 'context/MasterContext';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// 見積もり基盤履歴モーダル
function QuoteBaseHistoryModal(props) {
  const master = useMaster();
  const restApi = useRestApi();

  // 履歴
  const [navigate, setHistory] = useState([]);

  // モーダルの表示
  const [show, setShow] = useState(false);

  // モーダルパラメタの変更処理
  useEffect(() => {
    if (props.params.baseNo) {
      restApi.get(`/api/master/baseQuote/history/${props.params.baseNo}`, (data) => {
        setHistory(
          data.history
            .sort((a, b) =>
              `${a['UPD_DATE']} ${a['UPD_TIME']}` < `${b['UPD_DATE']} ${b['UPD_TIME']}` ? 1 : -1
            )
            .map((h, i) => ({
              ...h,
              NO: i + 1,
              EDITABLE_FLG: h['EDITABLE_FLG'] == '1',
              UPD_DATE_TIME: `${h['UPD_DATE']} ${h['UPD_TIME']}`,
            }))
        );
        // ダイアログを表示
        setShow(true);
      });
    }
  }, [props.params]);

  // 閉じるボタン
  const handleClose = () => {
    setShow(false);
  };

  // グリッドのフォーマット処理
  const formatItem = (grid, e) => {
    if (e.panel.cellType == CellType.Cell) {
      let col = grid.columns[e.col];

      // 一番過去の場合は処理しない
      if (grid.rows.length <= e.row + 1) {
        return;
      }

      if (col.binding == 'NO' || col.binding == 'UPD_DATE_TIME') {
        return;
      }

      let current = grid.rows[e.row].dataItem;
      let before = grid.rows[e.row + 1].dataItem;
      let isUpd = current[col.binding] != before[col.binding];

      // 変更があったセルに色付け
      e.cell.style.color = isUpd ? 'blue' : '';
      e.cell.style.fontWeight = isUpd ? 'bold' : '';
      e.cell.style.background = isUpd ? '#DDEEFF' : '';
    }
  };

  return (
    <>
      <Dialog fullWidth={true} open={show} maxWidth={'xl'}>
        <DialogTitle className="p-3">変更履歴</DialogTitle>
        <DialogContent className="px-0 py-0 pr-0 pl-0">
          <Card className="m-0">
            <Card.Body>
              <Row>
                <Col className="m-0 p-0 table-scroll" style={{ height: '500px' }}>
                  <FlexGrid
                    allowSorting={false}
                    itemsSource={navigate}
                    selectionMode={'Cell'}
                    headersVisibility={'Column'}
                    formatItem={formatItem}
                    autoGenerateColumns={true}
                    isReadOnly={true}
                    loadedRows={(grid) => {
                      grid.rows.forEach((ele) => {
                        ele.height = 24;
                      });
                    }}
                  >
                    <FlexGridColumn binding="NO" header="No" width={40}></FlexGridColumn>
                    <FlexGridColumn
                      binding="UPD_DATE_TIME"
                      header="変更日時"
                      width={150}
                    ></FlexGridColumn>
                    <FlexGridColumn binding="CONTENT" header="名称" width={200}></FlexGridColumn>
                    <FlexGridColumn binding="SIYOU" header="仕様" width={200}></FlexGridColumn>
                    <FlexGridColumn
                      binding="SU"
                      header="数量"
                      dataType="Number"
                      isRequired={false}
                      width={100}
                      inputType={'tel'}
                      format="n0"
                    ></FlexGridColumn>
                    <FlexGridColumn binding="TANI" header="単位" width={50}></FlexGridColumn>
                    <FlexGridColumn
                      binding="GENKA_TANKA"
                      header="原価単価"
                      dataType="Number"
                      isRequired={false}
                      width={100}
                      format="n0"
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="GENKA_RATE"
                      header="掛け率"
                      width={100}
                      dataType="Number"
                      format="n2"
                      isRequired={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="TANKA"
                      header="単価"
                      dataType="Number"
                      isRequired={false}
                      width={100}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="EDITABLE_FLG"
                      header="変更可能"
                      width={80}
                    ></FlexGridColumn>
                    <FlexGridColumn binding="BIKO" header="摘要" width={100}></FlexGridColumn>
                    <FlexGridColumn
                      binding="BIKO_SYANAI"
                      header="社内備考"
                      width="2*"
                      allowResizing={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="SORT"
                      header="ソート順"
                      width={80}
                      allowResizing={false}
                      isReadOnly={true}
                      format="d"
                      align="right"
                    ></FlexGridColumn>
                  </FlexGrid>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </DialogContent>
        <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
          <Button variant="secondary" className="footer-button" onClick={handleClose}>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default QuoteBaseHistoryModal;
