import { DataMap, HeadersVisibility } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import TextComponent from 'components/Input/TextComponent';
import { useMaster } from 'context/MasterContext';
import { useRestApi } from 'context/RestApiContext';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import SelectComponent from 'components/Input/SelectComponent';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';

/** 工事追加選択モーダル画面 */
function PaymentConstructionSelectModal(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const master = useMaster();
  const restApi = useRestApi();

  // 工事一覧
  const [constructions, setConstructions] = useState([]);
  // 表示中の工事一覧
  const [viewConstructions, setViewConstructions] = useState([]);
  // 選択中の工事情報
  const [selectedConstructions, setSelectedConstructions] = useState([]);

  // モーダルの表示
  const [show, setShow] = useState(false);

  // コード翻訳用のマッピング
  var planerMap = new DataMap(master.planer, 'id', 'name');

  // 検索条件
  const [searchCondition, setSearchCondition] = useState({
    TAN_CD: '',
    FILTER: '',
  });

  // 初期表示処理
  useEffect(() => {
    if (props.params?.paymentMonth) {
      // 契約済の工事情報を取得
      restApi.post(
        '/api/payment/constructions',
        { paymentMonth: props.params.paymentMonth },
        (data) => {
          // 検索条件をクリア
          setSearchCondition({
            TAN_CD: '',
            FILTER: '',
          });

          // 選択中の工事を設定
          setSelectedConstructions(props.params.constructions);

          // 検索結果を表示
          setConstructions(
            data.keiList.map((p, i) => {
              // 宛先は請求先を優先する
              let atesaki =
                p.SEIKYU_KBN === 'client'
                  ? p.SEIKYU_CLIENT_NAME
                  : p.SEIKYU_TRI_NAME ||
                    (p.QUOTE_KBN === 'client' ? p.QUOTE_CLIENT_NAME : p.QUOTE_TRI_NAME);
              return {
                ...p,
                Checked: false,
                ATESAKI: atesaki,
              };
            })
          );
          setShow(true);
        }
      );
    }
  }, [props.params]);

  // 検索条件、検索結果の変更処理
  useEffect(() => {
    const filter = searchCondition.FILTER.trim();
    const tanCd = searchCondition.TAN_CD;

    setViewConstructions(
      constructions
        .filter((x) => !filter || x.ATESAKI?.includes(filter) || x.KOUJI_MEI?.includes(filter))
        .filter((x) => !tanCd || x.TAN_CD == tanCd)
        .map((x, i) => ({
          ...x,
          Checked: selectedConstructions.some((s) => s['SEIKYU_NO'] == x['SEIKYU_NO']),
          NO: i + 1,
        }))
    );
  }, [constructions, searchCondition]);

  // 編集処理
  const handleChange = (prop) => (target) => {
    setSearchCondition({ ...searchCondition, [prop]: target?.value ?? target?.target.value });
  };

  // 選択処理
  const handleOnChangeCheck = (item) => (e) => {
    item['Checked'] = e.target.checked;
    if (e.target.checked) {
      setSelectedConstructions([...selectedConstructions, item]);
    } else {
      setSelectedConstructions(
        selectedConstructions.filter((s) => s['SEIKYU_NO'] != item['SEIKYU_NO'])
      );
    }
  };

  // チェックボックスが無効か？
  const isCheckDisabled = (item) => {
    // 支払い情報に請求番号が存在する場合は無効
    return props.params.payments.some((p) => p.SEIKYU_NO == item.SEIKYU_NO);
  };

  // 確定ボタン
  const handleFixed = () => {
    props.params.callback(selectedConstructions);
    setShow(false);
  };

  // 閉じるボタン
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '1080px', // Set your width here
          },
        },
      }}
      open={show}
    >
      <DialogTitle className="p-3">工事選択</DialogTitle>
      <DialogContent className="">
        <Row className="pt-4">
          <Col className="pr-1" md="5">
            <Form.Group>
              <label>絞り込み</label>
              <TextComponent
                placeholder="工事名/依頼主を部分一致で検索します"
                type="text"
                value={searchCondition.FILTER}
                onChange={handleChange('FILTER')}
              ></TextComponent>
            </Form.Group>
          </Col>
          <Col className="pr-1" md="2">
            <Form.Group>
              <label>担当プランナー</label>
              <SelectComponent value={searchCondition.TAN_CD} onChange={handleChange('TAN_CD')}>
                <option value="">　</option>
                {master.planer.map((x, i) => (
                  <option key={i} value={x['id']}>
                    {x['name']}
                  </option>
                ))}
              </SelectComponent>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <div className="table-scroll" style={{ height: '580px' }}>
              <FlexGrid
                isReadOnly={true}
                itemsSource={viewConstructions}
                selectionMode={'Row'}
                headersVisibility={HeadersVisibility.Column}
                allowSorting={true}
                style={{ height: '100%' }}
                loadedRows={(grid) => {
                  grid.rows.forEach((ele) => {
                    ele.height = 24;
                  });
                }}
              >
                <FlexGridColumn binding="Checked" header=" " width={34}>
                  <FlexGridCellTemplate
                    cellType="Cell"
                    template={(cell) => (
                      <input
                        type="checkbox"
                        checked={cell.item.Checked}
                        onChange={handleOnChangeCheck(cell.item)}
                        disabled={isCheckDisabled(cell.item)}
                      ></input>
                    )}
                  />
                </FlexGridColumn>
                <FlexGridColumn
                  binding="SEIKYU_NO"
                  header="#"
                  width={40}
                  format="d"
                ></FlexGridColumn>
                <FlexGridColumn binding="KOUJI_MEI" header="工事名" width={'1*'}></FlexGridColumn>
                <FlexGridColumn binding="ATESAKI" header="依頼主" width={'1*'}></FlexGridColumn>
                <FlexGridColumn
                  binding="KOUJI_BASYO"
                  header="工事場所"
                  width={'2*'}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="TAN_CD"
                  dataMap={planerMap}
                  header="担当"
                  width={80}
                ></FlexGridColumn>
              </FlexGrid>
            </div>
          </Col>
        </Row>
      </DialogContent>
      <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
        <Button variant="secondary" className="footer-button" onClick={handleClose}>
          閉じる
        </Button>
        <Button variant="primary btn-fill" className="footer-button" onClick={handleFixed}>
          確定
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PaymentConstructionSelectModal;
