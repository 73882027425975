import { useMaster } from "context/MasterContext";
import { format } from "date-fns";
import { useCallback, useEffect, useState, useRef } from 'react';
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { MessageModal } from "components/modal/MessageModal"
import { useRestApi } from 'context/RestApiContext';
import TextComponent from "components/Input/TextComponent";
import SelectComponent from "components/Input/SelectComponent";
import { checkRequired } from "utils/validationUtils"
import { getAddress } from 'utils/addresUtils';

const ClientEdit = (props) => {

    const restApi = useRestApi();

    // 確認ダイアログ
    const [dialog, setDialog] = useState({})

    // マスタ情報
    const master = useMaster();
    const navigate = useNavigate();

    // 画面パラメタを取得
    const location = useLocation()
    let targetClient = location?.state?.targetClient;
    // 画面モード
    let mode = location?.state?.mode;
    const [client, setClient] = useState(targetClient ?? {
        NAME_SEI_1: "",
        NAME_MEI_1: "",
        NAME_SEI_KANA_1: "",
        NAME_MEI_KANA_1: "",
        NAME_SEI_2: "",
        NAME_MEI_2: "",
        NAME_SEI_KANA_2: "",
        NAME_MEI_KANA_2: "",
        POST_CODE: "",
        PREF: "",
        CITY: "",
        TOWN: "",
        ADDRESS: "",
        TEL1: "",
        TEL2: "",
        MAIL: "",
        BIRTHDAY: "",
        FAMILY: "",
        CARS: "",
        HOUSE_MAKER: "",
        HOUSE_HIKIWATASHI: "",
        BIKO: "",
        CHANNEL_KBN: -1,
        CHANNEL_CD: -1,
        CHANNEL_TAN_CD: "",
        TAN_CD: "",
        FIRST_INQUIRY_DAY: format(new Date(), "yyyy/MM/dd"),
        HIKKOSI_YOTEI: "",
        TRI_CD: 0,
    });

    // 取引先リスト
    const [toriList, setToriList] = useState([])

    // エラーフィールド
    const [errorFileds, setErrorFileds] = useState([])


    // 初期表示処理
    useEffect(() => {
        // 取引先を取得
        restApi.get("/api/master/tori", (data) => {
            // 受注区分が 1:受注 のみ選択可能
            setToriList(data.filter(x => x.JUCYU_KBN == "1"))
        });
    }, [])

    // 編集処理
    const handleChange = (prop) => (target) => {
        const targetValue = target ? target.value : ""

        let _client = { ...client, [prop]: targetValue }

        // 集客要因区分が変更された場合、集客要因をクリア
        if (prop == "CHANNEL_KBN") {
            _client = { ..._client, CHANNEL_CD: -1 }
        }
        setErrorFileds([...errorFileds].filter(x => x != prop))
        setClient(_client)
    }

    // 郵便番号検索
    const searchZip = () => {
        getAddress(restApi, client.POST_CODE, (json) => {
            let result = json["results"][0];
            setClient({ ...client, PREF: result["address1"], CITY: result["address2"], TOWN: result["address3"] })
            setErrorFileds([...errorFileds].filter(x => (x != "PREF" && x != "CITY" && x != "TOWN")))
        }, setDialog)
    }

    // 保存ボタン
    const handleOnClickSave = () => {
        var error = validate()
        if (error.length > 0) {
            setDialog({
                type: "error",
                message: error,
            })
            return;
        }

        restApi.post(`/api/client/${mode}`, client, (data) => {
            // 呼び出し元に戻る 
            navigate(
                '/admin/clientmanage',
                {
                    state: {
                        targetClientCd: data["CLIENT_CD"],
                        searchCondition: location?.state?.searchCondition
                    }
                }
            );
        })
    }

    // 入力チェック
    const validate = () => {
        let errors = []
        checkRequired(client.NAME_SEI_1, "施主名１（姓）", "NAME_SEI_1", errors)
        checkRequired(client.NAME_MEI_1, "施主名１（名）", "NAME_MEI_1", errors)
        checkRequired(client.POST_CODE, "郵便番号", "POST_CODE", errors)
        checkRequired(client.PREF, "都道府県", "PREF", errors)
        checkRequired(client.CITY, "市", "CITY", errors)
        checkRequired(client.TOWN, "町村", "TOWN", errors)
        checkRequired(client.ADDRESS, "番地", "ADDRESS", errors)
        checkRequired(client.TEL1, "電話番号1", "TEL1", errors)
        checkRequired(client.MAIL, "メールアドレス", "MAIL", errors)

        setErrorFileds(errors.flatMap(x => (x.fields)))

        return errors.map(x => (x.msg))
    }

    // 削除ボタン
    const handleOnClickDelete = () => {
        setDialog({
            type: "confirm",
            message: "削除します。よろしいですか？",
            okCallBack: () => {
                restApi.post(`/api/client/del`, client, (_) => {
                    // 呼び出し元に戻る 
                    navigate(
                        '/admin/clientmanage',
                        {
                            state: {
                                targetClientCd: "",
                                searchCondition: location?.state?.searchCondition
                            }
                        }
                    );
                })
            }
        })
    };


    // 戻るボタン
    const handleOnClickReturn = () => {
        navigate(
            '/admin/clientmanage',
            {
                state: {
                    targetClientCd: client["CLIENT_CD"],
                    searchCondition: location?.state?.searchCondition,
                }
            });
    }

    return (
        <>
            <Card className='p-2'>
                <Row>
                    <Col className="pr-1" md="2">
                        <Form.Group>
                            <label>顧客ID</label>
                            <TextComponent
                                disabled
                                value={client.CLIENT_CD}
                                type="text"
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="2">
                        <label>初回問合せ日</label>
                        <TextComponent
                            type="date"
                            value={client.FIRST_INQUIRY_DAY.trim() == "" ? format(new Date(), "yyyy/MM/dd") : client.FIRST_INQUIRY_DAY}
                            onChange={handleChange('FIRST_INQUIRY_DAY')}
                        ></TextComponent>
                    </Col>
                </Row>
                <Row className='pt-1'>
                    <Col className="pr-1" md="2">
                        <Form.Group>
                            <label>施主名１（姓）<span class="badge badge-danger">必須</span></label>
                            <TextComponent
                                type="text"
                                value={client.NAME_SEI_1}
                                onChange={handleChange('NAME_SEI_1')}
                                error={errorFileds.some(x => x == 'NAME_SEI_1')}
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="2">
                        <Form.Group>
                            <label>施主名１（名）<span class="badge badge-danger">必須</span></label>
                            <TextComponent
                                type="text"
                                value={client.NAME_MEI_1}
                                onChange={handleChange('NAME_MEI_1')}
                                error={errorFileds.some(x => x == 'NAME_MEI_1')}
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="2">
                        <Form.Group>
                            <label>施主名１かな（姓）</label>
                            <TextComponent
                                type="text"
                                value={client.NAME_SEI_KANA_1}
                                onChange={handleChange('NAME_SEI_KANA_1')}
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="2">
                        <Form.Group>
                            <label>施主名１かな（名）</label>
                            <TextComponent
                                type="text"
                                value={client.NAME_MEI_KANA_1}
                                onChange={handleChange('NAME_MEI_KANA_1')}
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='pt-1'>
                    <Col className="pr-1" md="2">
                        <Form.Group>
                            <label>施主名２（姓）</label>
                            <TextComponent
                                type="text"
                                value={client.NAME_SEI_2}
                                onChange={handleChange('NAME_SEI_2')}
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="2">
                        <Form.Group>
                            <label>施主名２（名）</label>
                            <TextComponent
                                type="text"
                                value={client.NAME_MEI_2}
                                onChange={handleChange('NAME_MEI_2')}
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="2">
                        <Form.Group>
                            <label>施主名２かな（姓）</label>
                            <TextComponent
                                type="text"
                                value={client.NAME_SEI_KANA_2}
                                onChange={handleChange('NAME_SEI_KANA_2')}
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="2">
                        <Form.Group>
                            <label>施主名２かな（名）</label>
                            <TextComponent
                                type="text"
                                value={client.NAME_MEI_KANA_2}
                                onChange={handleChange('NAME_MEI_KANA_2')}
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='pt-1'>
                    <Col className="pr-1 form-inline" md="3">
                        <label>郵便番号<span class="badge badge-danger ml-1">必須</span></label>
                        <Form.Group>
                            <TextComponent
                                type="tel"
                                value={client.POST_CODE}
                                onChange={handleChange('POST_CODE')}
                                error={errorFileds.some(x => x == 'POST_CODE')}
                            ></TextComponent>
                            <Button variant='default' onClick={searchZip}>郵便番号検索</Button>
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="1">
                        <Form.Group>
                            <label>都道府県<span class="badge badge-danger ml-1">必須</span></label>
                            <TextComponent
                                type="text"
                                value={client.PREF}
                                onChange={handleChange('PREF')}
                                error={errorFileds.some(x => x == 'PREF')}
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="2">
                        <Form.Group>
                            <label>市<span class="badge badge-danger ml-1">必須</span></label>
                            <TextComponent
                                type="text"
                                value={client.CITY}
                                onChange={handleChange('CITY')}
                                error={errorFileds.some(x => x == 'CITY')}
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="2">
                        <Form.Group>
                            <label>町村<span class="badge badge-danger ml-1">必須</span></label>
                            <TextComponent
                                type="text"
                                value={client.TOWN}
                                onChange={handleChange('TOWN')}
                                error={errorFileds.some(x => x == 'TOWN')}
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>番地<span class="badge badge-danger ml-1">必須</span></label>
                            <TextComponent
                                type="text"
                                value={client.ADDRESS}
                                onChange={handleChange('ADDRESS')}
                                error={errorFileds.some(x => x == 'ADDRESS')}
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                </Row>


                <Row className='pt-1'>
                    <Col className="pr-1" md="4">
                        <Form.Group>
                            <label>電話番号１<span class="badge badge-danger ml-1">必須</span></label>
                            <TextComponent
                                type="tel"
                                value={client.TEL1}
                                onChange={handleChange('TEL1')}
                                error={errorFileds.some(x => x == 'TEL1')}
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="4">
                        <Form.Group>
                            <label>電話番号２</label>
                            <TextComponent
                                type="tel"
                                value={client.TEL2}
                                onChange={handleChange('TEL2')}
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="4">
                        <Form.Group>
                            <label>メールアドレス<span class="badge badge-danger ml-1">必須</span></label>
                            <TextComponent
                                type="email"
                                value={client.MAIL}
                                onChange={handleChange('MAIL')}
                                error={errorFileds.some(x => x == 'MAIL')}
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='pt-1'>
                    <Col className="pr-1" md="2">
                        <Form.Group>
                            <label>生年月日</label>
                            <TextComponent
                                type="date"
                                value={client.BIRTHDAY}
                                onChange={handleChange('BIRTHDAY')}
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='pt-1'>
                    <Col className="pr-1" md="12">
                        <Form.Group>
                            <label>家族構成</label>
                            <TextComponent
                                type="text"
                                value={client.FAMILY}
                                onChange={handleChange('FAMILY')}
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='pt-1'>
                    <Col className="pr-1" md="1">
                        <Form.Group>
                            <label>車の保有台数</label>
                            <TextComponent
                                type="tel"
                                value={client.CARS}
                                onChange={handleChange('CARS')}
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="2">
                        <Form.Group>
                            <label>ハウスメーカー</label>
                            <SelectComponent
                                value={client.HOUSE_MAKER}
                                onChange={handleChange('HOUSE_MAKER')}>
                                <option></option>
                                {
                                    master.houseMaker.map((x, i) => (
                                        <option key={i} value={x["id"]}>{x["name"]}</option>
                                    ))}
                            </SelectComponent>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='pt-1'>
                    <Col className="pr-1" md="2">
                        <Form.Group>
                            <label>住宅引き渡し予定日</label>
                            <TextComponent
                                type="date"
                                value={client.HOUSE_HIKIWATASHI}
                                onChange={handleChange('HOUSE_HIKIWATASHI')}
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="2">
                        <Form.Group>
                            <label>引っ越し予定日</label>
                            <TextComponent
                                type="date"
                                value={client.HIKKOSI_YOTEI}
                                onChange={handleChange('HIKKOSI_YOTEI')}
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="12">
                        <Form.Group>
                            <label>備考</label>
                            <TextComponent
                                type="text"
                                value={client.BIKO}
                                onChange={handleChange('BIKO')} s
                            ></TextComponent>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='pt-1'>
                    <Col className="pr-1" md="2">
                        <Form.Group>
                            <label>集客要因区分</label>
                            <SelectComponent
                                value={client.CHANNEL_KBN}
                                onChange={handleChange('CHANNEL_KBN')}>
                                <option></option>
                                {
                                    master.channelKbn.map((x, i) => (
                                        <option key={i} value={x["CHANNEL_KBN"]}>{x["CHANNEL_KBN_NM"]}</option>
                                    ))}
                            </SelectComponent>
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="2">
                        <Form.Group>
                            <label>集客要因</label>
                            <SelectComponent
                                value={client.CHANNEL_CD}
                                onChange={handleChange('CHANNEL_CD')}>
                                <option></option>
                                {
                                    master.channelCd.filter(x => x.CHANNEL_KBN == client.CHANNEL_KBN).map((x, i) => (
                                        <option key={i} value={x["CHANNEL_CD"]}>{x["CHANNEL_NM"]}</option>
                                    ))}
                            </SelectComponent>
                        </Form.Group>
                    </Col>

                    <Col className="pr-1" md="2">
                        <Form.Group>
                            <label>受注元</label>
                            <SelectComponent
                                value={client.TRI_CD}
                                onChange={handleChange('TRI_CD')}>
                                <option value={0}></option>
                                {
                                    toriList.map((x, i) => (
                                        <option key={i} value={x["TRI_CD"]}>{x["RYAKU"]}</option>
                                    ))}
                            </SelectComponent>
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="2">
                        <Form.Group>
                            <label>担当プランナー</label>
                            <SelectComponent
                                value={client.TAN_CD}
                                onChange={handleChange('TAN_CD')}>
                                <option value={""}></option>
                                {
                                    master.planer.map((x, i) => (
                                        <option key={i} value={x["id"]}>{x["name"]}</option>
                                    ))}
                            </SelectComponent>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="pt-2" >
                    <Col>
                        <Button variant="secondary" className="footer-button" onClick={handleOnClickReturn}>戻る</Button>
                    </Col>
                    <Col className="text-right">
                        {
                            mode == "del" ?
                                <Button variant="danger" className="btn-fill footer-button" onClick={handleOnClickDelete}>削除</Button>
                                : <Button variant="primary" className="btn-fill footer-button" onClick={handleOnClickSave}>保存</Button>
                        }
                    </Col>
                </Row>
            </Card>
            <MessageModal dialog={dialog}></MessageModal>
        </>
    );
};
export default ClientEdit;