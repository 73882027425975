import { useState, useContext, useEffect, useRef, useCallback } from 'react';

import { Button, Card, Form, Row, Col } from "react-bootstrap";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useRestApi } from 'context/RestApiContext'

import { MessageModal } from 'components/modal/MessageModal'
import TextComponent from 'components/Input/TextComponent'

function WarrantyEntryModal(props) {

    const restApi = useRestApi();

    // モード情報
    const MODE_LIST = [
        { id: "add", caption: "登録", variant: "primary", readOnly: false },
        { id: "upd", caption: "更新", variant: "primary", readOnly: false },
        { id: "del", caption: "削除", variant: "danger", readOnly: true },
    ]

    // 確認ダイアログ
    const [dialog, setDialog] = useState({})

    // 画面モード
    const [mode, setMode] = useState({})

    // 引渡日
    const [hikiDate, setHikiDate] = useState("")

    // モーダルの表示
    const [show, setShow] = useState(false);

    // モーダルパラメタの変更処理
    useEffect(() => {
        if (props?.params?.seikyuNo) {
            setMode(MODE_LIST.find(x => x.id == props.params.mode))
            setHikiDate(props.params.hikiDate)
            setShow(true)
        }
    }, [props.params]);

    // 編集処理
    const handleOnChange = (target) => {
        setHikiDate(target.value);
    }

    // 作成ボタン押下処理
    const handleOnClickCreate = () => {
        setDialog({
            type: "confirm",
            message: `${mode.caption}します。よろしいですか？`,
            okCallBack: () => {

                let param = {
                    SEIKYU_NO: props.params.seikyuNo,
                    HIKI_DATE: hikiDate,
                }

                // 登録(保証書の印刷もおこなう)
                if (mode?.id == "add") {
                    restApi.postToPdf("/api/warranty/create", param, () => {
                        props.callbak()
                        setShow(false)
                    })
                }
                // 更新
                else {
                    restApi.post(`/api/warranty/${mode.id}`, param, () => {
                        props.callbak()
                        setShow(false)
                    })
                }
            }
        })
    }

    // 閉じるボタン
    const handleClose = useCallback(() => {
        setShow(false)
    }, [props.params])

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={show}
            >
                <DialogTitle className="p-3">保証書</DialogTitle>
                <DialogContent className="px-0 py-0 pr-0 pl-0">
                    <Card className="m-0">
                        <Card.Body>
                            <Row>
                                <Col className="pl-3" md="5">
                                    <Form.Group>
                                        <label>引渡日</label>
                                        <TextComponent
                                            type="date"
                                            value={hikiDate}
                                            disabled={mode?.readOnly}
                                            onChange={handleOnChange}
                                        ></TextComponent>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </DialogContent>
                <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
                    <Button variant="secondary" className="footer-button" onClick={handleClose}>閉じる</Button>
                    <Button className='btn-fill footer-button' variant={mode?.variant} disabled={hikiDate == ""} onClick={handleOnClickCreate}>{mode?.caption}</Button>
                </DialogActions>
            </Dialog>
            { /** メッセージモーダル */}
            < MessageModal dialog={dialog} ></MessageModal >
        </>
    );
}

export default WarrantyEntryModal;
