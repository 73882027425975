import { useCallback, useEffect, useState, useRef } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { MessageModal } from 'components/modal/MessageModal';
import { useRestApi } from 'context/RestApiContext';
import TextComponent from 'components/Input/TextComponent';
import { checkRequired } from 'utils/validationUtils';
import { getAddress } from 'utils/addresUtils';

const TriEdit = (props) => {
  const restApi = useRestApi();

  // 確認ダイアログ
  const [dialog, setDialog] = useState({});

  const navigate = useNavigate();

  // 画面パラメタを取得
  const location = useLocation();
  let targetTri = location?.state?.targetTri;
  // 画面モード
  let mode = location?.state?.mode;
  let maxsort = location?.state?.maxsort;
  const [tri, setTri] = useState(
    targetTri ?? {
      HACYU_KBN: '',
      JUCYU_KBN: '',
      TRI_NAME: '',
      TRI_NAME_KANA: '',
      RYAKU: '',
      ADD1: '',
      ADD2: '',
      POST_CODE: '',
      TEL: '',
      FAX: '',
      TAN_NAME: '',
      TAN_MAIL: '',
      TAN_MOBILE: '',
      KESSAN: '',
      GTMATU: '',
      SEIKYU: '',
      SORT: '',
      BIKO: '',
    }
  );

  // エラーフィールド
  const [errorFileds, setErrorFileds] = useState([]);

  // 編集処理
  const handleChange = (prop) => (target) => {
    const targetValue = target ? target.value : '';
    let _tri = { ...tri, [prop]: targetValue };
    setErrorFileds([...errorFileds].filter((x) => x != prop));
    setTri(_tri);
  };
  //チェックボックスの編集処理
  const handleChangeCheck = (prop) => (target) => {
    let _tri = { ...tri, [prop]: tri[prop] == '1' ? '' : '1' };
    setErrorFileds([...errorFileds].filter((x) => x != prop));
    setTri(_tri);
  };
  // 保存ボタン
  const handleOnClickSave = () => {
    var error = validate();
    if (error.length > 0) {
      setDialog({
        type: 'error',
        message: error,
      });
      return;
    }

    restApi.post(`/api/master/${mode}`, tri, (data) => {
      // 呼び出し元に戻る
      navigate('/admin/trimanage', {
        state: {
          searchCondition: location?.state?.searchCondition,
        },
      });
    });
  };
  //入力チェック
  const validate = () => {
    let errors = [];
    checkRequired(tri.TRI_NAME, '取引先名', 'TRI_NAME', errors);
    checkRequired(tri.POST_CODE, '郵便番号', 'POST_CODE', errors);
    checkRequired(tri.ADD1, '所在地1', 'ADD1', errors);
    if (!tri.HACYU_KBN && !tri.JUCYU_KBN) {
      errors.push({
        msg: `・発注区分または受注区分を入力してください。`,
        fields: ['HACYU_KBN', 'JUCYU_KBN'],
      });
    }
    setErrorFileds(errors.flatMap((x) => x.fields));

    return errors.map((x) => x.msg);
  };

  // 削除ボタン
  const handleOnClickDelete = () => {
    setDialog({
      type: 'confirm',
      message: '削除します。よろしいですか？',
      okCallBack: () => {
        restApi.post(`/api/master/del`, tri, (_) => {
          // 呼び出し元に戻る
          navigate('/admin/trimanage', {
            state: {
              searchCondition: location?.state?.searchCondition,
            },
          });
        });
      },
    });
  };
  // 郵便番号検索
  const searchZip = () => {
    getAddress(
      restApi,
      tri.POST_CODE,
      (json) => {
        let result = json['results'][0];
        setTri({ ...tri, ADD1: result['address1'] + result['address2'] + result['address3'] });
        setErrorFileds([...errorFileds].filter((x) => x != 'ADD1'));
      },
      setDialog
    );
  };

  // 戻るボタン
  const handleOnClickReturn = () => {
    navigate('/admin/trimanage', {
      state: {
        searchCondition: location?.state?.searchCondition,
      },
    });
  };
  useEffect(() => {
    //ソートの初期表示
    if (mode == 'add') {
      let _tri = { ...tri, SORT: String(Math.ceil((maxsort + 1) / 10) * 10) };
      setTri(_tri);
    }
  }, []);
  return (
    <>
      <Card className="p-2" style={{ height: '860px' }}>
        <Row>
          <Col className="pr-1" md="2">
            <Form.Group>
              <label>取引先ID</label>
              <TextComponent disabled value={tri.TRI_CD} type="text"></TextComponent>
            </Form.Group>
          </Col>
          <Col className="pr-1" md="1">
            <label>ソート順</label>
            <TextComponent
              type="number"
              fmt="d"
              value={parseInt(tri.SORT)}
              onChange={handleChange('SORT')}
            ></TextComponent>
          </Col>
          <Col className="pr-1">
            <label>
              発注・受注区分<span className="badge badge-danger ml-1">必須</span>
            </label>
            <Form.Group>
              <div
                style={{
                  backgroundColor:
                    errorFileds.includes('JUCYU_KBN') && errorFileds.includes('HACYU_KBN')
                      ? 'lightpink'
                      : '',
                  border: '1px solid #E3E3E3',
                  borderRadius: '4px',
                  padding: '8px 5px 8px 8px',
                  height: '40px',
                  color: '#565656',
                  boxShadow: 'none',
                  width: '172px',
                  transition: 'all 300ms linear',
                  display: 'Block',
                  borderBottom: '3px solid rgb(227, 227, 227)',
                }}
              >
                &nbsp;&nbsp;
                <label style={{ fontWeight: '400', fontSize: '16px', color: 'black' }}>
                  <input
                    className="mr-3"
                    style={{ transform: 'scale(2)' }}
                    type="checkbox"
                    checked={tri.HACYU_KBN == 1}
                    onChange={handleChangeCheck('HACYU_KBN')}
                  ></input>
                  発注
                </label>
                &nbsp;&nbsp;&nbsp;
                <label style={{ fontWeight: '400', fontSize: '16px', color: 'black' }}>
                  <input
                    className="mr-3"
                    style={{ transform: 'scale(2)' }}
                    type="checkbox"
                    checked={tri.JUCYU_KBN == 1}
                    onChange={handleChangeCheck('JUCYU_KBN')}
                  ></input>
                  受注
                </label>
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row className="pt-1">
          <Col className="pr-1" md="2">
            <label>
              取引先名<span className="badge badge-danger ml-1">必須</span>
            </label>
            <TextComponent
              type="text"
              value={tri.TRI_NAME}
              onChange={handleChange('TRI_NAME')}
              error={errorFileds.some((x) => x == 'TRI_NAME')}
            ></TextComponent>
          </Col>
          <Col className="pr-1" md="2">
            <label>取引先名かな</label>
            <TextComponent
              type="text"
              value={tri.TRI_NAME_KANA}
              onChange={handleChange('TRI_NAME_KANA')}
            ></TextComponent>
          </Col>
          <Col className="pr-1" md="2">
            <label>略名</label>
            <TextComponent
              type="text"
              value={tri.RYAKU}
              onChange={handleChange('RYAKU')}
            ></TextComponent>
          </Col>
        </Row>
        <Row className="pt-1">
          <Col className="pr-1 form-inline" md="3">
            <label>
              郵便番号<span className="badge badge-danger ml-1">必須</span>
            </label>
            <Form.Group>
              <TextComponent
                type="tel"
                value={tri.POST_CODE}
                onChange={handleChange('POST_CODE')}
                error={errorFileds.some((x) => x == 'POST_CODE')}
              ></TextComponent>
              <Button variant="default" onClick={searchZip}>
                郵便番号検索
              </Button>
            </Form.Group>
          </Col>
        </Row>
        <Row className="pt-1">
          <Col className="pr-1" md="5">
            <label>
              所在地1<span className="badge badge-danger ml-1">必須</span>
            </label>
            <TextComponent
              type="text"
              value={tri.ADD1}
              onChange={handleChange('ADD1')}
              error={errorFileds.some((x) => x == 'ADD1')}
            ></TextComponent>
          </Col>
          <Col className="pr-1" md="5">
            <label>所在地2</label>
            <TextComponent
              type="text"
              value={tri.ADD2}
              onChange={handleChange('ADD2')}
            ></TextComponent>
          </Col>
        </Row>
        <Row className="pt-1">
          <Col className="pr-1" md="2">
            <label>電話番号</label>
            <TextComponent
              type="tel"
              value={tri.TEL}
              onChange={handleChange('TEL')}
            ></TextComponent>
          </Col>
          <Col className="pr-1" md="2">
            <label>FAX</label>
            <TextComponent
              type="tel"
              value={tri.FAX}
              onChange={handleChange('FAX')}
            ></TextComponent>
          </Col>
        </Row>
        <Row className="pt-1">
          <Col className="pr-1" md="2">
            <label>担当者名</label>
            <TextComponent
              type="text"
              value={tri.TAN_NAME}
              onChange={handleChange('TAN_NAME')}
            ></TextComponent>
          </Col>
          <Col className="pr-1" md="2">
            <label>担当者メール</label>
            <TextComponent
              type="email"
              value={tri.TAN_MAIL}
              onChange={handleChange('TAN_MAIL')}
            ></TextComponent>
          </Col>
          <Col className="pr-1" md="2">
            <label>担当者携帯</label>
            <TextComponent
              type="tel"
              value={tri.TAN_MOBILE}
              onChange={handleChange('TAN_MOBILE')}
            ></TextComponent>
          </Col>
        </Row>
        <Row className="pt-1">
          <Col className="pr-1" md="11">
            <Form.Group>
              <label>備考</label>
              <TextComponent
                type="text"
                value={tri.BIKO}
                onChange={handleChange('BIKO')}
              ></TextComponent>
            </Form.Group>
          </Col>
        </Row>
        <Row className="pt-2">
          <Col className="pt-1 pl-3" style={{ top: '280px' }}>
            <Row>
              <Col className="d-flex align-items-end">
                <Button variant="secondary" className="footer-button" onClick={handleOnClickReturn}>
                  戻る
                </Button>
              </Col>
              <Col className="d-flex align-items-end flex-row-reverse">
                {mode == 'del' ? (
                  <Button
                    variant="danger"
                    className="btn-fill footer-button"
                    onClick={handleOnClickDelete}
                  >
                    削除
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    className="btn-fill footer-button"
                    onClick={handleOnClickSave}
                  >
                    保存
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <MessageModal dialog={dialog}></MessageModal>
    </>
  );
};
export default TriEdit;
