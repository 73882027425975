import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { DataMap } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import { useRestApi } from 'context/RestApiContext';
import { useMaster } from 'context/MasterContext';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row, Alert } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router';
import StatusLableComponent from 'components/Input/StatusLableComponent';

function ApprovalList() {
  const master = useMaster();
  const restApi = useRestApi();
  // 画面遷移履歴
  const location = useLocation();
  const navigate = useNavigate();

  // コード翻訳用のマッピング
  var staffMap = new DataMap(master.staff, 'id', 'name');

  // 承認可能なユーザかどうか？
  const [isApp, setApp] = useState(false);
  // 承認待ち一覧
  const [pendingList, setPendingList] = useState([]);
  // 承認申請一覧
  const [requestList, setRequestList] = useState([]);

  // 印刷済みも表示のチェックボックス
  const [checkedPrinted, setCheckedPrinted] = useState(location.state?.checkedPrinted ?? false);

  // 初期表示
  useEffect(() => {
    restApi.get(`/api/apporval/list/${checkedPrinted ? 1 : 0}`, (data) => {
      setApp(data.isApp);
      setRequestList(data.requestList.map((x, i) => ({ ...x, SEQ: i + 1 })));
      // setPendingList(data.pendingList.map((x, i) => ({ ...x, SEQ: i + 1 })));
    });
  }, []);

  useEffect(() => {
    // 印刷済みのチェックを保持する
    navigate(location.pathname, {
      state: { ...location.state, checkedPrinted: checkedPrinted },
      replace: true,
    });

    console.log('▲', location.state?.checkedPrinted, checkedPrinted);

    // 印刷済みを表示するかを変更時の処理
    restApi.get(`/api/apporval/pendingList/${checkedPrinted ? 1 : 0}`, (data) => {
      setPendingList(data.pendingList.map((x, i) => ({ ...x, SEQ: i + 1 })));
    });
  }, [checkedPrinted]);

  // 承認処理
  const handleOnClickEdit = (item) => {
    if (item['KBN'] == '見積') {
      navigate('/admin/CustomerQuoteEntry', {
        state: {
          mode: 'approval',
          clientId: item['CLIENT_CD'],
          seikyuNo: item['SEIKYU_NO'],
          quoteNo: item['NO'],
          backPage: location?.pathname,
          checkedPrinted: checkedPrinted,
        },
      });
    } else if (item['KBN'] == '追加請求') {
      navigate('/admin/addinvoice', {
        state: {
          mode: 'approval',
          clientId: item['CLIENT_CD'],
          seikyuNo: item['SEIKYU_NO'],
          quoteNo: item['NO'],
          backPage: location?.pathname,
          addInvoice: true,
          checkedPrinted: checkedPrinted,
        },
      });
    } else if (item['KBN'] == '請求書') {
      navigate('/admin/InvoiceApproval', {
        state: {
          mode: 'approval',
          seikyuNo: item['SEIKYU_NO'],
          backPage: location?.pathname,
          checkedPrinted: checkedPrinted,
        },
      });
    } else {
      navigate('/admin/orderentry', {
        state: {
          mode: 'approval',
          cyuNo: item['NO'],
          backPage: location?.pathname,
          checkedPrinted: checkedPrinted,
        },
      });
    }
  };

  // 顧客情報ボタン
  const handleOnClickClientInfo = (item) => {
    navigate('/admin/ClientInfo', {
      state: {
        clientId: item.CLIENT_CD,
        seikyuNo: item.SEIKYU_NO,
        cyuNo: item.KBN == '注文' ? item.NO : -1,
        quoteNo: item.KBN == '見積' || item.KBN == '追加請求' ? item.NO : -1,
      },
    });
  };

  // 印刷ボタン
  const handleOnClickPrint = (item) => {
    if (item.KBN == '見積' || item.KBN == '追加請求') {
      restApi.getToPdf(`/api/quote/report/${item.NO}`);
    } else if (item.KBN == '注文') {
      restApi.getToPdf(`/api/order/report/${item.NO}`);
    } else if (item.KBN == '請求書') {
      restApi.getToPdf(`/api/invoice/report/${item.NO}`);
    }

    // 印刷日を設定
    if (!item.PRINT_DATE) {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const format_now = `${year}/${month}/${day} ${hours}:${minutes}`;
      setPendingList([
        ...pendingList.map((x) => (x.NO == item.NO ? { ...x, PRINT_DATE: format_now } : x)),
      ]);
    }
  };

  return (
    <>
      <Container fluid>
        {isApp ? (
          <Row>
            <Col>
              <Card className="card-stats mb-3">
                <Card.Header>承認待ち一覧</Card.Header>
                <Card.Body className="p-2">
                  <FlexGrid
                    itemsSource={requestList}
                    headersVisibility={'Column'}
                    selectionMode={'Row'}
                    isReadOnly={true}
                    loadedRows={(grid) => {
                      grid.rows.forEach((ele) => {
                        ele.height = 38;
                      });
                    }}
                    updatedView={(grid) => {
                      grid.rows.forEach((ele) => {
                        ele.height = 38;
                      });
                    }}
                  >
                    <FlexGridFilter />
                    <FlexGridColumn
                      binding="SEQ"
                      header="No"
                      width={60}
                      align={'right'}
                    ></FlexGridColumn>
                    <FlexGridColumn binding="STATUS" header="ステータス" width={92} align="center">
                      <FlexGridCellTemplate
                        cellType="Cell"
                        template={(cell) => <StatusLableComponent status={cell.item?.STATUS} />}
                      />
                    </FlexGridColumn>
                    <FlexGridColumn binding="KBN" header="区分" width={80}></FlexGridColumn>
                    <FlexGridColumn
                      binding="NO"
                      header="見積／注文番号"
                      format={'d*'}
                      width={120}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="TAN_CD"
                      header="担当プランナー"
                      width={120}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="TAN_CD"
                      header="TAN_CD"
                      width={120}
                      dataMap={staffMap}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="CLIENT_NAME"
                      header="顧客名/注文先名"
                      width={180}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="KOUJI_MEI"
                      header="工事名"
                      width="1*"
                      allowResizing={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="SYANAI_MEI"
                      header="社内呼称"
                      width="1*"
                      allowResizing={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="REQ_TAN_CD"
                      header="申請者"
                      width={95}
                      dataMap={staffMap}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="UPD_DATE"
                      header="最終更新日"
                      width={95}
                    ></FlexGridColumn>
                    <FlexGridColumn header="" binding="" isReadOnly={true} width={80}>
                      <FlexGridCellTemplate
                        cellType="Cell"
                        template={(cell) => (
                          <>
                            <Button
                              className="material-symbols-rounded cell-icon btn-fill"
                              variant="primary"
                              title="承認／却下"
                              onClick={() => handleOnClickEdit(cell.item)}
                            >
                              playlist_add_check
                            </Button>
                            <Button
                              className="material-symbols-rounded cell-icon btn-fill"
                              variant="success"
                              title="顧客情報"
                              onClick={() => handleOnClickClientInfo(cell.item)}
                            >
                              person
                            </Button>
                          </>
                        )}
                      />
                    </FlexGridColumn>
                  </FlexGrid>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        <Row>
          <Col>
            <Card className="card-stats mb-3">
              <Card.Header>
                <Row>
                  <Col>承認状態</Col>
                  <Row>
                    <Col className="mr-3 pt-0 d-flex flex-nowrap text-right align-items-end">
                      <label className="mr-2" style={{ fontSize: '12pt', color: 'black' }}>
                        <input
                          className="mr-2"
                          style={{ transform: 'scale(1.3)' }}
                          type="checkbox"
                          checked={checkedPrinted}
                          onChange={(e) => setCheckedPrinted(e.target.checked)}
                        ></input>
                        印刷済みを表示
                      </label>
                    </Col>
                  </Row>
                </Row>
              </Card.Header>
              <Card.Body className="p-2 pt-0">
                <FlexGrid
                  itemsSource={pendingList}
                  headersVisibility={'Column'}
                  selectionMode={'Row'}
                  isReadOnly={true}
                  loadedRows={(grid) => {
                    grid.rows.forEach((ele) => {
                      ele.height = 38;
                    });
                  }}
                  updatedView={(grid) => {
                    grid.rows.forEach((ele) => {
                      ele.height = 38;
                    });
                  }}
                >
                  <FlexGridFilter />
                  <FlexGridColumn
                    binding="SEQ"
                    header="No"
                    width={60}
                    align={'right'}
                  ></FlexGridColumn>
                  <FlexGridColumn binding="STATUS" header="ステータス" width={92} align="center">
                    <FlexGridCellTemplate
                      cellType="Cell"
                      template={(cell) => <StatusLableComponent status={cell.item?.STATUS} />}
                    />
                  </FlexGridColumn>
                  <FlexGridColumn binding="KBN" header="区分" width={80}></FlexGridColumn>
                  <FlexGridColumn
                    binding="NO"
                    header="見積／注文番号"
                    format={'d*'}
                    width={120}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="TAN_CD"
                    header="担当プランナー"
                    width={120}
                    dataMap={staffMap}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="CLIENT_NAME"
                    header="顧客名/注文先名"
                    width={180}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="KOUJI_MEI"
                    header="工事名"
                    width="2*"
                    allowResizing={false}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="SYANAI_MEI"
                    header="社内呼称"
                    width="1*"
                    allowResizing={false}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="APP_TAN_CD"
                    header="承認者"
                    width={120}
                    dataMap={staffMap}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="UPD_DATE"
                    header="最終更新日"
                    width={95}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="PRINT_DATE"
                    header="印刷日時"
                    width={118}
                  ></FlexGridColumn>
                  <FlexGridColumn header="" binding="" isReadOnly={true} width={84}>
                    <FlexGridCellTemplate
                      cellType="Cell"
                      template={(cell) => (
                        <>
                          <Button
                            className="material-symbols-rounded cell-icon btn-fill"
                            variant="success"
                            title="顧客情報"
                            onClick={() => handleOnClickClientInfo(cell.item)}
                          >
                            person
                          </Button>
                          <Button
                            className="material-symbols-rounded cell-icon btn-fill"
                            variant="primary"
                            title="印刷"
                            disabled={cell.item.STATUS !== '承認済み'}
                            onClick={() => handleOnClickPrint(cell.item)}
                          >
                            print
                          </Button>
                        </>
                      )}
                    />
                  </FlexGridColumn>
                </FlexGrid>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ApprovalList;
