import * as wijmo from '@grapecity/wijmo';
import React from 'react';
import { useAuth } from 'context/AuthContext';
import { useRestApi } from 'context/RestApiContext';
import { useMaster } from 'context/MasterContext';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Card, Col, Container, Row, Form } from 'react-bootstrap';
import { Tooltip, Badge, Avatar, Chip } from '@mui/material';
import ClientCardList from 'views/Dashboard/ClientCardList';
import ClientCardSortModal from 'views/Dashboard/ClientCardSortModal';
import ClientCardEditModal from 'views/Dashboard/ClientCardEditModal';
import NoOrdersModal from 'views/Dashboard/NoOrdersModal';
import NyukinCommentModal from 'views/Accounting/NyukinCommentModal';
import { NYUKIN_STATUS } from 'components/Input/NyukinLabelComponent';
import { calcKeiRate } from 'utils/keiyakuUtils';

function Dashboard() {
  const location = useLocation();
  const navigate = useNavigate();

  const auth = useAuth();
  const restApi = useRestApi();
  const master = useMaster();

  // 担当コード
  const [tanCd, setTanCd] = useState(location.state?.tanCd ?? auth.auth.user_id);
  // 担当者
  const [staff, setStaff] = useState({});
  // 売り上げ
  const [sales, setSales] = useState({});
  // 顧客一覧
  const [clients, setClients] = useState([]);
  // 契約件数
  const [keiyakuCount, setKeiyakuCount] = useState({});

  // 承認依頼数
  const [reqCount, setReqCount] = useState(0);

  // 未発注オーダ
  const [noOrderList, setNoOrderList] = useState([]);

  // 入金トラブル件数
  const [nyukinErrCount, setNyukinErrCount] = useState('');

  // 入金件数
  const [nyukinCount, setNyukinCount] = useState('');

  // 編集モーダルのパラメタ
  const [editModalparam, setEditModalparam] = useState({});

  // 入金コメントモーダルのパラメタ
  const [nyukinCommentModalparam, setNyukinCommentModalparam] = useState({});

  // 未発注一覧モーダルのパラメタ
  const [noOrdersModalModalparam, setNoOrdersModalModalparam] = useState({});

  // 並び替えモーダルのパラメタ
  const [sortModalparam, setSortModalparam] = useState({});

  // 担当コードが変わった場合、再表示
  useEffect(() => {
    let tCd = location.state?.tanCd ?? tanCd;
    restApi.get(`/api/dashboard/list/${tCd}`, (data) => {
      // 承認件数
      setReqCount(data.reqCount);
      // 売り上げ
      setSales(data.sales);
      let _staff = master.staff.find((s) => s.id == tanCd);
      // 顧客一覧
      setClients(
        data.clients.map((c) => ({
          ...c,
          LABELS: c.LABELS ? c.LABELS.split(',') : [],
          staff: _staff,
        }))
      );
      setStaff(_staff);

      // 契約件数
      setKeiyakuCount(data.keiyaku_count);

      // 入金確認件数（全額入金、かつ、未完了の件数）
      setNyukinCount(
        data.clients.filter((c) => c.NYUKIN_STATUS == NYUKIN_STATUS.ALL && c.PROCESS_CD != 9).length
      );

      // 入金トラブル件数
      setNyukinErrCount(data.clients.filter((c) => c.NYUKIN_STATUS == NYUKIN_STATUS.ERROR).length);

      // 未発注のオーダ
      setNoOrderList(
        data.clients
          .filter((c) => c.KEI_NO && c.CYU_GENKA <= 0 && c.MITSU_KIN > 0)
          .map((c) => c.SEIKYU_NO)
      );
    });
  }, [tanCd, master.staff]);

  // 並び替えモーダルの呼び出し処理
  const callSortModal = (process) => {
    setSortModalparam({
      clients: [...clients.filter((c) => c.PROCESS_CD == process.PROCESS_CD)],
      processName: process.DISPLAY,
      staff: staff,
      fixCallback: (data) => {
        setClients([
          ...clients.filter((c) => !data.some((d) => c.SEIKYU_NO == d.SEIKYU_NO)),
          ...data,
        ]);
      },
    });
  };

  // 編集モーダルの呼び出し処理
  const callSettingsModal = (client) => {
    setEditModalparam({
      client: client,
      callback: (cl) => {
        setClients([...clients.map((c) => (c == client ? cl : c))]);
      },
    });
  };

  // 入金コメントモーダルの呼び出し処理
  const callNyukinCommentModal = (client) => {
    setNyukinCommentModalparam({ client: client });
  };

  // 未発注一覧モーダルの呼び出し処理
  const callNoOrdersModal = () => {
    setNoOrdersModalModalparam({ show: true });
  };

  // 担当コードの変更処理
  const handleOnChangeTancd = (val) => {
    navigate(location.pathname, { state: { ...location.state, tanCd: val }, replace: true });
    setTanCd(val);
  };

  // 入金確認画面に遷移
  const handleOnClickMoveNyukin = (nyukinStatus) => {
    navigate('/admin/accounting', {
      state: {
        searchCondition: {
          TAN_CD: tanCd,
          FILTER: '',
          STATUS: [nyukinStatus],
        },
      },
    });
  };

  // 申請/承認画面に遷移
  const handleOnClickMoveApproval = () => {
    navigate('/admin/approval');
  };

  return (
    <>
      <Container fluid>
        <Row className="mb-1">
          {auth.auth.kbn && auth.auth.kbn.some((k) => ['sales', 'admin'].includes(k)) ? (
            <>
              <Col className="m-0 p-0" md="1">
                <Form.Select
                  className="form-control"
                  value={tanCd}
                  onChange={(e) => handleOnChangeTancd(e.target.value)}
                >
                  {master.planer.map((x, i) => (
                    <option key={i} value={x['id']}>
                      {x['name']}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col className="m-2  p-0">のダッシュボード</Col>
            </>
          ) : (
            <></>
          )}
          <Col className="text-right">
            {/** 入金確認件数（全額入金、かつ、未完了の件数） */}
            {nyukinCount > 0 ? (
              <Badge
                color="error"
                variant="dot"
                badgeContent={'　'}
                sx={{ '& .MuiBadge-badge': { height: 9, minWidth: 9 } }}
              >
                <Button
                  className="p-2 m-0 d-flex align-items-center"
                  style={{ height: '38px' }}
                  variant="success"
                  onClick={() => handleOnClickMoveNyukin(NYUKIN_STATUS.ALL)}
                >
                  <i className="material-symbols-rounded">Notifications</i>
                  入金確認：
                  <span style={{ color: 'red', fontWeight: 'bold' }}>{nyukinCount}件</span>
                </Button>
              </Badge>
            ) : (
              <></>
            )}
            {/* 入金トラブル */}
            {nyukinErrCount > 0 ? (
              <Badge
                className="ml-2"
                color="error"
                variant="dot"
                badgeContent={'　'}
                sx={{ '& .MuiBadge-badge': { height: 9, minWidth: 9 } }}
              >
                <Button
                  className="p-2 m-0 d-flex align-items-center"
                  style={{ height: '38px' }}
                  variant="danger"
                  onClick={() => handleOnClickMoveNyukin(NYUKIN_STATUS.ERROR)}
                >
                  <i className="material-symbols-rounded">Notifications</i>
                  入金トラブル：
                  <span style={{ color: 'red', fontWeight: 'bold' }}>{nyukinErrCount}件</span>
                </Button>
              </Badge>
            ) : (
              <></>
            )}
            {/* 未発注の工事 */}
            {noOrderList.length > 0 ? (
              <Badge
                className="ml-2"
                color="error"
                variant="dot"
                badgeContent={'　'}
                sx={{ '& .MuiBadge-badge': { height: 9, minWidth: 9 } }}
              >
                <Button
                  className="p-2 m-0 d-flex align-items-center"
                  style={{ height: '38px' }}
                  variant="warning"
                  onClick={() => callNoOrdersModal()}
                >
                  <i className="material-symbols-rounded">Notifications</i>
                  未発注：
                  <span style={{ color: 'red', fontWeight: 'bold' }}>{noOrderList.length}件</span>
                </Button>
              </Badge>
            ) : (
              <></>
            )}
            {/* 承認依頼メッセージ */}
            {reqCount > 0 ? (
              <Badge
                className="ml-2"
                color="error"
                variant="dot"
                badgeContent={'　'}
                sx={{ '& .MuiBadge-badge': { height: 9, minWidth: 9 } }}
              >
                <Button
                  className="p-2 m-0 d-flex align-items-center"
                  style={{ height: '38px' }}
                  variant="primary"
                  onClick={handleOnClickMoveApproval}
                >
                  <i className="material-symbols-rounded">Notifications</i>
                  <span style={{ color: 'red', fontWeight: 'bold' }}>{reqCount}件</span>
                  の承認依頼
                </Button>
              </Badge>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="p-0 pt-1 pr-1" md="2">
            <Card className="m-0">
              <Card.Body className="p-1">
                <Row>
                  <Col>
                    <div>
                      <p className="card-category">年間目標</p>
                    </div>
                  </Col>
                </Row>
                <Row className="m-0 p-0">
                  <Col className="text-right">
                    <h3 className="pt-2 pb-2 m-0">
                      {wijmo.format('{val:c0}', { val: sales.TARGET_AMOUNT })}
                    </h3>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col className="p-1" md="2">
            <Card className="m-0">
              <Card.Body className="p-1">
                <Row>
                  <Col>
                    <div>
                      <p className="card-category">総売上金額</p>
                    </div>
                  </Col>
                </Row>
                <Row className="m-0 p-0">
                  <Col className="text-right">
                    <h3 className="pt-2 pb-2 m-0">
                      {wijmo.format('{val:c0}', { val: sales.SALES })}
                    </h3>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col className="p-1" md="1">
            <Card className="m-0">
              <Card.Body className="p-1">
                <Row>
                  <Col>
                    <div>
                      <p className="card-category">売上進捗</p>
                    </div>
                  </Col>
                </Row>
                <Row className="m-0 p-0">
                  <Col className="text-right">
                    <h3 className="pt-2 pb-2 m-0">
                      {wijmo.format('{val:p0}', { val: sales.PROGRESS })}
                    </h3>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col className="p-1" md="2">
            <Card className="m-0">
              <Card.Body className="p-1">
                <Row>
                  <Col>
                    <div>
                      <p className="card-category">原価</p>
                    </div>
                  </Col>
                </Row>
                <Row className="m-0 p-0">
                  <Col className="text-right">
                    <h3 className="pt-2 pb-2 m-0">
                      {wijmo.format('{val:c0}', { val: sales.GENKA })}
                    </h3>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col className="p-1" md="2">
            <Card className="m-0">
              <Card.Body className="p-1">
                <Row>
                  <Col>
                    <div>
                      <p className="card-category">粗利</p>
                    </div>
                  </Col>
                </Row>
                <Row className="m-0 p-0">
                  <Col className="text-right">
                    <h3 className="pt-2 pb-2 m-0">
                      {wijmo.format('{val:c0}', { val: sales.ARARI })}
                    </h3>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col className="p-1" md="1">
            <Card className="m-0">
              <Card.Body className="p-1">
                <Row>
                  <Col>
                    <div>
                      <p className="card-category">利益率</p>
                    </div>
                  </Col>
                </Row>
                <Row className="m-0 p-0">
                  <Col className="text-right">
                    <h3 className="pt-2 pb-2 m-0">
                      {wijmo.format('{val:p0}', { val: sales.ARARI_RATE })}
                    </h3>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col className="p-1" md="2">
            <Tooltip
              title={
                <>
                  契約数：{keiyakuCount.KEI_COUNT}
                  <br />
                  失注数：{keiyakuCount.LOST_COUNT}
                  <br />
                  保留数：{keiyakuCount.PENDING_COUNT}
                </>
              }
            >
              <Card className="m-0">
                <Card.Body className="p-1">
                  <Row>
                    <Col>
                      <div>
                        <p className="card-category">契約率 ※カッコ内は保留含む</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="m-0 p-0 ">
                    <Col className="text-right">
                      <h3 className="pt-2 pb-2 m-0">
                        {wijmo.format('{val:p0}', {
                          val: calcKeiRate(keiyakuCount.KEI_COUNT, keiyakuCount.LOST_COUNT),
                        })}
                        <span className="ml-2" style={{ fontSize: '15pt' }}>
                          (
                          {wijmo.format('{val:p0}', {
                            val: calcKeiRate(
                              keiyakuCount.KEI_COUNT,
                              keiyakuCount.LOST_COUNT,
                              keiyakuCount.PENDING_COUNT
                            ),
                          })}
                          )
                        </span>
                      </h3>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Tooltip>
          </Col>
        </Row>
        <Row className="mt-2">
          <div
            className="mt-0 d-flex justify-content-start"
            style={{ height: 'calc(100vh - 228px)', overflow: 'auto' }}
          >
            {master.process.map((p) => (
              <ClientCardList
                key={p.PROCESS_CD}
                staff={staff}
                clients={clients}
                process={p}
                callSortModal={callSortModal}
                callSettingsModal={callSettingsModal}
                callNyukinCommentModal={callNyukinCommentModal}
              />
            ))}
          </div>
        </Row>
      </Container>
      {/** 並べ替えモーダル画面 */}
      <ClientCardSortModal params={sortModalparam} />
      {/** 編集モーダル画面 */}
      <ClientCardEditModal params={editModalparam} />
      {/** 入金コメントモーダル画面 */}
      <NyukinCommentModal params={nyukinCommentModalparam} />
      {/** 未発注一覧モーダル */}
      <NoOrdersModal params={noOrdersModalModalparam} noOrderList={noOrderList} />
    </>
  );
}

export default Dashboard;
