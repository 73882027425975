//住所を取得
export function getAddress(restApi, postCode, fn, setDialog) {
    restApi.get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postCode}`, (json) => {
    //存在しない郵便番号エラー  
    if (!json["results"]) {
            setDialog({
                type: "error",
                message: "郵便番号に誤りがあります。",
            })
            return;
        }
        fn(json)
    }, {})
}