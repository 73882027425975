import CalendarComponent from "components/Input/Calendar";
import NumberComponent from "components/Input/NumberComponent";
import { Form } from "react-bootstrap";
import 'assets/css/textcomponent.css';


// テキストコンポーネント
function TextComponent(props) {
    switch (props.type) {
        // 日付
        case "date":
            return (
                // 入力不可の場合は通常のテキスト
                props.disabled ?
                    <Form.Control
                        type="text"
                        value={props.value}
                        disabled={props.disabled} />
                    :
                    <CalendarComponent
                        value={props.value}
                        onChange={props.onChange}
                        disabled={props.disabled}
                        selectionMode={props.selectionMode}
                        error={props.error} />
            )
        // テキストエリア
        case "textarea":
            return (
                <Form.Control
                    as={props.type}
                    rows={props.rows}
                    value={props.value}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    maxLength={props.maxLength}
                    onChange={(e) => props.onChange ? props.onChange(e.target) : null}
                ></Form.Control>
            )
        // 数値
        case "number":
            return (
                <NumberComponent
                    fmt={props.fmt}
                    value={props.value}
                    disabled={props.disabled}
                    onChange={props.onChange}
                >
                </NumberComponent>
            )
        // テキスト
        case "text":
        default:
            return (
                <Form.Control className={props.className}
                    placeholder={props.placeholder}
                    type={props.type}
                    value={props.value}
                    disabled={props.disabled}
                    onChange={(e) => props.onChange ? props.onChange(e.target) : null}
                    maxLength={props.maxLength}
                    style={{ borderBottom: "3px solid #E3E3E3", background: props.error ? "lightpink" : "" }}
                ></Form.Control>
            )
    }
}

export default TextComponent;