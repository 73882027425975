import { DataMap, CellType, KeyAction } from '@grapecity/wijmo.grid';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import TextComponent from 'components/Input/TextComponent';
import { useMaster } from 'context/MasterContext';
import { useRestApi } from 'context/RestApiContext';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import IconButtonCompnent from 'components/Input/IconButtonCompnent';
import { toFloat, toInt } from 'utils/numberUtils';

/** 請求情報の登録画面 */
function PaymentRegister(props) {
  const paymentMonth = props.paymentMonth;
  const toriDataMap = props.toriDataMap;
  const setDialog = props.setDialog;

  const restApi = useRestApi();

  // グリッドとスクロール位置
  const flexGrid = useRef();
  const scrollPosition = useRef(0);
  // 修正前のオリジナルデータ
  const orgPayments = useRef({});
  // 変更データ
  const updDataList = useRef([]);

  // 請求データ
  const [payments, setPayments] = useState([]);

  const [constructionsMap, setConstructionsMap] = useState(null);

  // リロード用データ
  const [reload, setReload] = useState('');

  // 初期表示処理
  useEffect(() => {
    restApi.post('/api/payment/list', { paymentMonth: paymentMonth }, (data) => {
      setPayments(data.payments);
      orgPayments.current = [...data.payments.map((x) => ({ ...x }))];
      const _constructionsMap = new DataMap(
        [
          { SEIKYU_NO: -1, KOUJI_MEI: '' },
          ...data.paySeikyuList.map((x) => ({
            SEIKYU_NO: x.SEIKYU_NO,
            KOUJI_MEI: x.SEIKYU_NO + ': ' + x.KOUJI_MEI + ' ' + props.getAtesaki(x),
          })),
        ],
        'SEIKYU_NO',
        'KOUJI_MEI'
      );
      setConstructionsMap(_constructionsMap);
    });
  }, []);

  // 明細の変更時にスクロール位置を復元
  useEffect(() => {
    if (flexGrid.current?.control) {
      scrollPosition.current = flexGrid.current.control.scrollPosition = scrollPosition.current;
    }
  }, [payments]);

  // グリッドの選択時の処理（ime制御)
  const gridSelectionChanged = (grid, args) => {
    var col = grid.columns[args.col];
    var imeEnabled = !['SU', 'TANKA'].some((x) => x == col?.binding);
    setTimeout(function () {
      grid.imeEnabled = imeEnabled;
    }, 50);
  };

  // 追加ボタンの処理
  const handleOnClickAdd = () => {
    let addData = {
      PAYMENT_ID: -1,
      MONTH: paymentMonth,
      TRI_CD: -1,
      ITEM_NAME: '',
      SU: 1,
      TANI: '式',
      TANKA: 0,
      KINGAKU: 0,
      SEIKYU_NO: -1,
    };
    // スクロール位置を保持
    scrollPosition.current = flexGrid.current.control.scrollPosition;

    setPayments([...payments, addData]);
    appendUpdData(addData);
  };

  // 削除ボタンの処理
  const handleOnClickDelete = (cell, delFlg) => {
    // スクロール位置を保持
    scrollPosition.current = flexGrid.current.control.scrollPosition;

    if (cell.item.PAYMENT_ID == -1) {
      // 一覧から削除
      updDataList.current = updDataList.current.filter((u) => u != cell.item);
      setPayments(payments.filter((p) => p != cell.item));
    } else {
      cell.item.DEL_FLG = delFlg;
      // 削除フラグを立てる
      setPayments(
        payments.map((x) => (x.PAYMENT_ID == cell.item.PAYMENT_ID ? { ...x, DEL_FLG: delFlg } : x))
      );

      appendUpdData(cell.item, ['DEL_FLG']);
      // 画面の読み込み直し用にstateの変更
      setReload(new Date().getTime());
    }
  };

  // 貼り付け後の処理
  const pasted = (grid, args) => {
    cellEditEnded(grid, args);
  };
  // 編集終了処理
  const cellEditEnded = (grid, args) => {
    var dataItem = grid.rows[args.row].dataItem;
    var col = grid.columns[args.col];
    let upd_bindings = [];

    // 単価・数量の場合
    if (col['binding'] == 'TANKA' || col['binding'] == 'SU') {
      let tanka = toFloat(dataItem['TANKA']);
      let su = toFloat(dataItem['SU'], 1);
      let kingaku = toInt(tanka * su);

      dataItem['KINGAKU'] = kingaku;
      upd_bindings = ['KINGAKU'];
    }

    // 変更データを保持
    appendUpdData(dataItem, [...upd_bindings, col['binding']]);

    // 一覧の再描画をおこなう
    if (upd_bindings.length) {
      grid.beginUpdate();
      grid.setCellData(args.row, 5, dataItem['KINGAKU'], true);
      grid.endUpdate();
    }
  };

  // グリッドのフォーマット処理
  const formatItem = (grid, e) => {
    if (e.panel.cellType == CellType.Cell) {
      var col = grid.columns[e.col];
      var dataItem = grid.rows[e.row].dataItem;

      if (col.binding) {
        // 削除データの場合は取り消し線を引く
        let isDel = dataItem.DEL_FLG == '1';
        e.cell.style.textDecorationLine = isDel ? 'line-through' : '';
        e.cell.style.textDecorationStyle = isDel ? 'double' : '';
        e.cell.style.textDecorationColor = isDel ? 'red' : '';

        // 変更列は青く表示
        let isUpd = dataItem.PAYMENT_ID == -1 || dataItem.updColumns?.includes(col.binding);

        e.cell.style.color = isUpd ? 'blue' : '';
        e.cell.style.fontWeight = isUpd ? 'bold' : '';
        e.cell.style.background = isUpd ? '#DDEEFF' : '';
      }
    }
  };

  // 変更データを退避処理
  const appendUpdData = (dataItem, bindings) => {
    // 既存データの編集の場合は、変更列を保持
    if (dataItem.PAYMENT_ID != -1) {
      dataItem['updColumns'] = dataItem['updColumns'] || [];
      bindings.forEach((binding) => {
        // 変更列のオリジナルデータを取得取得
        let org = orgPayments.current.find((o) => o.PAYMENT_ID == dataItem.PAYMENT_ID);

        // 変更が無い場合は取り除く
        if ((org[binding] ?? '') == (dataItem[binding] ?? '')) {
          dataItem['updColumns'] = dataItem['updColumns']?.filter((c) => c != binding) ?? [];
        }
        // 変更あり、かつ、ない場合は追加
        else if (!dataItem['updColumns'].some((c) => c == binding)) {
          dataItem['updColumns'].push(binding);
        }
      });
    }

    // 修正データありの場合
    if (updDataList.current.some((u) => u == dataItem)) {
      if (dataItem.PAYMENT_ID == -1 || dataItem['updColumns'].length) {
        updDataList.current = updDataList.current.map((u) => (u == dataItem ? dataItem : u));
      }
      // 修正なしの場合は修正データから消す
      else {
        updDataList.current = updDataList.current.filter(
          (u) => u.PAYMENT_ID != dataItem.PAYMENT_ID
        );
      }
    }
    // 修正データなしの場合、
    // カラムに変更があった、もしくは、新規追加の場合は変更データに追加
    else {
      updDataList.current.push(dataItem);
    }

    // 画面の読み込み直し用にstateの変更
    setReload(new Date().getTime());
  };

  // 次へボタン押下
  const handleNext = () => {
    restApi.post('/api/payment/regist', updDataList.current, (data) => {
      props.handleNext();
    });
  };
  // 戻るボタン押下処理
  const handleOnClickBack = () => {
    // 変更無しの場合はそのまま戻る
    if (!updDataList.current.length) {
      props.handleBack();
    } else {
      setDialog({
        type: 'confirm',
        message: `修正内容は破棄されます。よろしいですか？`,
        okCallBack: () => {
          props.handleBack();
        },
      });
    }
  };

  return (
    <>
      <Row className="pt-4">
        <Col className="pr-1">・請求情報を登録してください。</Col>
      </Row>
      <Row className="pt-2">
        <Col className="pr-1" md="2">
          <Form.Group>
            <label>請求年月</label>
            <TextComponent value={paymentMonth} disabled={true} type="date" selectionMode="Month" />
          </Form.Group>
        </Col>
      </Row>
      <Row className="text-right mt-0 mb-0">
        <Col>
          <Button
            className="m-0 material-symbols-rounded cell-icon-sm btn-fill"
            variant="primary"
            title="追加"
            onClick={handleOnClickAdd}
          >
            add
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="table-scroll" style={{ height: 'calc(100vh - 342px)' }}>
            <FlexGrid
              ref={flexGrid}
              imeEnabled={true}
              itemsSource={payments}
              selectionMode={'Cell'}
              headersVisibility={'Column'}
              autoGenerateColumns={false}
              allowSorting={true}
              selectionChanged={gridSelectionChanged}
              style={{ height: '100%' }}
              cellEditEnded={cellEditEnded}
              pasted={pasted}
              formatItem={formatItem}
              keyActionTab={KeyAction.Cycle}
              loadedRows={(grid) => {
                grid.rows.forEach((ele) => {
                  ele.height = 24;
                });
              }}
              updatedView={(grid) => {
                grid.rows.forEach((ele) => {
                  ele.height = 24;
                });
              }}
            >
              <FlexGridColumn
                binding="TRI_CD"
                header="取引先"
                dataMap={toriDataMap}
                width={'1*'}
                isRequired={false}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="ITEM_NAME"
                header="商品名"
                width={'1*'}
                isRequired={false}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="SU"
                header="数量"
                dataType="Number"
                format="n1"
                width={100}
                inputType={'tel'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="TANI"
                header="単位"
                width={50}
                isRequired={false}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="TANKA"
                header="単価"
                dataType="Number"
                width={100}
                isRequired={false}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="KINGAKU"
                header="小計"
                dataType="Number"
                cssClass="cell-readonly"
                isReadOnly={true}
                width={120}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="SEIKYU_NO"
                header="工事"
                dataMap={constructionsMap}
                cssClass="cell-readonly"
                isReadOnly={true}
                width={'2*'}
              ></FlexGridColumn>
              <FlexGridColumn header="　" isReadOnly={true} width={28}>
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(cell) =>
                    cell.item.DEL_FLG != '1' ? (
                      <IconButtonCompnent
                        className="cell-icon-sm"
                        variant="danger"
                        title="削除"
                        onClick={() => handleOnClickDelete(cell, '1')}
                        disabled={cell.item.SEIKYU_NO != -1}
                        tooltip={
                          cell.item.SEIKYU_NO != -1 ? '工事が設定済みのため削除できません' : ''
                        }
                        icon="Clear"
                      />
                    ) : (
                      <IconButtonCompnent
                        className="cell-icon-sm"
                        variant="success"
                        title="削除"
                        onClick={() => handleOnClickDelete(cell, '0')}
                        icon="Replay"
                      />
                    )
                  }
                />
              </FlexGridColumn>
            </FlexGrid>
          </div>
        </Col>
      </Row>
      <Row className="pt-2">
        <Col className="d-flex justify-content-between">
          <Button variant="secondary" className="footer-button" onClick={handleOnClickBack}>
            戻る
          </Button>
          <Button variant="primary" className="btn-fill footer-button" onClick={handleNext}>
            次へ
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default PaymentRegister;
