import { useState, useContext, useEffect, useRef, useCallback } from 'react';

import { Button, Card, Form, Row, Col } from "react-bootstrap";

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import { useRestApi } from 'context/RestApiContext'

import { MessageModal } from 'components/modal/MessageModal'
import TextComponent from 'components/Input/TextComponent'

/**
 * 契約変更モーダル
 * @param {*} props プロパティ
 * @returns 契約変更モーダル
 */
function ContractChangeModal(props) {

    const restApi = useRestApi();

    // モード情報
    const MODE_LIST = [
        { id: "add", caption: "登録", variant: "primary", readOnly: false },
        { id: "upd", caption: "更新", variant: "primary", readOnly: false },
        { id: "del", caption: "削除", variant: "danger", readOnly: true },
    ]

    // 確認ダイアログ
    const [dialog, setDialog] = useState({})

    // 画面モード
    const [mode, setMode] = useState({})

    // 変更契約情報
    const [contractChange, setContractChange] = useState({
        SEIKYU_NO: "",
        KEI_DATE: "",
        CONTENT_MOD: "",
        CONTENT_MOD: "",
        MOD_KOUKI_ST: "",
        MOD_KOUKI_ED: "",
        MOTO_KIN: 0,
        ADD_KIN: 0,
    })

    // モーダルの表示
    const [show, setShow] = useState(false);

    // モーダルパラメタの変更処理
    useEffect(() => {
        const quoteList = props?.params?.quoteList
        const seikyu = props?.params?.seikyu
        if (quoteList) {
            let motoKin = quoteList.find(x => x.STATUS == "契約済" && x.MOTO_KEI_NO == 0)?.MITSU_KIN_ZEI ?? 0
            setContractChange({
                ...contractChange,
                SEIKYU_NO: props?.params?.seikyuNo,
                MOTO_KIN: motoKin,
                ADD_KIN: seikyu.MITSU_KIN + seikyu.ZEI - motoKin,
            })

            // ダイアログを表示
            setShow(true)
        }
    }, [props.params]);

    // 編集処理
    const handleOnChange = (prop) => (target) => {
        setContractChange({ ...contractChange, [prop]: target.value })
    }

    // 印刷ボタン押下処理
    const handleOnClickPrint = () => {
        restApi.postToPdf(`/api/contract/modify/report`,
            {
                ...contractChange,
            }, () => { /** 処理なし */ })
    }

    // 閉じるボタン
    const handleClose = useCallback(() => {
        setShow(false)
    }, [props.params])

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={show}
            >
                <DialogTitle className="p-3">契約変更</DialogTitle>
                <DialogContent className="px-0 py-0 pr-0 pl-0">
                    <Card className="m-0">
                        <Card.Body>
                            <Row>
                                <Col className="pr-1" md="3">
                                    <Form.Group>
                                        <label>契約日</label>
                                        <TextComponent
                                            type="date"
                                            value={contractChange?.KEI_DATE ?? ""}
                                            onChange={handleOnChange('KEI_DATE')}
                                        ></TextComponent>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pl-3" md="10">
                                    <Form.Group>
                                        <label>変更内容</label><br />
                                        <TextComponent
                                            type="text"
                                            value={contractChange?.CONTENT_MOD}
                                            disabled={mode?.readOnly}
                                            onChange={handleOnChange("CONTENT_MOD")}
                                        ></TextComponent>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pr-1" md="3">
                                    <Form.Group>
                                        <label>変更後工期：着工予定日</label>
                                        <TextComponent
                                            type="date"
                                            value={contractChange?.KOUKI_ST ?? ""}
                                            onChange={handleOnChange('MOD_KOUKI_ST')}
                                        ></TextComponent>
                                    </Form.Group>
                                </Col>
                                <Col className="pr-1" md="3">
                                    <Form.Group>
                                        <label>変更後工期：完了予定日</label>
                                        <TextComponent
                                            type="date"
                                            value={contractChange?.KOUKI_ED ?? ""}
                                            onChange={handleOnChange('MOD_KOUKI_ED')}
                                        ></TextComponent>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pr-1" md="3">
                                    <Form.Group>
                                        <label>当初契約金額</label>
                                        <TextComponent
                                            type="number"
                                            value={contractChange?.MOTO_KIN ?? 0}
                                            disabled={true}
                                        ></TextComponent>
                                    </Form.Group>
                                </Col>
                                <Col className="pr-1" md="3">
                                    <Form.Group>
                                        <label>追加工事契約金額</label>
                                        <TextComponent
                                            type="number"
                                            value={contractChange?.ADD_KIN ?? 0}
                                            onChange={handleOnChange("ADD_KIN")}
                                        ></TextComponent>
                                    </Form.Group>
                                </Col>
                                <Col className="pr-1" md="3">
                                    <Form.Group>
                                        <label>合計契約金額</label>
                                        <TextComponent
                                            type="number"
                                            value={(contractChange?.MOTO_KIN ?? 0) + (contractChange?.ADD_KIN ?? 0)}
                                            disabled={true}
                                        ></TextComponent>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </DialogContent>
                <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
                    <Button variant="secondary" className="footer-button" onClick={handleClose}>閉じる</Button>
                    <Button variant="primary" className="footer-button btn-fill" onClick={handleOnClickPrint}>印刷</Button>
                </DialogActions>
            </Dialog>
            { /** メッセージモーダル */}
            < MessageModal dialog={dialog} ></MessageModal >
        </>
    );
}

export default ContractChangeModal;
