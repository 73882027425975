import { Alert } from "react-bootstrap";
import { useState } from 'react';
import { Avatar } from "@mui/material";
import ClientCard from 'views/Dashboard/ClientCard'

function ClientCardList(props) {

  // プロパティ
  const clients = props.clients
  const process = props.process
  const callSettingsModal = props.callSettingsModal
  const callNyukinCommentModal = props.callNyukinCommentModal

  // マウスホバー中か？
  const [hover, setHover] = useState(false)

  // 並べ替えボタン
  const handleOnClickSort = (e) => {
    e.preventDefault()
    props.callSortModal(process)
  }

  // 表示期間に設定があり、0件のものは表示しない
  if (process.DISP_DATE > 0 && !clients.some(c => c.PROCESS_CD == process.PROCESS_CD)) {
    return <></>
  }

  return (
    <div className='flex-fill p-0 m-0 mr-2' style={{ "width": "308px" }}>
      <Alert style={{ color: process.FONT_COLOR, background: process.COLOR, width: "308px" }} className='m-0 p-0 d-flex flex-row justify-content-between align-items-center rounded'
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}>
        <div className='d-flex p-2 align-items-center'>
          <i className="material-symbols-rounded">{process.ICON}</i>
          <h4 className='m-0 p-0 pl-2'>{process.DISPLAY}</h4>
        </div>
        {
          !hover && process.DISP_DATE ?
            <h6 className='ml-auto align-self-end'>（表示期間: {process.DISP_DATE}日）</h6>
            : <></>
        }
        <a href="#" onClick={handleOnClickSort}>
          <Avatar className="mr-2" sx={{ width: 32, height: 32, bgcolor: "#8946ff" }} hidden={!hover || clients.length == 0} >
            <i className="material-symbols-rounded">sort</i>
          </Avatar>
        </a>
      </Alert>
      {
        clients
          .filter(c => c.PROCESS_CD == process.PROCESS_CD)
          .sort((a, b) => a.SORT > b.SORT ? 1 : -1)
          .map(c =>
          (
            <ClientCard
              key={c.SEIKYU_NO}
              client={c}
              callSettingsModal={callSettingsModal}
              callNyukinCommentModal={callNyukinCommentModal} />
          ))
      }
    </div >
  )
}

export default ClientCardList;
