import { useState, useEffect, useCallback } from 'react';
import { Button, Row } from 'react-bootstrap'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { ColorPicker } from '@grapecity/wijmo.react.input';


import "assets/css/modal.css"

/** メッセージモーダル */
export const ColorPickerModal = (props) => {
    const [show, setShow] = useState(false);
    const [color, setColor] = useState("")
    const [mode, setMode] = useState(props.params?.mode ?? "back")

    useEffect(() => {
        if (props.params.color) {
            setMode(props.params.mode)
            setColor(props.params.color)
            setShow(true)
        }
    }, [props.params]);

    // OKボタン
    const handleModalOk = () => {
        if (props.params.callBack) {
            props.params.callBack(color);
        }
        setShow(false);
    }

    // 閉じるボタン
    const handleModalClose = () => {
        setShow(false);
    }

    return (
        <Dialog
            minWidth="sm"
            maxWidth={"sm"}
            open={show}
        >
            {
                <>
                    <DialogTitle style={{
                        background: mode == "back" ? color : props.params.backColor
                        , color: mode != "back" ? color : props.params.fontColor
                    }} className="p-2">色を選択してください</DialogTitle>
                    <DialogContent className='m-1 mt-2'>
                        <ColorPicker
                            value={color}
                            showAlphaChannel={true}
                            showColorString={true}
                            valueChanged={(s) => setColor(s.value)}>
                        </ColorPicker>
                    </DialogContent>
                    <DialogActions className='p-2 m-0 pt-0' style={{ justifyContent: 'space-between' }}>
                        <Button variant="secondary" className="footer-button" onClick={handleModalClose} >
                            閉じる
                        </Button>
                        <Button variant="primary" className="footer-button" onClick={handleModalOk} >
                            確定
                        </Button>
                    </DialogActions>
                </>
            }

        </Dialog >
    )
}