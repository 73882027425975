import { useMaster } from 'context/MasterContext';
import { useEffect, useState, useCallback } from 'react';
import { Button, Card, Col, Container, Row, Alert } from 'react-bootstrap';
import { Tooltip, Badge, Avatar, Chip } from '@mui/material';
import { useNavigate } from 'react-router';
import StatusLableComponent from 'components/Input/StatusLableComponent';
import { format, parse } from 'date-fns';
import { NyukinLabelComponent, NYUKIN_STATUS } from 'components/Input/NyukinLabelComponent';

function ClientCard(props) {
  // マスタ情報
  const master = useMaster();
  const navigate = useNavigate();

  // プロパティ
  const client = props.client;

  // マウスホバー中か？
  const [hover, setHover] = useState(false);
  // 顧客ID
  const clientId = client.CLIENT_CD;
  // 顧客名
  const name = `${client.NAME_SEI_1}　${client.NAME_MEI_1}`;
  // 住所
  const address = `${client.PREF}${client.CITY}${client.TOWN}${client.ADDRESS}`;

  // 顧客情報ボタン
  const handleOnClickClientInfo = (e) => {
    e.preventDefault();
    navigate('/admin/ClientInfo', {
      state: {
        clientId: clientId,
        seikyuNo: client.SEIKYU_NO,
      },
    });
  };

  // 設定画面呼び出しボタン
  const handleOnClickClientSetting = (e) => {
    e.preventDefault();
    props.callSettingsModal(client);
  };
  return (
    <Card
      className="mt-1 mb-1 p-0"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Card.Body className="p-1 m-0">
        <Row className="p-1">
          <Col className="d-flex justify-content-between">
            <div>
              <p className="p-0 m-0" style={{ fontSize: '8pt', color: '#B3B3B3' }}>
                #{client.SEIKYU_NO}
              </p>
              <p className="p-0 m-0" style={{ fontSize: '16pt' }}>
                {name}
              </p>
            </div>
            <div className="d-flex">
              {!hover ? (
                // 入金済、かつ、請求書発行済の場合
                client.NYUKIN_STATUS == NYUKIN_STATUS.ALL && client.SEIKYU_DATE ? (
                  <div className="stampmark">入金済</div>
                ) : // 保留、かつ、最終更新が6か月より前の場合は時効
                client.PROCESS_CD == 7 &&
                  parse(client.EXPIRY_DATE, 'yyyy-MM-dd', new Date()) < new Date() ? (
                  <div className="stampmark2">時効</div>
                ) : (
                  // 上記以外は受注確度を表示
                  <h4 className="m-0 p-0" style={{ color: '#1976d2' }}>
                    {client.ORDER_LEVEL}%
                  </h4>
                )
              ) : (
                <></>
              )}
              <a href="#" onClick={handleOnClickClientInfo}>
                <Avatar sx={{ width: 24, height: 24, bgcolor: '#87CB16' }} hidden={!hover}>
                  <i className="material-symbols-rounded">person</i>
                </Avatar>
              </a>
              <a href="#" onClick={handleOnClickClientSetting} className="ml-1">
                <Avatar sx={{ width: 24, height: 24 }} hidden={!hover}>
                  <i className="material-symbols-rounded">more_horiz</i>
                </Avatar>
              </a>
            </div>
          </Col>
        </Row>
        <Row className="p-1">
          <Col className="d-flex">
            <div className="text-truncate">{client.KOUJI_MEI}</div>
          </Col>
        </Row>
        <Row className="p-0 pl-1">
          <Col className="d-flex">
            {client.SEIKYU_DATE ? (
              <div>請求日:&nbsp;{client.SEIKYU_DATE}</div>
            ) : client.EXPECTED_SALES_MONTH ? (
              <div>売上予定:&nbsp;{client.EXPECTED_SALES_MONTH}</div>
            ) : (
              client.ORDER_LEVEL >= 80 && (
                <div style={{ color: 'red', fontWeight: 'bold' }}>売上予定が未入力</div>
              )
            )}
          </Col>
        </Row>
        <Row className="p-1">
          <Col className="d-flex">
            <i className="material-symbols-rounded">home_pin</i>
            <Tooltip title={address} arrow>
              <div className="text-truncate">{address}</div>
            </Tooltip>
          </Col>
        </Row>
        {client.REQ + client.APP + client.REJ > 0 ||
        client.KEI_NO ||
        client?.staff?.SALES_KBN == '1' ||
        (client?.staff?.PLANNER_KBN == '1' && client.SALES_TAN_CD) ? (
          <Row className="p-1 pt-3">
            <Col>
              <StatusLableComponent
                className="mr-3 mb-1"
                status="承認待ち"
                badgeContent={client.REQ}
                hidden={client.REQ == 0}
              />
              <StatusLableComponent
                className="mr-3 mb-1"
                status="承認済み"
                badgeContent={client.APP}
                hidden={client.APP == 0}
              />
              <StatusLableComponent
                className="mr-3 mb-1"
                status="承認却下"
                badgeContent={client.REJ}
                hidden={client.REJ == 0}
              />
              {/* 営業事務の場合はプランナーを表示 */}
              {client?.staff?.SALES_KBN == '1' ? (
                <Chip
                  size="small"
                  className="mr-1 mb-1"
                  label={'プ・' + master.planer.find((p) => p.id == client.TAN_CD)?.name}
                  style={{
                    minWidth: '62px',
                    background: '#7030a0',
                    color: '#ffffff',
                  }}
                />
              ) : (
                <></>
              )}
              {/* プランナーの場合は補助担当を表示 */}
              {client?.staff?.PLANNER_KBN == '1' && client.SALES_TAN_CD ? (
                <Chip
                  size="small"
                  className="mr-1 mb-1"
                  label={'補・' + master.planer.find((p) => p.id == client.SALES_TAN_CD)?.name}
                  style={{
                    minWidth: '62px',
                    background: '#7030a0',
                    color: '#ffffff',
                  }}
                />
              ) : (
                <></>
              )}
              <StatusLableComponent className="mr-1 mb-1" status="契約済" hidden={!client.KEI_NO} />
              <StatusLableComponent
                className="mr-1 mb-1"
                status="発注なし"
                hidden={!client.KEI_NO || client.CYU_GENKA > 0 || client.MITSU_KIN <= 0}
              />
              <StatusLableComponent
                className="mr-1 mb-1"
                status="マイナス"
                hidden={client.MITSU_KIN >= 0}
              />
              <NyukinLabelComponent
                className="mr-1  mb-1"
                nyukin={client.NYUKIN_STATUS}
                hidden={client.NYUKIN_STATUS == 0}
                hasComment={client.NYUKIN_COMMENT}
                onClick={client.NYUKIN_COMMENT ? () => props.callNyukinCommentModal(client) : null}
                size="small"
              />
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {/* ラベル */}
        {client.LABELS?.length ? (
          <>
            <Row>
              <Col>
                <hr className="p-0 m-0  mb-1"></hr>
                {master.label
                  .filter((ml) => (client.LABELS ?? []).some((l) => l == ml.LABEL_CD))
                  .map((ml) => (
                    <Chip
                      key={ml.LABEL_CD}
                      className="m-1"
                      size="small"
                      style={{ color: ml.FONT_COLOR, backgroundColor: ml.COLOR }}
                      label={ml.DISPLAY}
                    />
                  ))}
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
        {
          /** 表示期限 */
          client.DASHBOARD_DISP_DATE != '9999-12-31' ? (
            <Row>
              <Col className="d-flex flex-row-reverse">
                <h5 className="p-0 m-0" style={{ color: 'red', fontWeight: 'bold' }}>
                  {format(parse(client.DASHBOARD_DISP_DATE, 'yyyy-MM-dd', new Date()), 'yyyy/M/d')}
                  まで表示
                </h5>
              </Col>
            </Row>
          ) : (
            <></>
          )
        }
      </Card.Body>
    </Card>
  );
}

export default ClientCard;
